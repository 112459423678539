import axios from 'axios';
import {V1API} from "../../../_helpers/APIPath";
import {encode, decode} from "../../dataMagic";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";


const keyList = {
    smsUpdate : 'REQUISITION_UPDATE',
    smsDelete : 'REQUISITION_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,

    state : {
        requisition: [],
        my_requisition:[],
        my_requisition_count:null,
        requisition_count:null,
        requisition_detail: {},
        requisition_type:[],
        requisitionPaymentList:[],

        submitLoading: false,
        submitError: false,
        submitSuccess: false,

        ...store
    },

    mutations : {
        ...mutations,
        SET_REQUISITION (state, {results,pg_count}) {
            state.requisition = results;
            state.requisition_count = pg_count;
        },
        SET_MYREQUISITION (state, {results,pg_count}) {
            state.my_requisition = results;
            state.my_requisition_count = pg_count;
        },

        SET_REQUISITION_TYPE(state, type) {
            state.requisition_type = type;
        },

        SET_REQUISITION_DETAIL (state, item) {
            state.requisition_detail = item;
        },


        SET_REQUISITION_PAYEMNT_LIST_BY_REQUISITION(state,list){
            state.requisitionPaymentList = list
        },

        SUBMIT_LOADING (state, loading) {
            state.submitLoading = loading;
        },

        SUBMIT_ERROR (state, hasError) {
            state.submitError = hasError;
        },

        SUBMIT_SUCCESS (state, success) {
            state.submitSuccess = success;
        }
    },

    actions: {
        async updateRequisition ({commit, dispatch}, payload) {
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try {

                let response = await axios.patch(V1API.edit_requisition + payload.id, payload, config).then(result=>{
                    let data = result.data
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
                dispatch ('fetchRequisition')
                return response

            }catch (e) {
                commit('SUBMIT_ERROR', true);
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        },
        async updateRequisitionStatus ({commit, dispatch}, payload) {
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try {

                let response = await axios.get(V1API.edit_requisition_status+"/" + payload.id+"/"+ payload.status, config).then(result=>{
                    let data = result.data
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
                dispatch ('fetchRequisition', {perPage:payload.perPage, currentPage:payload.page})
                dispatch ('fetchRequisitionDetail',payload.id)
                return response

            }catch (e) {
                commit('SUBMIT_ERROR', true);
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        },

        async deleteRequisition ({dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.delete(V1API.delete_requisition + payload.delete, config).then(result=>{
                let data = result.data
                return data
            })

            dispatch('fetchRequisition',{perPage:payload.perPage, currentPage:payload.page});

            return response
        },

        async fetchRequisition ({ commit },payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.get_requisition+'?count='+payload.perPage+'&page='+payload.currentPage, config);
                const {results,pg_count} = result.data

                commit('SET_REQUISITION', {results,pg_count});

            } catch(error) {
                commit('SUBMIT_ERROR', true);
            }
            finally {
                commit('SUBMIT_LOADING', false);
            }
        },


        async fetchMyRequisition ({ commit },payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.my_requisition+'?count='+payload.perPage+'&page='+payload.currentPage, config);
                const {results,pg_count} = result.data

                commit('SET_MYREQUISITION', {results,pg_count});

            } catch(error) {
                commit('SUBMIT_ERROR', true);
            }
            finally {
                commit('SUBMIT_LOADING', false);
            }
        },

        async fetchRequisitionDetail ({ commit }, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.get_requisition_details + payload, config);
                let response = result.data

                commit('SET_REQUISITION_DETAIL', response.data);

            } catch(error) {
                commit('SUBMIT_ERROR', true);
            }
            finally {
                commit('SUBMIT_LOADING', false);
            }
        },

        async addRequisition ({commit, dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let response = await axios.post(V1API.create_requisition, payload, config).then(result=>{
                    let data = result.data
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
                dispatch ('fetchRequisition')
                return response

            }catch (e) {
                commit('SUBMIT_ERROR', true);
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        },


        async addRequistionAttachment ({commit, dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
                "Content-Type": "multipart/form-data"
            };
            try{
                let data= new FormData()
                let payloadData = buildFormData(data, payload.attachment);
        
                let response = await axios.post(V1API.requisition_add_attachment, payloadData, config).then(result=>{
                  
                    let data = result.data
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
                dispatch("fetchRequisitionDetail",payload.requisition_id)
                return response

            }catch (e) {
               
                commit('SUBMIT_ERROR', true);
            }finally {
             
                commit('SUBMIT_LOADING', false);
            }
        },
        async deleteAttachmentFromRequisition ({commit, dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.delete(V1API.requisition_delete_attachment + payload.id, config).then(result=>{
                let data = result.data
                return data
            })
            
            dispatch ('fetchRequisitionDetail',payload.requisition_id)
            
            return response
        },

        async RequisitionAddRefer ({commit, dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            };
            try{
                let response = await axios.post(V1API.requisition_add_refer + payload.id, {
                    refer_id:payload.refer_id
                }, config).then(result=>{
                    let data = result.data
                    return data

                });
             
                dispatch ('fetchRequisitionDetail',payload.id)
                
            
                return response

            }catch (e) {
                commit('SUBMIT_ERROR', true);
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        },

        async RequisitionDeleteRefer ({commit, dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            };
            try{
                let response = await axios.post(V1API.requisition_delete_refer + payload.id, {
                    refer_id:payload.refer_id
                }, config).then(result=>{
                    let data = result.data
                    return data

                });
                dispatch ('fetchRequisitionDetail',payload.id)
            
                return response

            }catch (e) {
                commit('SUBMIT_ERROR', true);
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        },


        async addRequisitionType ({commit, dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            };

            try{
                let response = await axios.post(V1API.add_requisition_type, payload, config).then(result=>{
                    let data = result.data
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
                dispatch ('fetchRequisitionType')
                return response

            }catch (e) {
                commit('SUBMIT_ERROR', true);
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        },

        async fetchRequisitionType ({ commit }) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.requisition_type_list, config);
                let response = result.data

                commit('SET_REQUISITION_TYPE', response.data);

            } catch(error) {
                commit('SUBMIT_ERROR', true);
            }
            commit('SUBMIT_LOADING', false);
        },

        async deleteRequisitionType ({commit, dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.delete(V1API.delete_requisition_type + payload, config).then(result=>{
                let data = result.data
                return data
            })
            
            dispatch ('fetchRequisitionType')
            
            return response
        },


        // Requisition Record Payment

        async recordPayment ({commit, dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let response = await axios.post(V1API.requisition_record_payment, payload, config).then(result=>{
                    let data = result.data
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
              
                return response

            }catch (e) {
                commit('SUBMIT_ERROR', true);
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        },



        
        async fetchRequisitionPaymentByRequisition ({ commit }, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.requisition_payment_list_by_requisition + payload, config);
                let response = result.data

                commit('SET_REQUISITION_PAYEMNT_LIST_BY_REQUISITION', response.data);

            } catch(error) {
                commit('SUBMIT_ERROR', true);
            }
            finally {
                commit('SUBMIT_LOADING', false);
            }
        },

        async updatePaymentStatus({commit, dispatch}, payload) {
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try {

                let response = await axios.get(V1API.requisition_payemnt_list_status_change+ payload.id + "/" + payload.status, config).then(result => {
                    let data = result.data
                    return data

                })
                dispatch("fetchRequisitionPaymentByRequisition",payload.detail_id)
                return response
            } catch (e) {

                commit('SUBMIT_ERROR', true);
            } finally {
                commit('SUBMIT_LOADING', false);
            }
        },


    },

    getters: {}
}
