import axios from 'axios';
import {V1API} from "../../../_helpers/APIPath";
import {encode, decode} from "../../dataMagic";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";


const keyList = {
    smsUpdate : 'TICKET_UPDATE',
    smsDelete : 'TICKET_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,
    state : {
        my_draft:{},
        my_eod:[],
        all_eod:[],
        eod_detail:{},

        my_complete_tickets:[],
        my_complete_task:[],

        eod_reply:[],

        eodLoading: false,
        eodError: false,

        eodReplyLoading:false,
        eodReplyError:false,

        eodReferLoading:false,
        eodReferError:false,

        eodSuccess:false,

        count:null,
        all_eod_count:null,

        ...store
    },
    mutations : {
        ...mutations,
        SET_MY_COMPLETE(state,{tickets,tasks}){
            state.my_complete_task = tasks;
            state.my_complete_tickets = tickets;
        },

        SET_MY_DRAFT(state,draft){
            state.my_draft = draft;
        },
        SET_EOD_DETAIL(state,eod){
            state.eod_detail = eod;
        },
        SET_MY_EOD(state,{results,count}){
            state.my_eod = results;
            state.count = count
        },
        SET_ALL_EOD(state,{results,pg_count}){
            state.all_eod = results;
            state.all_eod_count= pg_count;
        },
        SET_EOD_REPLY(state,reply){
            state.eod_reply = reply;
        },


        EOD_LOADING (state, loading) {
            state.eodLoading = loading;
        },
        EOD_ERROR (state, hasError) {
            state.eodError = hasError;
        },

        EOD_REPLY_LOADING (state, loading) {
            state.eodReplyLoading = loading;
        },
        EOD_REPLY_ERROR (state, hasError) {
            state.eodReplyError = hasError;
        },

        EOD_REFER_LOADING (state, loading) {
            state.eodReferLoading = loading;
        },
        EOD_REFER_ERROR (state, hasError) {
            state.eodReferError = hasError;
        },


        EOD_SUBMIT_SUCCESS (state, success) {
            state.eodSuccess = success;
        },
     
    },

    actions: {
        //Training Type
        async addEOD ({commit, dispatch}, payload) {
            commit('EOD_LOADING', true);
            commit('EOD_ERROR', false);
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            };
            try{
                let response = await axios.post(V1API.eod_create, payload, config).then(result=>{
                    let data = result.data
                    return data

                });

                commit('EOD_SUBMIT_SUCCESS', true);
                // dispatch ('fetchMyEOD')
                return response

            }catch (e) {
                commit('EOD_ERROR', true);
            }finally {
                commit('EOD_LOADING', false);
            }
        },

        async addEODDraft ({commit, dispatch}, payload) {
            commit('EOD_LOADING', true);
            commit('EOD_ERROR', false);
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            };
            try{
                let response = await axios.post(V1API.eod_draft_create, payload, config).then(result=>{
                    let data = result.data
                    return data

                });

                commit('EOD_SUBMIT_SUCCESS', true);
                dispatch ('fetchMyDraft')
                return response

            }catch (e) {
                commit('EOD_ERROR', true);
            }finally {
                commit('EOD_LOADING', false);
            }
        },
        
        async fetchMyDraft ({ commit }) {
            commit('EOD_LOADING', true);
            commit('EOD_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };
            try{
                let result = await axios.get(V1API.get_my_draft, config);
                let response = result.data
                commit('SET_MY_DRAFT', response.data);

            } catch(error) {
                commit('EOD_ERROR', true);
            }
            commit('EOD_LOADING', false);
        },

        async fetchEODCompleteTask ({ commit }) {
            commit('EOD_LOADING', true);
            commit('EOD_ERROR', false);
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };
            try{
                let result = await axios.get(V1API.eod_completed_task_tickets, config);
                const {tickets,tasks} = result.data.data
                commit('SET_MY_COMPLETE', {tickets,tasks});

            } catch(error) {
                commit('EOD_ERROR', true);
            }
            commit('EOD_LOADING', false);
        },


        async updateEODDraft({commit, state, dispatch}, payload) {
            let response='';
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                  }
            };
            await performNetworkCall(
                commit,
                keyList.smsUpdate,
                async () => {
                    response= await axios.patch(V1API.eod_draft_edit+payload.id,
                        {
                           
                            report: payload.report

                        },config).then(result=>{
                        let data=decode(result.data)
                        return data
                    })

                    dispatch('fetchMyDraft');

                }
            )
            return response
        },
        async deleteEODDraft({commit, dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.delete(V1API.eod_draft_delete + payload.id, config).then(result=>{
                let data = result.data
                return data
            })
            
            dispatch('fetchMyDraft');
            
            return response
        },
        async deleteEOD({commit, dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.delete(V1API.eod_delete + payload.delete, config).then(result=>{
                let data = result.data
                return data
            })
            
            dispatch('fetchAllEOD',{currentPage:payload.page,perPage:payload.perPage});
            
            return response
        },


        
        async fetchMyEOD ({ commit }, payload) {
            commit('EOD_LOADING', true);
            commit('EOD_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try { 
               let result = await axios.get(V1API.get_my_eod_list +'?count='+payload.perPage+'&page='+payload.currentPage, config);

                const {results, count} = result.data

                commit('SET_MY_EOD', {results, count});
                return result

            } catch(error) {
                commit('EOD_ERROR', true);
            } finally {
                commit('EOD_LOADING', false);
            }
        },


        async fetchAllEOD ({ commit },payload) {
            commit('EOD_LOADING', true);
            commit('EOD_ERROR', false);
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };
            try{
                let result = await axios.get(V1API.get_all_eod+'?count='+payload.perPage+'&page='+payload.currentPage, config);
                const {results,pg_count}  = result.data
                commit('SET_ALL_EOD', {results,pg_count});

            } catch(error) {
                commit('EOD_ERROR', true);
            }
            commit('EOD_LOADING', false);
        },

        async fetchEODtDetail ({ commit },payload) {
            commit('EOD_LOADING', true);
            commit('EOD_ERROR', false);
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };
            try{
                let result = await axios.get(V1API.get_eod_details+payload, config);
                let response = result.data
                commit('SET_EOD_DETAIL', response.data);

            } catch(error) {
                commit('EOD_ERROR', true);
            }
            commit('EOD_LOADING', false);
        },

        async addEODReply ({commit, dispatch}, payload) {
            commit('EOD_REPLY_LOADING', true);
            commit('EOD_REPLY_ERROR', false);
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            };
            try{
                let response = await axios.post(V1API.add_eod_reply, {

                    eod: payload.eod,
                    reply:payload.reply,
                }, config).then(result=>{
                    let data = result.data
                    return data

                });

                commit('EOD_SUBMIT_SUCCESS', true);
                // dispatch ('fetchMyRefreTicket')
                
                
                return response

            }catch (e) {
                commit('EOD_REPLY_ERROR', true);
            }finally {
                commit('EOD_REPLY_LOADING', false);
            }
        },


        async fetchEODReply ({ commit }, payload) {
            commit('EOD_REPLY_LOADING', true);
            commit('EOD_REPLY_ERROR', false);
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };
            try{
                let result = await axios.get(V1API.get_reply_of_eod + payload, config);
                let response = result.data
                commit('SET_EOD_REPLY', response.data);

            } catch(error) {
                commit('EOD_REPLY_ERROR', true);
            }
            finally {
                commit('EOD_REPLY_LOADING', false);
            }
            
        },

        async EODAddRefer ({commit, dispatch}, payload) {
            commit('EOD_REFER_LOADING', true);
            commit('EOD_REFER_ERROR', false);
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            };
            try{
                let response = await axios.post(V1API.eod_add_refer + payload.id, {
                    refer_id:payload.refer_id
                }, config).then(result=>{
                    let data = result.data
                    return data

                });

                if(payload.myEOD){
                    dispatch ('fetchMyEOD',{currentPage:payload.page,perPage:payload.perPage})
                }
              
                else if(payload.detail){
                    dispatch ('fetchEODtDetail',payload.id)
                }
                else if(payload.alleod){
                    dispatch ('fetchAllEOD',{currentPage:payload.page,perPage:payload.perPage})
                }
                
              
                return response

            }catch (e) {
                commit('EOD_REFER_ERROR', true);
            }finally {
                commit('EOD_REFER_LOADING', false);
            }
        },






        
        async EODDeleteRefer ({commit, dispatch}, payload) {
            commit('EOD_REFER_LOADING', true);
            commit('EOD_REFER_ERROR', false);
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            };
            try{
                let response = await axios.post(V1API.eod_delete_refer + payload.id, {
                    refer_id:payload.refer_id
                }, config).then(result=>{
                    let data = result.data
                    return data

                });

                
                if(payload.myEOD){
                    dispatch ('fetchMyEOD',{currentPage:payload.page,perPage:payload.perPage})
                }
                else if(payload.detail){
                    dispatch ('fetchEODtDetail',payload.id)
                }
               
                else if(payload.alleod){
                    dispatch ('fetchAllEOD',{currentPage:payload.page,perPage:payload.perPage})
                }
                return response

            }catch (e) {
                commit('EOD_REFER_ERROR', true);
            }finally {
                commit('EOD_REFER_LOADING', false);
            }
        },
        async updateEODStatus({commit, dispatch}, payload) {
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try {

                let response = await axios.get(V1API.edit_eod_status+ payload.id + "/" + payload.status, config).then(result => {
                    let data = result.data
                    return data

                })
             
                dispatch ('fetchEODtDetail',payload.id)
                dispatch ('fetchAllEOD',{currentPage:payload.page,perPage:payload.perPage})
                return response
            }catch (e) {     
                commit('EOD_ERROR', true);
            } finally {
                commit('EOD_LOADING', false);
            }
        },




        

    



        ////////////////////////////////////////////////////////////////////
        async fetchTicket ({ commit }) {
            commit('EOD_LOADING', true);
            commit('EOD_ERROR', false);
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };
            try{
                let result = await axios.get(V1API.get_ticket, config);
                let response = result.data
                commit('SET_TICKET', response.data);

            } catch(error) {
                commit('EOD_ERROR', true);
            }
            commit('EOD_LOADING', false);
        },
        async fetchTicketDetail ({ commit },payload) {
            commit('EOD_LOADING', true);
            commit('EOD_ERROR', false);
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };
            try{
                let result = await axios.get(V1API.get_ticket_details+payload, config);
                let response = result.data
                commit('SET_TICKET_DETAILS', response.data);

            } catch(error) {
                commit('EOD_ERROR', true);
            }
            commit('EOD_LOADING', false);
        },

        

        
        async updateTicket({commit, state, dispatch}, payload) {
            let response='';
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                  }
            };
            await performNetworkCall(
                commit,
                keyList.smsUpdate,
                async () => {
                    response= await axios.patch(V1API.edit_ticket+payload.id,
                        {
                            subject: payload.subject,
                            refer_to: payload.refer_to

                        },config).then(result=>{
                        let data=decode(result.data)
                        return data
                    })

                    dispatch('fetchTicket');

                }
            )
            return response
        },


        async deleteTicket({commit, dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.delete(V1API.delete_ticket + payload, config).then(result=>{
                let data = result.data
                return data
            })
            
            dispatch('fetchTicket');
            
            return response
        },

        async updateTicketStatus({commit, dispatch}, payload) {
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try {

                let response = await axios.get(V1API.update_ticket_status+ payload.id + "/" + payload.status, config).then(result => {
                    let data = result.data
                    return data

                })
                dispatch("fetchMyRefreTicket")
                dispatch("fetchTicket")
                dispatch("fetchTicketDetail",payload.id)
                return response
            } catch (e) {
                
                commit('EOD_ERROR', true);
            } finally {

                commit('EOD_LOADING', false);
            }
        },

        async updateTicketPriority({commit, dispatch}, payload) {
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try {

                let response = await axios.get(V1API.update_ticket_priority+ payload.id + "/" + payload.priority, config).then(result => {
                    let data = result.data
                    return data

                })

                
                dispatch("fetchMyTicket")
                dispatch("fetchTicket")
                dispatch("fetchTicketDetail",payload.id)
                return response
            } catch (e) {

                commit('EOD_ERROR', true);
            } finally {

                commit('EOD_LOADING', false);
            }
        },


        async fetchMyTicket ({ commit }) {
            commit('EOD_LOADING', true);
            commit('EOD_ERROR', false);
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };
            try{
                let result = await axios.get(V1API.get_my_ticket, config);
                let response = result.data
                commit('SET_MY_TICKET', response.data);

            } catch(error) {
                commit('EOD_ERROR', true);
            }
            commit('EOD_LOADING', false);
        },


        async fetchMyRefreTicket ({ commit }) {
            commit('EOD_LOADING', true);
            commit('EOD_ERROR', false);
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };
            try{
                let result = await axios.get(V1API.get_refer_ticket, config);
                let response = result.data
                commit('SET_MY_REFER_TICKET', response.data);

            } catch(error) {
                commit('EOD_ERROR', true);
            }
            commit('EOD_LOADING', false);
        },


        async addTicketReply ({commit, dispatch}, payload) {
            commit('EOD_LOADING', true);
            commit('EOD_ERROR', false);
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            };
            try{
                let response = await axios.post(V1API.add_ticket_reply, {

                    ticket: payload.ticket,
                    reply:payload.reply,
                }, config).then(result=>{
                    let data = result.data
                    return data

                });

                commit('EOD_SUBMIT_SUCCESS', true);
                dispatch ('fetchMyRefreTicket')
                
                
                return response

            }catch (e) {
                commit('EOD_ERROR', true);
            }finally {
                commit('EOD_LOADING', false);
            }
        },


        async fetchTicketReply ({ commit }, payload) {
            commit('EOD_LOADING', true);
            commit('EOD_ERROR', false);
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };
            try{
                let result = await axios.get(V1API.get_replies_of_ticket + payload, config);
                let response = result.data
                commit('SET_TICKET_REPLY', response.data);

            } catch(error) {
                commit('EOD_ERROR', true);
            }
            commit('EOD_LOADING', false);
        },



        async ticketAddRefer ({commit, dispatch}, payload) {
            commit('EOD_LOADING', true);
            commit('EOD_ERROR', false);
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            };
            try{
                let response = await axios.post(V1API.add_refer_to_ticket + payload.id, {
                    refer_id:payload.refer_id
                }, config).then(result=>{
                    let data = result.data
                    return data

                });

                
                dispatch ('fetchMyRefreTicket')
                dispatch ('fetchTicketDetail',payload.id)
                dispatch ('fetchMyTicket')
                dispatch ('fetchTicket')
                return response

            }catch (e) {
                commit('EOD_ERROR', true);
            }finally {
                commit('EOD_LOADING', false);
            }
        },






        
        async ticketDeleteRefer ({commit, dispatch}, payload) {
            commit('EOD_LOADING', true);
            commit('EOD_ERROR', false);
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            };
            try{
                let response = await axios.post(V1API.delete_refer_to_ticket + payload.id, {
                    refer_id:payload.refer_id
                }, config).then(result=>{
                    let data = result.data
                    return data

                });

                
                dispatch ('fetchMyRefreTicket')
                dispatch ('fetchTicketDetail',payload.id)
                dispatch ('fetchMyTicket')
                dispatch ('fetchTicket')
                return response

            }catch (e) {
                commit('EOD_ERROR', true);
            }finally {
                commit('EOD_LOADING', false);
            }
        },


    },

    
    getters: {}
}
