<template>
    <div>
        <Breadcrumbs main="Finance & Accounts" title="Banking Info" />
        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header">
                            <router-link to="/finance-and-accounts/banking-info/create">
                                <button class="btn btn-primary" type="submit" style="display: flex; align-items: center;">
                                    <div style="display: flex; gap: 12px; align-items: center; color: white;">
                                        <i class="fa fa-light fa-plus"></i>
                                        <h7>Add New Bank Account</h7>
                                    </div>
                                </button>
                            </router-link>
                        </div>
    
                        <div class="card-body">
                            <b-row>
                                <b-col xl="6">
                                    <b-input-group class="datatable-btn">
                                        <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
                                        <b-input-group-append>
                                            <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                        </b-input-group-append>
                                    </b-input-group>
                                </b-col>
                                <b-col xl="6">
                                    <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                                        <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                                    </b-form-group>
                                </b-col>
                            </b-row>
    
                            <div v-if="!loading && !error">
                                <div class="table-responsive datatable-vue">
                                    <b-table show-empty stacked="md" :items="items" :fields="tablefields" :filter="filter" :per-page="perPage" @filtered="onFiltered">
                                        <template #cell(index)="data">
                                            {{ (currentPage - 1) * perPage + data.index + 1 }}
                                        </template>
    
                                        <template #cell(account_number)="data">
                                            <router-link :to="'/finance-and-accounts/banking-info/details/'+ data.item.id" class="link-style"> <span>{{ data.item.account_number }}</span></router-link>
                                        </template>
    
                                        <template #cell(status)="data">
                                            <span v-if="data.item.status == true" @click="toggleStatus(data.item.id)" style="cursor: pointer;">
                                                <b-badge pill variant="success">Active</b-badge>
                                            </span>
                                            <span v-if="data.item.status == false" @click="toggleStatus(data.item.id)" style="cursor: pointer;">
                                                <b-badge pill variant="danger">Inactive</b-badge>
                                            </span>
                                        </template>
    
                                        <template #cell(action)="row">
                                            <span style="display: flex; gap: 12px;">
                                                <feather type="delete" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover v-b-modal.modal-center @click="deleteBank(row.item.id)" title="Delete"></feather>
                                                <div @click="editBank(row.item)">
                                                    <feather type="edit" size="20px" style="color: #568ae3; cursor: pointer;" v-b-tooltip.hover title="Edit" v-b-modal.modal-prevent-closing-edit></feather>
                                                </div>
                                            </span>
                                        </template>
                                    </b-table>
                                </div>
                            </div>
                            <div v-else>
                                <div class="loader-box">
                                    <div class="loader-10"></div>
                                </div>
                            </div>
    
                            <b-col md="6" class="my-1 p-0">
                                <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" class="my-0"></b-pagination>
                            </b-col>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Container-fluid Ends-->
    
        <!-- Client Edit Modal -->
        <b-modal id="modal-prevent-closing-edit" ref="modal" title="Edit Client" size="lg" @show="resetEditModal" @hidden="resetEditModal" @ok="handleEditOk">
            <form ref="form" @submit.stop.prevent="handleEditSubmit">
                <div class="row">
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label>Beneficiery Name</label>
                            <input class="form-control" type="text" placeholder="Enter beneficiery name" v-model="bank.beneficiery_name">
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label>Account Title</label>
                            <input class="form-control" type="text" placeholder="Enter account title" v-model="bank.account_title">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label>Account Number</label>
                            <input class="form-control" type="text" placeholder="Enter account number" v-model="bank.account_number">
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label>Account Type</label>
                            <input class="form-control" type="text" placeholder="Enter account type" v-model="bank.account_type">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label>Bank Name</label>
                            <input class="form-control" type="text" placeholder="Enter bank name" v-model="bank.bank_name">
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label>Branch Name & Address</label>
                            <input class="form-control" type="text" placeholder="Enter branch address" v-model="bank.branch_detail">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label>Branch Routing Number</label>
                            <input class="form-control" type="text" placeholder="Enter branch routing number" v-model="bank.branch_routing_number">
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label>Swift Code</label>
                            <input class="form-control" type="text" placeholder="Enter swift code" v-model="bank.swift_number">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label>Beneficiery E-mail Address</label>
                            <input class="form-control" type="text" placeholder="Enter beneficiery email address" v-model="bank.beneficiery_email">
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label>Active Status</label>
                            <select class="form-control digits" v-model="bank.status">
                                <option v-for="(status, index) in statuses" :key="index" :value="status.value">{{ status.title }}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </form>
        </b-modal>
    
        <!-- Delete Modal -->
        <b-modal id="modal-center" centered hide-header @ok="handleOk">
            <div class="mt-4 mb-4" style="font-size: large;">Are you sure you want to delete this account?</div>
        </b-modal>
    </div>
</template>
    
<script>
    import {
        mapState
    } from 'vuex';
    import Swal from 'sweetalert2/dist/sweetalert2.js';
    import 'sweetalert2/src/sweetalert2.scss';
    
    export default {
        data() {
            return {
                tablefields: [
                    {
                        key: 'index',
                        label: '#'
                    },
                    {
                        key: 'bank_name',
                        label: 'Bank',
                        sortable: true,
                    },
                    {
                        key: 'account_title',
                        label: 'Account Title',
                        sortable: true,
                    },
                    {
                        key: 'account_number',
                        label: 'Account Number',
                        sortable: true,
                    },
                    {
                        key: 'branch_detail',
                        label: 'branch',
                        sortable: true,
                    },
                    {
                        key: 'status',
                        label: 'Status',
                        sortable: true,
                    },
                    {
                        key: 'action',
                        label: 'Action',
                        sortable: true,
                    },
                ],

                bank: {
                    beneficiery_name: '',
                    account_title: '',
                    account_number: '',
                    account_type: '',
                    bank_name: '',
                    branch_detail: '',
                    branch_routing_number: '',
                    swift_number: '',
                    beneficiery_email: '',
                    status: true
                },

                statuses: [
                    {
                        title: 'Active',
                        value: true
                    },
                    {
                        title: 'Inactive',
                        value: false
                    }
                ],
    
                edit_value: null,
                delete_value: null,
    
                filter: null,
                totalRows: 1,
                currentPage: 1,
                perPage: 25,
                pageOptions: [25, 50, 100],
            };
        },
    
        computed: {
            ...mapState({
                items: state => state.bank.accounts,
                loading: state => state.bank.submitLoading,
                error: state => state.bank.submitError
            }),
    
            sortOptions() {
                return this.tablefields
                .filter(f => f.sortable)
                .map(f => {
                    return {
                        text: f.label,
                        value: f.key
                    };
                });
            }
        },
    
        mounted() {
            this.$store.dispatch("bank/fetchBankAccounts")
        },
    
        methods: {
            editBank(bank) {
                this.edit_value = bank.id
                this.bank.beneficiery_name = bank.beneficiery_name
                this.bank.account_title = bank.account_title
                this.bank.account_number = bank.account_number
                this.bank.account_type = bank.account_type
                this.bank.bank_name = bank.bank_name
                this.bank.branch_detail = bank.branch_detail
                this.bank.branch_routing_number = bank.branch_routing_number
                this.bank.swift_number = bank.swift_number
                this.bank.beneficiery_email = bank.beneficiery_email
                this.bank.status = bank.status
            },
            
            handleEditOk(bvModalEvent) {
                bvModalEvent.preventDefault()
                this.handleEditSubmit()
            },
    
            handleEditSubmit() {
                this.$store.dispatch("bank/updateBankAccount", {id: this.edit_value, account: this.bank}).then(response => {
                    if (response.code == 200) {
                        Swal.fire({
                            title: "Success!",
                            text: response.response,
                            icon: "success"
                        });
    
                        this.edit_value = null,
                        this.bank.beneficiery_name = '',
                        this.bank.account_title = '',
                        this.bank.account_number = '',
                        this.bank.account_type = '',
                        this.bank.bank_name = '',
                        this.bank.branch_detail = ''
                        this.bank.branch_routing_number = ''
                        this.bank.swift_number = ''
                        this.bank.beneficiery_email = ''
                        this.bank.status = true
    
                    } else {
                        Swal.fire({
                            title: "Error!",
                            text: response.response,
                            icon: "error"
                        });
                    }
                })
    
                // Hide the modal manually
                this.$nextTick(() => {
                    this.$bvModal.hide('modal-prevent-closing-edit')
                })
            },
    
            onFiltered(filteredItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering
                this.totalRows = filteredItems.length;
                this.currentPage = 1;
            },
    
            deleteBank(id) {
                this.delete_value = id
            },
    
            handleOk() {
                this.$store.dispatch("bank/deleteBankAccount", this.delete_value).then(response => {
                    if (response.code == 200) {
                        Swal.fire({
                            title: "Success!",
                            text: response.response,
                            icon: "success"
                        });
    
                        this.delete_value = null
    
                    } else {
                        Swal.fire({
                            title: "Error!",
                            text: response.response,
                            icon: "error"
                        });
                    }
                })
            },

            toggleStatus(id) {
                this.$store.dispatch("bank/toggleActiveStatus", id).then(response => {
                    if (response.code == 200) {
                        Swal.fire({
                            title: "Success!",
                            text: response.response,
                            icon: "success"
                        });
                    } else {
                        Swal.fire({
                            title: "Error!",
                            text: response.response,
                            icon: "error"
                        });
                    }
                })
            }
        }
    };
    </script>
    