import { render, staticRenderFns } from "./vendor-contact.vue?vue&type=template&id=303ce5e4&scoped=true&"
import script from "./vendor-contact.vue?vue&type=script&lang=js&"
export * from "./vendor-contact.vue?vue&type=script&lang=js&"
import style0 from "./vendor-contact.vue?vue&type=style&index=0&id=303ce5e4&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "303ce5e4",
  null
  
)

export default component.exports