<template>
<div>
    <Breadcrumbs main="Project" title="Projects" />
    <!-- Container-fluid starts-->
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12 project-list">
                <div class="card">
                    <div class="row m-0">
                        <div class="col-sm-12 p-0">
                            <b-tabs content-class="mt-3" v-model="tabIndex">
                                <b-tab title="All" :title-link-class="linkClass(0)" @click="categoryType('ALL')">
                                    <template v-slot:title>
                                        <div >
                                            <feather type="target" class="tab-title"></feather> All
                                        </div>
                                    </template>
                                    <b-card-text>
                                        <div class="col-sm-12 p-0">
                                            <div class="card" v-if="!loading && !error">
                                                <div class="card-body">
                                                    <div class="row mb-4" v-if="projects.length>0">
                                                        <div style="display: flex; flex-direction: row; justify-content: space-between" class="col-xl-12">
                                                            <b-input-group class="col-xl-4">
                                                                <b-form-input v-model="query" placeholder="Type to Search"></b-form-input>
                                                                <b-input-group-append>
                                                                    <b-button @click="findProjects()">Search</b-button>
                                                                </b-input-group-append>
                                                            </b-input-group>
                                                            <div style="display: flex; gap:15px">
                                                                <feather type="list" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover title="List View" @click="toggleGrid"> </feather>
                                                                <feather type="grid" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover title="Grid View" @click="toggleList"> </feather>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="row" v-if="projects.length > 0">
                                                        <div v-if="showGrid" class="col-xl-4 col-lg-6" v-for="(project, index) in projects" :key="index" :class="index < 3 ? 'mb-4' : 'mb-4'">
                                                            <div class="project-box">
                                                                <div class="my-4">
                                                                    <span v-if="project.status == 'APPROVED'" class="badge" :class="'badge-primary'">{{ project.status }}</span>
                                                                    <span v-if="project.status == 'PENDING'" class="badge" :class="'badge-danger'">{{ project.status }}</span>
                                                                    <span v-if="project.status == 'WORKING'" class="badge" :class="'badge-info'">{{ project.status }}</span>
                                                                    <span v-if="project.status == 'COMPLETED'" class="badge" :class="'badge-success'">{{ project.status }}</span>
                                                                </div>

                                                                <router-link :to="{ path: '/project/details/' + project.id }" class="link-style">
                                                                    <h6>{{ project.title }}</h6>
                                                                </router-link>
                                                                <div style="display: flex; align-items: start; justify-content: flex-start;">
                                                                    <h7 class="badge-pill badge-danger ">{{ project.deadline }}</h7>
                                                                </div>

                                                                <!-- <div v-if="project.details.length < 200" v-html="project.details"></div> -->

                                                                <div class="mt-3" v-html="project.details" style="height: 200px; overflow: hidden; text-overflow: ellipsis;"></div>

                                                                <div class="mt-3 row details">
                                                                    <div class="col-6">
                                                                        <span>Tasks</span>
                                                                    </div>
                                                                    <div v-if="project.status == 'APPROVED'" class="col-6" style="color: #1c1c1c;">{{ project.task }}</div>
                                                                    <div v-if="project.status == 'PENDING'" class="col-6" :class="'text-danger'">{{ project.task }}</div>
                                                                    <div v-if="project.status == 'WORKING'" class="col-6" style="color: #a426f2;">{{ project.task }}</div>
                                                                    <div v-if="project.status == 'COMPLETED'" class="col-6" :class="'text-success'">{{ project.task }}</div>
                                                                    <div class="col-6">
                                                                        <span>Resolved</span>
                                                                    </div>
                                                                    <div v-if="project.status == 'APPROVED'" class="col-6" style="color: #1c1c1c;">{{ project.resolved }}</div>
                                                                    <div v-if="project.status == 'PENDING'" class="col-6" :class="'text-danger'">{{ project.resolved }}</div>
                                                                    <div v-if="project.status == 'WORKING'" class="col-6" style="color: #a426f2;">{{ project.resolved }}</div>
                                                                    <div v-if="project.status == 'COMPLETED'" class="col-6" :class="'text-success'">{{ project.resolved }}</div>
                                                                    <div class="col-6">
                                                                        <span>Remaining</span>
                                                                    </div>
                                                                    <div v-if="project.status == 'APPROVED'" class="col-6" style="color: #1c1c1c;">{{ project.remaining }}</div>
                                                                    <div v-if="project.status == 'PENDING'" class="col-6" :class="'text-danger'">{{ project.remaining }}</div>
                                                                    <div v-if="project.status == 'WORKING'" class="col-6" style="color: #a426f2;">{{ project.remaining }}</div>
                                                                    <div v-if="project.status == 'COMPLETED'" class="col-6" :class="'text-success'">{{ project.remaining }}</div>
                                                                </div>

                                                                <div class="customers mt-3" v-if="project.teammates.length > 0">
                                                                    <ul v-for="(team, index) in project.teammates" :key="index">
                                                                        <li class="d-inline-block" v-if="team.user_id.additional_info">
                                                                            <img class="img-30 rounded-circle" :src="host + team.user_id.additional_info.photo" alt="" v-b-tooltip.hover :title="team.user_name">
                                                                        </li>
                                                                    </ul>
                                                                </div>

                                                                <div class="project-status mt-3">
                                                                    <div class="media mb-0">
                                                                        <p>{{projectDoneTaskPercentage(project)}}%</p>
                                                                        <div class="media-body text-right">
                                                                            <span>Done</span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="progress" style="height: 5px">
                                                                        <div v-if="project.status == 'APPROVED'" class="progress-bar-animated progress-bar-striped" :class="'bg-primary'" role="progressbar" :style="{'width': projectDoneTaskPercentage(project) + '%'}" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                                                                        <div v-if="project.status == 'PENDING'" class="progress-bar-animated progress-bar-striped" :class="'bg-danger'" role="progressbar" :style="{'width': projectDoneTaskPercentage(project) + '%'}" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                                                                        <div v-if="project.status == 'WORKING'" class="progress-bar-animated progress-bar-striped" :class="'bg-info'" role="progressbar" :style="{'width': projectDoneTaskPercentage(project) + '%'}" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                                                                        <div v-if="project.status == 'COMPLETED'" class="progress-bar-animated progress-bar-striped" :class="'bg-success'" role="progressbar" :style="{'width': projectDoneTaskPercentage(project) + '%'}" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div v-if="showList" class="col-xl-12">
                                                            <b-row>
                                                                <b-col xl="12">
                                                                    <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                                                                        <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                                                                    </b-form-group>
                                                                </b-col>
                                                            </b-row>

                                                            <div class="table-responsive datatable-vue">
                                                                <b-table show-empty stacked="md" :items="projects" :fields="tablefields" :filter="filter" :current-page="currentPage" :per-page="perPage" @filtered="onFiltered">
                                                                    <template #cell(index)="data">
                                                                        {{ (currentPage - 1) * perPage + data.index + 1 }}
                                                                    </template>

                                                                    <template #cell(title)="data">
                                                                        <span style="display: flex; flex-direction: column; gap: 8px;">
                                                                            <router-link :to="{ path: '/project/details/' + data.item.id }" class="link-style" style="font-size:14px">{{data.item.title}}</router-link>
                                                                            <small><span style="font-size: medium;">
                                                                                    <span v-if="data.item.status == 'APPROVED'" class="badge" :class="'badge-primary'">{{ data.item.status }}</span>
                                                                                    <span v-if="data.item.status == 'PENDING'" class="badge" :class="'badge-danger'">{{ data.item.status }}</span>
                                                                                    <span v-if="data.item.status == 'WORKING'" class="badge" :class="'badge-info'">{{ data.item.status }}</span>
                                                                                    <span v-if="data.item.status == 'COMPLETED'" class="badge" :class="'badge-success'">{{ data.item.status }}</span>
                                                                                </span>
                                                                            </small>

                                                                        </span>
                                                                    </template>

                                                                    <template #cell(date)="data">
                                                                        <div class="d-flex flex-column">
                                                                            <span>Start Date:</span>
                                                                            <div class="badge badge-primary" style="max-width:fit-content">{{ data.item.start_date }}</div>
                                                                            <span>Deadline:</span>
                                                                            <div class="badge badge-danger" style="max-width:fit-content">{{ data.item.deadline }}</div>

                                                                        </div>

                                                                    </template>

                                                                    <template #cell(teammember)="data">
                                                                        <div class="customers mt-1 " v-if="data.item.teammates.length > 0" style="display: grid; grid-template-columns: repeat(5, minmax(0, 1fr)); gap:5px">
                                                                            <ul v-for="(team, index) in data.item.teammates.slice(0,15)" :key="index">
                                                                                <li class="d-inline-block" v-if="team.user_id.additional_info">
                                                                                    <img class="img-30 rounded-circle" :src="host + team.user_id.additional_info.photo" alt="" v-b-tooltip.hover :title="team.user_name">
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </template>

                                                                    <template #cell(task)="data">
                                                                        <div class="d-flex flex-column">
                                                                            <div style="color: #1c1c1c;"> Task: {{ data.item.task }}</div>
                                                                            <div style="color: #1c1c1c;">Resolved: {{ data.item.resolved }}</div>
                                                                            <div style="color: #1c1c1c;">Remaining: {{ data.item.remaining }}</div>
                                                                        </div>

                                                                    </template>
                                                                    <template #cell(client_name)="data">
                                                                        <div v-if="data.item.client_name.length < 1">
                                                                            <span>N/A</span>
                                                                        </div>
                                                                        <div v-for="client in data.item.client_name.slice(0,3)" :key="client.client_id" style="width:150px">
                                                                            <span>{{client.client_name}}</span>
                                                                        </div>
                                                                        <div v-if="data.item.client_name.length > 3">
                                                                            <span class="badge badge-primary" style="max-width:fit-content" @click="clientInformation(data.item)" v-b-modal.modal-prevent-closing-client>See More</span>
                                                                        </div>
                                                                    </template>

                                                                </b-table>
                                                            </div>

                                                            <b-col md="6" class="my-1 p-0">
                                                                <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" class="my-0"></b-pagination>
                                                            </b-col>
                                                        </div>
                                                    </div>
                                                    <div v-else class="text-center">
                                                        <h6>No Project Found</h6>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card" v-else>
                                                <div class="loader-box">
                                                    <div class="loader-10"></div>
                                                </div>
                                            </div>

                                        </div>
                                    </b-card-text>
                                </b-tab>

                                <b-tab title="Approved" :title-link-class="linkClass(1)" @click="categoryType('APPROVED')">
                                    <template v-slot:title>
                                        <div >
                                            <feather type="disc"></feather> Approved
                                        </div>
                                    </template>
                                    <b-card-text>
                                        <div class="col-sm-12 p-0">
                                            <div class="card" v-if="!loading && !error">
                                                <div class="card-body">
                                                    <div class="row mb-4" v-if="projects.length>0">
                                                        <div style="display: flex; flex-direction: row; justify-content: space-between" class="col-xl-12">
                                                            <b-input-group class="col-xl-4">
                                                                <b-form-input v-model="query" placeholder="Type to Search"></b-form-input>
                                                                <b-input-group-append>
                                                                    <b-button @click="findProjects()">Search</b-button>
                                                                </b-input-group-append>
                                                            </b-input-group>
                                                            <div style="display: flex; gap:15px">
                                                                <feather type="list" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover title="List View" @click="toggleGrid"> </feather>
                                                                <feather type="grid" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover title="Grid View" @click="toggleList"> </feather>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row" v-if="projects.length>0">
                                                        <div v-if="showGrid" class="col-xl-4 col-lg-6" v-for="(project, index) in projects" :key="index" :class="index < 3 ? 'mb-4' : 'mb-4'">
                                                            <div class="project-box">
                                                                <div class="my-4">
                                                                    <span v-if="project.status == 'APPROVED'" class="badge" :class="'badge-primary'">{{ project.status }}</span>
                                                                    <span v-if="project.status == 'PENDING'" class="badge" :class="'badge-danger'">{{ project.status }}</span>
                                                                    <span v-if="project.status == 'WORKING'" class="badge" :class="'badge-info'">{{ project.status }}</span>
                                                                    <span v-if="project.status == 'COMPLETED'" class="badge" :class="'badge-success'">{{ project.status }}</span>
                                                                </div>
                                                                <router-link :to="{ path: '/project/details/' + project.id }" class="link-style">
                                                                    <h6>{{ project.title }}</h6>
                                                                </router-link>
                                                                <div style="display: flex; align-items: start; justify-content: flex-start;">
                                                                    <h7 class="badge-pill badge-danger ">{{ project.deadline }}</h7>
                                                                </div>

                                                                <!-- <div v-if="project.details.length < 200" v-html="project.details"></div> -->
                                                                <div class="mt-3" v-html="project.details" style="height: 200px; overflow: hidden; text-overflow: ellipsis;"></div>

                                                                <div class="mt-3 row details">
                                                                    <div class="col-6">
                                                                        <span>Tasks</span>
                                                                    </div>
                                                                    <div v-if="project.status == 'APPROVED'" class="col-6" style="color: #1c1c1c;">{{ project.task }}</div>
                                                                    <div v-if="project.status == 'PENDING'" class="col-6" :class="'text-danger'">{{ project.task }}</div>
                                                                    <div v-if="project.status == 'WORKING'" class="col-6" style="color: #a426f2;">{{ project.task }}</div>
                                                                    <div v-if="project.status == 'COMPLETED'" class="col-6" :class="'text-success'">{{ project.task }}</div>
                                                                    <div class="col-6">
                                                                        <span>Resolved</span>
                                                                    </div>
                                                                    <div v-if="project.status == 'APPROVED'" class="col-6" style="color: #1c1c1c;">{{ project.resolved }}</div>
                                                                    <div v-if="project.status == 'PENDING'" class="col-6" :class="'text-danger'">{{ project.resolved }}</div>
                                                                    <div v-if="project.status == 'WORKING'" class="col-6" style="color: #a426f2;">{{ project.resolved }}</div>
                                                                    <div v-if="project.status == 'COMPLETED'" class="col-6" :class="'text-success'">{{ project.resolved }}</div>
                                                                    <div class="col-6">
                                                                        <span>Remaining</span>
                                                                    </div>
                                                                    <div v-if="project.status == 'APPROVED'" class="col-6" style="color: #1c1c1c;">{{ project.remaining }}</div>
                                                                    <div v-if="project.status == 'PENDING'" class="col-6" :class="'text-danger'">{{ project.remaining }}</div>
                                                                    <div v-if="project.status == 'WORKING'" class="col-6" style="color: #a426f2;">{{ project.remaining }}</div>
                                                                    <div v-if="project.status == 'COMPLETED'" class="col-6" :class="'text-success'">{{ project.remaining }}</div>
                                                                </div>

                                                                <div class="customers mt-3" v-if="project.teammates.length > 0">
                                                                    <ul v-for="(team, index) in project.teammates" :key="index">
                                                                        <li class="d-inline-block" v-if="team.user_id.additional_info">
                                                                            <img class="img-30 rounded-circle" :src="host + team.user_id.additional_info.photo" alt="" v-b-tooltip.hover :title="team.user_name">
                                                                        </li>
                                                                    </ul>
                                                                </div>

                                                                <div class="project-status mt-3">
                                                                    <div class="media mb-0">
                                                                        <p>{{projectDoneTaskPercentage(project)}}%</p>
                                                                        <div class="media-body text-right">
                                                                            <span>Done</span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="progress" style="height: 5px">
                                                                        <div v-if="project.status == 'APPROVED'" class="progress-bar-animated progress-bar-striped" :class="'bg-primary'" role="progressbar" :style="{'width': projectDoneTaskPercentage(project) + '%'}" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                                                                        <div v-if="project.status == 'PENDING'" class="progress-bar-animated progress-bar-striped" :class="'bg-danger'" role="progressbar" :style="{'width': projectDoneTaskPercentage(project) + '%'}" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                                                                        <div v-if="project.status == 'WORKING'" class="progress-bar-animated progress-bar-striped" :class="'bg-info'" role="progressbar" :style="{'width': projectDoneTaskPercentage(project) + '%'}" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                                                                        <div v-if="project.status == 'COMPLETED'" class="progress-bar-animated progress-bar-striped" :class="'bg-success'" role="progressbar" :style="{'width': projectDoneTaskPercentage(project) + '%'}" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div v-if="showList" class="col-xl-12">
                                                            <b-row>
                                                                <b-col xl="12">
                                                                    <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                                                                        <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                                                                    </b-form-group>
                                                                </b-col>
                                                            </b-row>

                                                            <div class="table-responsive datatable-vue">
                                                                <b-table show-empty stacked="md" :items="projects" :fields="tablefields" :filter="filter" :current-page="currentPage" :per-page="perPage" @filtered="onFiltered">
                                                                    <template #cell(index)="data">
                                                                        {{ (currentPage - 1) * perPage + data.index + 1 }}
                                                                    </template>

                                                                    <template #cell(title)="data">
                                                                        <span style="display: flex; flex-direction: column; gap: 8px;">
                                                                            <router-link :to="{ path: '/project/details/' + data.item.id }" class="link-style" style="font-size:14px">{{data.item.title}}</router-link>
                                                                            <small><span style="font-size: medium;">
                                                                                    <span v-if="data.item.status == 'APPROVED'" class="badge" :class="'badge-primary'">{{ data.item.status }}</span>
                                                                                    <span v-if="data.item.status == 'PENDING'" class="badge" :class="'badge-danger'">{{ data.item.status }}</span>
                                                                                    <span v-if="data.item.status == 'WORKING'" class="badge" :class="'badge-info'">{{ data.item.status }}</span>
                                                                                    <span v-if="data.item.status == 'COMPLETED'" class="badge" :class="'badge-success'">{{ data.item.status }}</span>
                                                                                </span>
                                                                            </small>

                                                                        </span>
                                                                    </template>

                                                                    <template #cell(date)="data">
                                                                        <div class="d-flex flex-column">
                                                                            <span>Start Date:</span>
                                                                            <div class="badge badge-primary" style="max-width:fit-content">{{ data.item.start_date }}</div>
                                                                            <span>Deadline:</span>
                                                                            <div class="badge badge-danger" style="max-width:fit-content">{{ data.item.deadline }}</div>

                                                                        </div>

                                                                    </template>

                                                                    <template #cell(teammember)="data">
                                                                        <div class="customers mt-1 " v-if="data.item.teammates.length > 0" style="display: grid; grid-template-columns: repeat(5, minmax(0, 1fr)); gap:5px">
                                                                            <ul v-for="(team, index) in data.item.teammates.slice(0,15)" :key="index">
                                                                                <li class="d-inline-block" v-if="team.user_id.additional_info">
                                                                                    <img class="img-30 rounded-circle" :src="host + team.user_id.additional_info.photo" alt="" v-b-tooltip.hover :title="team.user_name">
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </template>

                                                                    <template #cell(task)="data">
                                                                        <div class="d-flex flex-column">
                                                                            <div style="color: #1c1c1c;"> Task: {{ data.item.task }}</div>
                                                                            <div style="color: #1c1c1c;">Resolved: {{ data.item.resolved }}</div>
                                                                            <div style="color: #1c1c1c;">Remaining: {{ data.item.remaining }}</div>
                                                                        </div>

                                                                    </template>
                                                                    <template #cell(client_name)="data">
                                                                        <div v-if="data.item.client_name.length < 1">
                                                                            <span>N/A</span>
                                                                        </div>
                                                                        <div v-for="client in data.item.client_name.slice(0,3)" :key="client.client_id" style="width:150px">
                                                                            <span>{{client.client_name}}</span>
                                                                        </div>
                                                                        <div v-if="data.item.client_name.length > 3">
                                                                            <span class="badge badge-primary" style="max-width:fit-content" @click="clientInformation(data.item)" v-b-modal.modal-prevent-closing-client>See More</span>
                                                                        </div>
                                                                    </template>

                                                                </b-table>
                                                            </div>

                                                            <b-col md="6" class="my-1 p-0">
                                                                <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" class="my-0"></b-pagination>
                                                            </b-col>
                                                        </div>
                                                    </div>
                                                    <div v-else class="text-center">
                                                        <h6>No Project Found</h6>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card" v-else>
                                                <div class="loader-box">
                                                    <div class="loader-10"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </b-card-text>
                                </b-tab>

                                <b-tab title="Pending" :title-link-class="linkClass(2)" @click="categoryType('PENDING')">
                                    <template v-slot:title>
                                        <div >
                                            <feather type="info"></feather> Pending
                                        </div>
                                    </template>
                                    <b-card-text>
                                        <div class="col-sm-12 p-0">
                                            <div class="card" v-if="!loading && !error">
                                                <div class="card-body">
                                                    <div class="row mb-4" v-if="projects.length>0">
                                                        <div style="display: flex; flex-direction: row; justify-content: space-between" class="col-xl-12">
                                                            <b-input-group class="col-xl-4">
                                                                <b-form-input v-model="query" placeholder="Type to Search"></b-form-input>
                                                                <b-input-group-append>
                                                                    <b-button @click="findProjects()">Search</b-button>
                                                                </b-input-group-append>
                                                            </b-input-group>
                                                            <div style="display: flex; gap:15px">
                                                                <feather type="list" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover title="List View" @click="toggleGrid"> </feather>
                                                                <feather type="grid" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover title="Grid View" @click="toggleList"> </feather>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row" v-if="projects.length>0">
                                                        <div v-if="showGrid" class="col-xl-4 col-lg-6" v-for="(project, index) in projects" :key="index" :class="index < 3 ? 'mb-4' : 'mb-4'">
                                                            <div class="project-box">
                                                                <div class="my-4">
                                                                    <span v-if="project.status == 'APPROVED'" class="badge" :class="'badge-primary'">{{ project.status }}</span>
                                                                    <span v-if="project.status == 'PENDING'" class="badge" :class="'badge-danger'">{{ project.status }}</span>
                                                                    <span v-if="project.status == 'WORKING'" class="badge" :class="'badge-info'">{{ project.status }}</span>
                                                                    <span v-if="project.status == 'COMPLETED'" class="badge" :class="'badge-success'">{{ project.status }}</span>
                                                                </div>

                                                                <router-link :to="{ path: '/project/details/' + project.id }" class="link-style">
                                                                    <h6>{{ project.title }}</h6>
                                                                </router-link>
                                                                <div style="display: flex; align-items: start; justify-content: flex-start;">
                                                                    <h7 class="badge-pill badge-danger ">{{ project.deadline }}</h7>
                                                                </div>

                                                                <!-- <div v-if="project.details.length < 200" v-html="project.details"></div> -->
                                                                <div class="mt-3" v-html="project.details" style="height: 200px; overflow: hidden; text-overflow: ellipsis;"></div>

                                                                <div class="mt-3 row details">
                                                                    <div class="col-6">
                                                                        <span>Tasks</span>
                                                                    </div>
                                                                    <div v-if="project.status == 'APPROVED'" class="col-6" style="color: #1c1c1c;">{{ project.task }}</div>
                                                                    <div v-if="project.status == 'PENDING'" class="col-6" :class="'text-danger'">{{ project.task }}</div>
                                                                    <div v-if="project.status == 'WORKING'" class="col-6" style="color: #a426f2;">{{ project.task }}</div>
                                                                    <div v-if="project.status == 'COMPLETED'" class="col-6" :class="'text-success'">{{ project.task }}</div>
                                                                    <div class="col-6">
                                                                        <span>Resolved</span>
                                                                    </div>
                                                                    <div v-if="project.status == 'APPROVED'" class="col-6" style="color: #1c1c1c;">{{ project.resolved }}</div>
                                                                    <div v-if="project.status == 'PENDING'" class="col-6" :class="'text-danger'">{{ project.resolved }}</div>
                                                                    <div v-if="project.status == 'WORKING'" class="col-6" style="color: #a426f2;">{{ project.resolved }}</div>
                                                                    <div v-if="project.status == 'COMPLETED'" class="col-6" :class="'text-success'">{{ project.resolved }}</div>
                                                                    <div class="col-6">
                                                                        <span>Remaining</span>
                                                                    </div>
                                                                    <div v-if="project.status == 'APPROVED'" class="col-6" style="color: #1c1c1c;">{{ project.remaining }}</div>
                                                                    <div v-if="project.status == 'PENDING'" class="col-6" :class="'text-danger'">{{ project.remaining }}</div>
                                                                    <div v-if="project.status == 'WORKING'" class="col-6" style="color: #a426f2;">{{ project.remaining }}</div>
                                                                    <div v-if="project.status == 'COMPLETED'" class="col-6" :class="'text-success'">{{ project.remaining }}</div>
                                                                </div>

                                                                <div class="customers mt-3" v-if="project.teammates.length > 0">
                                                                    <ul v-for="(team, index) in project.teammates" :key="index">
                                                                        <li class="d-inline-block" v-if="team.user_id.additional_info">
                                                                            <img class="img-30 rounded-circle" :src="host + team.user_id.additional_info.photo" alt="" v-b-tooltip.hover :title="team.user_name">
                                                                        </li>
                                                                    </ul>
                                                                </div>

                                                                <div class="project-status mt-3">
                                                                    <div class="media mb-0">
                                                                        <p>{{projectDoneTaskPercentage(project)}}%</p>
                                                                        <div class="media-body text-right">
                                                                            <span>Done</span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="progress" style="height: 5px">
                                                                        <div v-if="project.status == 'APPROVED'" class="progress-bar-animated progress-bar-striped" :class="'bg-primary'" role="progressbar" :style="{'width': projectDoneTaskPercentage(project) + '%'}" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                                                                        <div v-if="project.status == 'PENDING'" class="progress-bar-animated progress-bar-striped" :class="'bg-danger'" role="progressbar" :style="{'width': projectDoneTaskPercentage(project) + '%'}" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                                                                        <div v-if="project.status == 'WORKING'" class="progress-bar-animated progress-bar-striped" :class="'bg-info'" role="progressbar" :style="{'width': projectDoneTaskPercentage(project) + '%'}" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                                                                        <div v-if="project.status == 'COMPLETED'" class="progress-bar-animated progress-bar-striped" :class="'bg-success'" role="progressbar" :style="{'width': projectDoneTaskPercentage(project) + '%'}" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div v-if="showList" class="col-xl-12">
                                                            <b-row>
                                                                <b-col xl="12">
                                                                    <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                                                                        <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                                                                    </b-form-group>
                                                                </b-col>
                                                            </b-row>

                                                            <div class="table-responsive datatable-vue">
                                                                <b-table show-empty stacked="md" :items="projects" :fields="tablefields" :filter="filter" :current-page="currentPage" :per-page="perPage" @filtered="onFiltered">
                                                                    <template #cell(index)="data">
                                                                        {{ (currentPage - 1) * perPage + data.index + 1 }}
                                                                    </template>

                                                                    <template #cell(title)="data">
                                                                        <span style="display: flex; flex-direction: column; gap: 8px;">
                                                                            <router-link :to="{ path: '/project/details/' + data.item.id }" class="link-style" style="font-size:14px">{{data.item.title}}</router-link>
                                                                            <small><span style="font-size: medium;">
                                                                                    <span v-if="data.item.status == 'APPROVED'" class="badge" :class="'badge-primary'">{{ data.item.status }}</span>
                                                                                    <span v-if="data.item.status == 'PENDING'" class="badge" :class="'badge-danger'">{{ data.item.status }}</span>
                                                                                    <span v-if="data.item.status == 'WORKING'" class="badge" :class="'badge-info'">{{ data.item.status }}</span>
                                                                                    <span v-if="data.item.status == 'COMPLETED'" class="badge" :class="'badge-success'">{{ data.item.status }}</span>
                                                                                </span>
                                                                            </small>

                                                                        </span>
                                                                    </template>

                                                                    <template #cell(date)="data">
                                                                        <div class="d-flex flex-column">
                                                                            <span>Start Date:</span>
                                                                            <div class="badge badge-primary" style="max-width:fit-content">{{ data.item.start_date }}</div>
                                                                            <span>Deadline:</span>
                                                                            <div class="badge badge-danger" style="max-width:fit-content">{{ data.item.deadline }}</div>

                                                                        </div>

                                                                    </template>

                                                                    <template #cell(teammember)="data">
                                                                        <div class="customers mt-1 " v-if="data.item.teammates.length > 0" style="display: grid; grid-template-columns: repeat(5, minmax(0, 1fr)); gap:5px">
                                                                            <ul v-for="(team, index) in data.item.teammates.slice(0,15)" :key="index">
                                                                                <li class="d-inline-block" v-if="team.user_id.additional_info">
                                                                                    <img class="img-30 rounded-circle" :src="host + team.user_id.additional_info.photo" alt="" v-b-tooltip.hover :title="team.user_name">
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </template>

                                                                    <template #cell(task)="data">
                                                                        <div class="d-flex flex-column">
                                                                            <div style="color: #1c1c1c;"> Task: {{ data.item.task }}</div>
                                                                            <div style="color: #1c1c1c;">Resolved: {{ data.item.resolved }}</div>
                                                                            <div style="color: #1c1c1c;">Remaining: {{ data.item.remaining }}</div>
                                                                        </div>

                                                                    </template>
                                                                    <template #cell(client_name)="data">
                                                                        <div v-if="data.item.client_name.length < 1">
                                                                            <span>N/A</span>
                                                                        </div>
                                                                        <div v-for="client in data.item.client_name.slice(0,3)" :key="client.client_id" style="width:150px">
                                                                            <span>{{client.client_name}}</span>
                                                                        </div>
                                                                        <div v-if="data.item.client_name.length > 3">
                                                                            <span class="badge badge-primary" style="max-width:fit-content" @click="clientInformation(data.item)" v-b-modal.modal-prevent-closing-client>See More</span>
                                                                        </div>
                                                                    </template>

                                                                </b-table>
                                                            </div>

                                                            <b-col md="6" class="my-1 p-0">
                                                                <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" class="my-0"></b-pagination>
                                                            </b-col>
                                                        </div>
                                                    </div>
                                                    <div v-else class="text-center">
                                                        <h6>No Project Found</h6>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card" v-else>
                                                <div class="loader-box">
                                                    <div class="loader-10"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </b-card-text>
                                </b-tab>

                                <b-tab title="Working" :title-link-class="linkClass(3)" @click="categoryType('WORKING')">
                                    <template v-slot:title>
                                        <div>
                                            <feather type="compass"></feather> Working
                                        </div>
                                    </template>
                                    <b-card-text>
                                        <div class="col-sm-12 p-0">
                                            <div class="card" v-if="!loading && !error">
                                                <div class="card-body">
                                                    <div class="row mb-4" v-if="projects.length>0">
                                                        <div style="display: flex; flex-direction: row; justify-content: space-between" class="col-xl-12">
                                                            <b-input-group class="col-xl-4">
                                                                <b-form-input v-model="query" placeholder="Type to Search"></b-form-input>
                                                                <b-input-group-append>
                                                                    <b-button @click="findProjects()">Search</b-button>
                                                                </b-input-group-append>
                                                            </b-input-group>
                                                            <div style="display: flex; gap:15px">
                                                                <feather type="list" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover title="List View" @click="toggleGrid"> </feather>
                                                                <feather type="grid" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover title="Grid View" @click="toggleList"> </feather>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row" v-if="projects.length>0">
                                                        <div v-if="showGrid" class="col-xl-4 col-lg-6" v-for="(project, index) in projects" :key="index" :class="index < 3 ? 'mb-4' : 'mb-4'">
                                                            <div class="project-box">
                                                                <div class="my-4">
                                                                    <span v-if="project.status == 'APPROVED'" class="badge" :class="'badge-primary'">{{ project.status }}</span>
                                                                    <span v-if="project.status == 'PENDING'" class="badge" :class="'badge-danger'">{{ project.status }}</span>
                                                                    <span v-if="project.status == 'WORKING'" class="badge" :class="'badge-info'">{{ project.status }}</span>
                                                                    <span v-if="project.status == 'COMPLETED'" class="badge" :class="'badge-success'">{{ project.status }}</span>
                                                                </div>

                                                                <router-link :to="{ path: '/project/details/' + project.id }" class="link-style">
                                                                    <h6>{{ project.title }}</h6>
                                                                </router-link>
                                                                <div style="display: flex; align-items: start; justify-content: flex-start;">
                                                                    <h7 class="badge-pill badge-danger ">{{ project.deadline }}</h7>
                                                                </div>

                                                                <!-- <div v-if="project.details.length < 200" v-html="project.details"></div> -->
                                                                <div class="mt-3" v-html="project.details" style="height: 200px; overflow: hidden; text-overflow: ellipsis;"></div>

                                                                <div class="mt-3 row details">
                                                                    <div class="col-6">
                                                                        <span>Tasks</span>
                                                                    </div>
                                                                    <div v-if="project.status == 'APPROVED'" class="col-6" style="color: #1c1c1c;">{{ project.task }}</div>
                                                                    <div v-if="project.status == 'PENDING'" class="col-6" :class="'text-danger'">{{ project.task }}</div>
                                                                    <div v-if="project.status == 'WORKING'" class="col-6" style="color: #a426f2;">{{ project.task }}</div>
                                                                    <div v-if="project.status == 'COMPLETED'" class="col-6" :class="'text-success'">{{ project.task }}</div>
                                                                    <div class="col-6">
                                                                        <span>Resolved</span>
                                                                    </div>
                                                                    <div v-if="project.status == 'APPROVED'" class="col-6" style="color: #1c1c1c;">{{ project.resolved }}</div>
                                                                    <div v-if="project.status == 'PENDING'" class="col-6" :class="'text-danger'">{{ project.resolved }}</div>
                                                                    <div v-if="project.status == 'WORKING'" class="col-6" style="color: #a426f2;">{{ project.resolved }}</div>
                                                                    <div v-if="project.status == 'COMPLETED'" class="col-6" :class="'text-success'">{{ project.resolved }}</div>
                                                                    <div class="col-6">
                                                                        <span>Remaining</span>
                                                                    </div>
                                                                    <div v-if="project.status == 'APPROVED'" class="col-6" style="color: #1c1c1c;">{{ project.remaining }}</div>
                                                                    <div v-if="project.status == 'PENDING'" class="col-6" :class="'text-danger'">{{ project.remaining }}</div>
                                                                    <div v-if="project.status == 'WORKING'" class="col-6" style="color: #a426f2;">{{ project.remaining }}</div>
                                                                    <div v-if="project.status == 'COMPLETED'" class="col-6" :class="'text-success'">{{ project.remaining }}</div>
                                                                </div>

                                                                <div class="customers mt-3" v-if="project.teammates.length > 0">
                                                                    <ul v-for="(team, index) in project.teammates" :key="index">
                                                                        <li class="d-inline-block" v-if="team.user_id.additional_info">
                                                                            <img class="img-30 rounded-circle" :src="host + team.user_id.additional_info.photo" alt="" v-b-tooltip.hover :title="team.user_name">
                                                                        </li>
                                                                    </ul>
                                                                </div>

                                                                <div class="project-status mt-3">
                                                                    <div class="media mb-0">
                                                                        <p>{{projectDoneTaskPercentage(project)}}%</p>
                                                                        <div class="media-body text-right">
                                                                            <span>Done</span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="progress" style="height: 5px">
                                                                        <div v-if="project.status == 'APPROVED'" class="progress-bar-animated progress-bar-striped" :class="'bg-primary'" role="progressbar" :style="{'width': projectDoneTaskPercentage(project) + '%'}" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                                                                        <div v-if="project.status == 'PENDING'" class="progress-bar-animated progress-bar-striped" :class="'bg-danger'" role="progressbar" :style="{'width': projectDoneTaskPercentage(project) + '%'}" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                                                                        <div v-if="project.status == 'WORKING'" class="progress-bar-animated progress-bar-striped" :class="'bg-info'" role="progressbar" :style="{'width': projectDoneTaskPercentage(project) + '%'}" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                                                                        <div v-if="project.status == 'COMPLETED'" class="progress-bar-animated progress-bar-striped" :class="'bg-success'" role="progressbar" :style="{'width': projectDoneTaskPercentage(project) + '%'}" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div v-if="showList" class="col-xl-12">
                                                            <b-row>
                                                                <b-col xl="12">
                                                                    <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                                                                        <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                                                                    </b-form-group>
                                                                </b-col>
                                                            </b-row>

                                                            <div class="table-responsive datatable-vue">
                                                                <b-table show-empty stacked="md" :items="projects" :fields="tablefields" :filter="filter" :current-page="currentPage" :per-page="perPage" @filtered="onFiltered">
                                                                    <template #cell(index)="data">
                                                                        {{ (currentPage - 1) * perPage + data.index + 1 }}
                                                                    </template>

                                                                    <template #cell(title)="data">
                                                                        <span style="display: flex; flex-direction: column; gap: 8px;">
                                                                            <router-link :to="{ path: '/project/details/' + data.item.id }" class="link-style" style="font-size:14px">{{data.item.title}}</router-link>
                                                                            <small><span style="font-size: medium;">
                                                                                    <span v-if="data.item.status == 'APPROVED'" class="badge" :class="'badge-primary'">{{ data.item.status }}</span>
                                                                                    <span v-if="data.item.status == 'PENDING'" class="badge" :class="'badge-danger'">{{ data.item.status }}</span>
                                                                                    <span v-if="data.item.status == 'WORKING'" class="badge" :class="'badge-info'">{{ data.item.status }}</span>
                                                                                    <span v-if="data.item.status == 'COMPLETED'" class="badge" :class="'badge-success'">{{ data.item.status }}</span>
                                                                                </span>
                                                                            </small>

                                                                        </span>
                                                                    </template>

                                                                    <template #cell(date)="data">
                                                                        <div class="d-flex flex-column">
                                                                            <span>Start Date:</span>
                                                                            <div class="badge badge-primary" style="max-width:fit-content">{{ data.item.start_date }}</div>
                                                                            <span>Deadline:</span>
                                                                            <div class="badge badge-danger" style="max-width:fit-content">{{ data.item.deadline }}</div>

                                                                        </div>

                                                                    </template>

                                                                    <template #cell(teammember)="data">
                                                                        <div class="customers mt-1 " v-if="data.item.teammates.length > 0" style="display: grid; grid-template-columns: repeat(5, minmax(0, 1fr)); gap:5px">
                                                                            <ul v-for="(team, index) in data.item.teammates.slice(0,15)" :key="index">
                                                                                <li class="d-inline-block" v-if="team.user_id.additional_info">
                                                                                    <img class="img-30 rounded-circle" :src="host + team.user_id.additional_info.photo" alt="" v-b-tooltip.hover :title="team.user_name">
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </template>

                                                                    <template #cell(task)="data">
                                                                        <div class="d-flex flex-column">
                                                                            <div style="color: #1c1c1c;"> Task: {{ data.item.task }}</div>
                                                                            <div style="color: #1c1c1c;">Resolved: {{ data.item.resolved }}</div>
                                                                            <div style="color: #1c1c1c;">Remaining: {{ data.item.remaining }}</div>
                                                                        </div>

                                                                    </template>
                                                                    <template #cell(client_name)="data">
                                                                        <div v-if="data.item.client_name.length < 1">
                                                                            <span>N/A</span>
                                                                        </div>
                                                                        <div v-for="client in data.item.client_name.slice(0,3)" :key="client.client_id" style="width:150px">
                                                                            <span>{{client.client_name}}</span>
                                                                        </div>
                                                                        <div v-if="data.item.client_name.length > 3">
                                                                            <span class="badge badge-primary" style="max-width:fit-content" @click="clientInformation(data.item)" v-b-modal.modal-prevent-closing-client>See More</span>
                                                                        </div>
                                                                    </template>

                                                                </b-table>
                                                            </div>
                                                            <b-col md="6" class="my-1 p-0">
                                                                <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" class="my-0"></b-pagination>
                                                            </b-col>
                                                        </div>

                                                    </div>
                                                    <div v-else class="text-center">
                                                        <h6>No Project Found</h6>
                                                    </div>
                                                </div>

                                            </div>
                                            <div class="card" v-else>
                                                <div class="loader-box">
                                                    <div class="loader-10"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </b-card-text>
                                </b-tab>

                                <b-tab title="Completed" :title-link-class="linkClass(4)" @click="categoryType('COMPLETED')"> 
                                    <template v-slot:title>
                                        <div>
                                            <feather type="compass"></feather> Completed
                                        </div>
                                    </template>
                                    <b-card-text>
                                        <div class="col-sm-12 p-0">
                                            <div class="card" v-if="!loading && !error">
                                                <div class="card-body">
                                                    <div class="row mb-4" v-if="projects.length>0">
                                                        <div style="display: flex; flex-direction: row; justify-content: space-between" class="col-xl-12">
                                                            <b-input-group class="col-xl-4">
                                                                <b-form-input v-model="query" placeholder="Type to Search"></b-form-input>
                                                                <b-input-group-append>
                                                                    <b-button @click="findProjects()">Search</b-button>
                                                                </b-input-group-append>
                                                            </b-input-group>
                                                            <div style="display: flex; gap:15px">
                                                                <feather type="list" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover title="List View" @click="toggleGrid"> </feather>
                                                                <feather type="grid" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover title="Grid View" @click="toggleList"> </feather>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row" v-if="projects.length>0">
                                                        <div v-if="showGrid" class="col-xl-4 col-lg-6" v-for="(project, index) in projects" :key="index" :class="index < 3 ? 'mb-4' : 'mb-4'">
                                                            <div class="project-box">
                                                                <div class="my-4">
                                                                    <span v-if="project.status == 'APPROVED'" class="badge" :class="'badge-primary'">{{ project.status }}</span>
                                                                    <span v-if="project.status == 'PENDING'" class="badge" :class="'badge-danger'">{{ project.status }}</span>
                                                                    <span v-if="project.status == 'WORKING'" class="badge" :class="'badge-info'">{{ project.status }}</span>
                                                                    <span v-if="project.status == 'COMPLETED'" class="badge" :class="'badge-success'">{{ project.status }}</span>
                                                                </div>

                                                                <router-link :to="{ path: '/project/details/' + project.id }" class="link-style">
                                                                    <h6>{{ project.title }}</h6>
                                                                </router-link>
                                                                <div style="display: flex; align-items: start; justify-content: flex-start;">
                                                                    <h7 class="badge-pill badge-danger ">{{ project.deadline }}</h7>
                                                                </div>

                                                                <!-- <div v-if="project.details.length < 200" v-html="project.details"></div> -->
                                                                <div class="mt-3" v-html="project.details" style="height: 200px; overflow: hidden; text-overflow: ellipsis;"></div>

                                                                <div class="mt-3 row details">
                                                                    <div class="col-6">
                                                                        <span>Tasks</span>
                                                                    </div>
                                                                    <div v-if="project.status == 'APPROVED'" class="col-6" style="color: #1c1c1c;">{{ project.task }}</div>
                                                                    <div v-if="project.status == 'PENDING'" class="col-6" :class="'text-danger'">{{ project.task }}</div>
                                                                    <div v-if="project.status == 'WORKING'" class="col-6" style="color: #a426f2;">{{ project.task }}</div>
                                                                    <div v-if="project.status == 'COMPLETED'" class="col-6" :class="'text-success'">{{ project.task }}</div>
                                                                    <div class="col-6">
                                                                        <span>Resolved</span>
                                                                    </div>
                                                                    <div v-if="project.status == 'APPROVED'" class="col-6" style="color: #1c1c1c;">{{ project.resolved }}</div>
                                                                    <div v-if="project.status == 'PENDING'" class="col-6" :class="'text-danger'">{{ project.resolved }}</div>
                                                                    <div v-if="project.status == 'WORKING'" class="col-6" style="color: #a426f2;">{{ project.resolved }}</div>
                                                                    <div v-if="project.status == 'COMPLETED'" class="col-6" :class="'text-success'">{{ project.resolved }}</div>
                                                                    <div class="col-6">
                                                                        <span>Remaining</span>
                                                                    </div>
                                                                    <div v-if="project.status == 'APPROVED'" class="col-6" style="color: #1c1c1c;">{{ project.remaining }}</div>
                                                                    <div v-if="project.status == 'PENDING'" class="col-6" :class="'text-danger'">{{ project.remaining }}</div>
                                                                    <div v-if="project.status == 'WORKING'" class="col-6" style="color: #a426f2;">{{ project.remaining }}</div>
                                                                    <div v-if="project.status == 'COMPLETED'" class="col-6" :class="'text-success'">{{ project.remaining }}</div>
                                                                </div>

                                                                <div class="customers mt-3" v-if="project.teammates.length > 0">
                                                                    <ul v-for="(team, index) in project.teammates" :key="index">
                                                                        <li class="d-inline-block" v-if="team.user_id.additional_info">
                                                                            <img class="img-30 rounded-circle" :src="host + team.user_id.additional_info.photo" alt="" v-b-tooltip.hover :title="team.user_name">
                                                                        </li>
                                                                    </ul>
                                                                </div>

                                                                <div class="project-status mt-3">
                                                                    <div class="media mb-0">
                                                                        <p>{{projectDoneTaskPercentage(project)}}%</p>
                                                                        <div class="media-body text-right">
                                                                            <span>Done</span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="progress" style="height: 5px">
                                                                        <div v-if="project.status == 'APPROVED'" class="progress-bar-animated progress-bar-striped" :class="'bg-primary'" role="progressbar" :style="{'width': projectDoneTaskPercentage(project) + '%'}" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                                                                        <div v-if="project.status == 'PENDING'" class="progress-bar-animated progress-bar-striped" :class="'bg-danger'" role="progressbar" :style="{'width': projectDoneTaskPercentage(project) + '%'}" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                                                                        <div v-if="project.status == 'WORKING'" class="progress-bar-animated progress-bar-striped" :class="'bg-info'" role="progressbar" :style="{'width': projectDoneTaskPercentage(project) + '%'}" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                                                                        <div v-if="project.status == 'COMPLETED'" class="progress-bar-animated progress-bar-striped" :class="'bg-success'" role="progressbar" :style="{'width': projectDoneTaskPercentage(project) + '%'}" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div v-if="showList" class="col-xl-12">
                                                            <b-row>
                                                                <b-col xl="12">
                                                                    <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                                                                        <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                                                                    </b-form-group>
                                                                </b-col>
                                                            </b-row>

                                                            <div class="table-responsive datatable-vue">
                                                                <b-table show-empty stacked="md" :items="projects" :fields="tablefields" :filter="filter" :current-page="currentPage" :per-page="perPage" @filtered="onFiltered">
                                                                    <template #cell(index)="data">
                                                                        {{ (currentPage - 1) * perPage + data.index + 1 }}
                                                                    </template>

                                                                    <template #cell(title)="data">
                                                                        <span style="display: flex; flex-direction: column; gap: 8px;">
                                                                            <router-link :to="{ path: '/project/details/' + data.item.id }" class="link-style" style="font-size:14px">{{data.item.title}}</router-link>
                                                                            <small><span style="font-size: medium;">
                                                                                    <span v-if="data.item.status == 'APPROVED'" class="badge" :class="'badge-primary'">{{ data.item.status }}</span>
                                                                                    <span v-if="data.item.status == 'PENDING'" class="badge" :class="'badge-danger'">{{ data.item.status }}</span>
                                                                                    <span v-if="data.item.status == 'WORKING'" class="badge" :class="'badge-info'">{{ data.item.status }}</span>
                                                                                    <span v-if="data.item.status == 'COMPLETED'" class="badge" :class="'badge-success'">{{ data.item.status }}</span>
                                                                                </span>
                                                                            </small>

                                                                        </span>
                                                                    </template>

                                                                    <template #cell(date)="data">
                                                                        <div class="d-flex flex-column">
                                                                            <span>Start Date:</span>
                                                                            <div class="badge badge-primary" style="max-width:fit-content">{{ data.item.start_date }}</div>
                                                                            <span>Deadline:</span>
                                                                            <div class="badge badge-danger" style="max-width:fit-content">{{ data.item.deadline }}</div>

                                                                        </div>

                                                                    </template>

                                                                    <template #cell(teammember)="data">
                                                                        <div class="customers mt-1 " v-if="data.item.teammates.length > 0" style="display: grid; grid-template-columns: repeat(5, minmax(0, 1fr)); gap:5px">
                                                                            <ul v-for="(team, index) in data.item.teammates.slice(0,15)" :key="index">
                                                                                <li class="d-inline-block" v-if="team.user_id.additional_info">
                                                                                    <img class="img-30 rounded-circle" :src="host + team.user_id.additional_info.photo" alt="" v-b-tooltip.hover :title="team.user_name">
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </template>

                                                                    <template #cell(task)="data">
                                                                        <div class="d-flex flex-column">
                                                                            <div style="color: #1c1c1c;"> Task: {{ data.item.task }}</div>
                                                                            <div style="color: #1c1c1c;">Resolved: {{ data.item.resolved }}</div>
                                                                            <div style="color: #1c1c1c;">Remaining: {{ data.item.remaining }}</div>
                                                                        </div>

                                                                    </template>
                                                                    <template #cell(client_name)="data">
                                                                        <div v-if="data.item.client_name.length < 1">
                                                                            <span>N/A</span>
                                                                        </div>
                                                                        <div v-for="client in data.item.client_name.slice(0,3)" :key="client.client_id" style="width:150px">
                                                                            <span>{{client.client_name}}</span>
                                                                        </div>
                                                                        <div v-if="data.item.client_name.length > 3">
                                                                            <span class="badge badge-primary" style="max-width:fit-content" @click="clientInformation(data.item)" v-b-modal.modal-prevent-closing-client>See More</span>
                                                                        </div>
                                                                    </template>

                                                                </b-table>
                                                            </div>

                                                            <b-col md="6" class="my-1 p-0">
                                                                <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" class="my-0"></b-pagination>
                                                            </b-col>
                                                        </div>

                                                    </div>
                                                    <div v-else class="text-center">
                                                        <h6>No Project Found</h6>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card" v-else>
                                                <div class="loader-box">
                                                    <div class="loader-10"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </b-card-text>
                                </b-tab>
                            </b-tabs>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Container-fluid Ends-->
    <!-- Client List -->
    <b-modal id="modal-prevent-closing-client" ref="modal" title="Client List" hide-footer>
        <div class="row">
            <div class="text-center col-sm-6 w-full" v-for="client in clientList" :key="client.client_id">
                <div class="w-100 mb-2 badge-style">
                    <h7>{{ client.client_name }}</h7>

                </div>
            </div>
        </div>
    </b-modal>
</div>
</template>

<script>
import {
    mapState
} from 'vuex';
import {
    myHost
} from "../../../_helpers/host";
export default {
    data() {
        return {
            host: myHost.host,
            tabIndex: 0,
            activeItem: 'ALL',
            query: null,
            projects: [],
            clientList: [],
            showGrid: true,
            showList: false,
            tablefields: [{
                    key: 'index',
                    label: '#'
                },

                {
                    key: 'title',
                    label: 'Project',
                    sortable: true
                },
                {
                    key: 'date',
                    label: 'Start & End Date',
                    sortable: true
                },
                {
                    key: 'teammember',
                    label: 'Team Members',
                    sortable: true
                },
                {
                    key: 'client_name',
                    label: 'Client',
                    sortable: true
                },

                {
                    key: 'task',
                    label: 'Task',
                    sortable: true
                },

            ],
            filter: null,
            totalRows: 1,
            currentPage: 1,
            perPage: 25,
            pageOptions: [25, 50, 100],
        }
    },
    watch: {
        allprojects(newValue, oldValue) {
            this.projects = this.allprojects;
            this.totalRows = this.projects.length;
        },

        searchedProjects(newValue, oldValue) {
            this.projects = this.searchedProjects
        },

    },

    computed: {
        ...mapState({
            allprojects: state => state.project.my_project,
            loading: state => state.project.submitLoading,
            error: state => state.project.submitError,
            searchedProjects: state => state.project.my_project_by_search,
        }),

        projectDoneTaskPercentage() {
            return (project) => {
                if (project.task == 0) {
                    const percentage = 0;
                    return percentage
                } else {
                    const percentage = ((project.task - project.remaining) / project.task) * 100;

                    return Math.floor(percentage);
                }

            };
        }

    },

    methods: {
        clientInformation(type) {
            this.clientList = type.client_name
        },

        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        toggleList() {
            this.showList = false;
            this.showGrid = true;
        },
        toggleGrid() {
            this.showGrid = false;
            this.showList = true;
        },

        findProjects() {
            this.$store.dispatch('project/fetchMyProjectbySearch', {
                project_status: this.activeItem,
                search_term: this.query,
            });
        },

        categoryType(item) {
            if (this.query !== null) {
                this.query = null
            }
            this.activeItem = item
            if (item === 'ALL') {
                this.$store.dispatch("project/fetchMyProject")
            } else {
                this.$store.dispatch("project/fetchMyProject", item)
            }

        },

        linkClass(idx) {
            if (this.tabIndex === idx) {
                return ['text-success']
            } else {

            }
        },
    },

    mounted() {
        this.$store.dispatch("project/fetchMyProject")
    }
};
</script>

<style scoped>
.badge-style {
    display: flex;
    justify-content: space-between;
    align-items: center;
    justify-items: center;
    background-color: #2c323f;
    padding: 0.375rem 1.75rem;
    color: white;
}

.img-30 {
    width: 30px !important;
    height: 30px !important;
}
</style>
