<template>
<div>
    <Breadcrumbs main="Procurement" title="RFP Edit" />

    <!-- Container-fluid starts-->
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12">
                <px-card>
                    <div slot="with-padding">
                        <div class="form theme-form">

                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Title</label>
                                        <input class="form-control" type="text" placeholder="Enter rfp title" v-model="rfp.title">
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Category</label>
                                        <select class="form-control digits" v-model="rfp.rfp_category">
                                            <option v-for="(item, index) in items" :key="index" :value="item.id">{{ item.title }}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Status</label>
                                        <select class="form-control digits" v-model="rfp.status">
                                            <option v-for="(status, index) in statuses" :key="index">{{ status }}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Deadline</label>
                                        <datepicker placeholder="Select a date" input-class="datepicker-here form-control digits" v-model="rfp.deadline"></datepicker>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Project</label>
                                        <select class="form-control digits" v-model="rfp.project">
                                            <option v-for="(project, index) in projects" :key="index" :value="project.id">{{ project.title }}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group">
                                       
                                        <div style="display: flex;">
                                            <label>Vendor</label>
                                            <input class="ml-2 mb-2" type="checkbox" id="myCheck" v-model="selectAll" @click="selectAll = !selectAll"> <span class="ml-2" v-if="selectAll">All vendors selected.</span>
                                        </div>
                                        <multiselect v-model="assignedVendor" tag-placeholder="Add member" placeholder="Search or add a member" label="user" :max="vendor_list.length" track-by="id" :options="formattedVendorOptions" :multiple="true" :taggable="true" :disabled="selectAll"></multiselect>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col">
                                    <div class="form-group">
                                        <label>Upload Attachment</label>
                                        <b-form-file v-model="attachment_prev" placeholder="Choose a file or drop it here..." drop-placeholder="Drop file here..." @change="uploadFile"></b-form-file>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col">
                                    <div class="form-group">
                                        <label>Short Description</label>
                                        <!-- <textarea 
                                                class="form-control" 
                                                id="textarea-auto-height"
                                                placeholder="Enter short description..." 
                                                rows="5"  
                                                max-rows="8" 
                                                v-model="rfp.details"
                                            ></textarea> -->
                                        <ckeditor :editor="editor" placeholder="Enter short description..." v-model="rfp.details"></ckeditor>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col">
                                    <div class="form-group mb-0">
                                        <button @click="onSubmit" class="btn btn-success mr-3">Submit</button>
                                        <router-link to="/procurement/rfp/list">
                                            <button class="btn btn-danger" href="#">Go Back</button>
                                        </router-link>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </px-card>
            </div>
        </div>
    </div>
    <!-- Container-fluid Ends-->
</div>
</template>

<script>
import {
    mapState
} from 'vuex';
import Datepicker from 'vuejs-datepicker';
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import moment from "moment";
import {
    myHost
} from "../../../_helpers/host";
import 'sweetalert2/src/sweetalert2.scss';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Multiselect from 'vue-multiselect';

export default {
    components: {
        vueDropzone: vue2Dropzone,
        Datepicker,
        Multiselect
    },

    data() {
        return {
            editor: ClassicEditor,
            rfp: {
                title: '',
                status: '',
                project:null,
                deadline: null,
                rfp_category: null,
                details: '',
                rfp_attachment: null
            },
            vendor_id: null,
            assignedVendor: [],
            selectAll: false,


            statuses: ['APPROVED', 'WORKING', 'COMPLETED'],

            attachment_prev: null,
            host: myHost.host,
        };
    },

    computed: {
        ...mapState({
            items: state => state.rfp.rfp_category,
            detail: state => state.rfp.rfp_detail,
            projects: state => state.project.project,
            vendor_list: state => state.vendor.vendor_without_pg,
        }),
        formattedVendorOptions() {
            return this.vendor_list.map(vendor => ({
                id: vendor.user.id,
                user: vendor.user.user_name
            }));
        },
    },

    mounted() {
        this.$store.dispatch("rfp/fetchRFPCategory")
        this.$store.dispatch("rfp/fetchRFPDetails", this.$route.params.id)
        this.$store.dispatch("project/fetchProject");
        this.$store.dispatch("vendor/fetchVendor_without_pg")
        
    },

    watch: {
        detail(oldValue, newValue) {
            this.rfp.title = this.detail.title
            this.rfp.status = this.detail.status
            this.rfp.deadline = this.detail.deadline
            this.rfp.rfp_category = this.detail.rfp_category.id
            this.rfp.details = this.detail.details
            this.rfp.project = this.detail.project
            this.attachment_prev = this.host + this.detail.rfp_attach.rfp_attachment
        }
    },

    methods: {
        uploadFile(e) {
            this.rfp.rfp_attachment = e.target.files[0]
        },

        onSubmit() {
            if (this.rfp.deadline !== null) {
                this.rfp.deadline = moment(String(this.rfp.deadline)).format("YYYY-MM-DD");
            }
            if (this.selectAll == true) {
                this.vendor_id = null
            } else {
                if (!Array.isArray(this.vendor_id)) {
                    this.vendor_id = [];
                }
                this.assignedVendor.map(item => this.vendor_id.push(item.id));
            }

            this.$store.dispatch("rfp/updateRFP", {
                id: this.$route.params.id,
                rfp: this.rfp,
                vendors:this.vendor_id
            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    setTimeout(() => this.$router.push('/procurement/rfp/list'), 2000)

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
        }
    }
};
</script>
