<template>
    <div>
        <Breadcrumbs main="HR" submain="Payroll" title="Payroll History"/>
        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">

                        <div class="card-header">
                            <div style="display: flex; flex-direction: row; justify-content: space-between; align-items: center;">
                                <h5>Current Payroll</h5>
                                <button class="btn btn-primary" @click="editPayroll(payroll_details)" v-b-modal.modal-prevent-closing-edit-xl style="display: flex; gap: 12px; align-items: center;">
                                    <i class="fa fa-regular fa-pencil"></i> 
                                    <h7>Edit</h7>
                                </button>
                            </div>
                        </div>

                        <div class="card-body">
                            <div class="form-inside-container">
                                <div class="header-width">
                                    <b-form-group
                                        label="Employee"
                                        label-for="name-input"
                                    > 
                                        <b-form-input v-model="payroll_details.employee.user_name" disabled></b-form-input>
                                    </b-form-group>
                                </div>

                                <div class="header-width">
                                    <b-form-group
                                        label="Department"
                                        label-for="name-input"
                                    >
                                        <b-form-input v-model="payroll_details.designation.department.name" disabled></b-form-input>
                                    </b-form-group>
                                </div>

                                <div class="header-width">
                                    <b-form-group
                                        label="Designation"
                                        label-for="name-input"
                                    >
                                        <b-form-input v-model="payroll_details.designation.title" disabled></b-form-input>
                                    </b-form-group>
                                </div>
                            </div>

                            <div class="form-inside-container">
                                <div class="header-width mr-2">
                                    <h6>Inclusions (+)</h6>
                                    <div> 
                                        <div style="display: flex; flex-direction: column; gap: 10px;">
                                            <div v-for="(inclusion, index) in payroll_details.inclusion" :key="index">
                                                <div style="display: flex; flex-direction: row; gap: 10px;">
                                                    <b-form-select v-model="inclusion.item">
                                                        <b-form-select-option v-for="option in inclusion_items" :value="option.id">{{ option.name }}</b-form-select-option>
                                                    </b-form-select> 
                                                    <b-form-input v-model="inclusion.amount" placeholder="Enter amount"></b-form-input>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div class="header-width ml-2" v-if="payroll_details.deduction !== null">
                                    <h6> Deductions (-)</h6>
                                    <div>
                                        <div style="display: flex; flex-direction: column; gap: 10px;">
                                            <div v-for="(deduction, index) in payroll_details.deduction" :key="index">
                                                <div style="display: flex; flex-direction: row; gap: 10px;">
                                                    <b-form-select v-model="deduction.item">
                                                        <b-form-select-option v-for="option in deduction_items" :value="option.id">{{ option.name }}</b-form-select-option>
                                                    </b-form-select>
                                                    <b-form-input v-model="deduction.amount" placeholder="Enter amount"></b-form-input>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="mt-4" style="display: flex; align-items: center;">
                                <h6 style="margin-top: 7px;">Gross Salary</h6>
                                <b-form-input v-model="payroll_details.gross_salary" disabled style="width: 300px; margin-left: 16px;"></b-form-input>
                            </div>
                        </div>
                    </div>

                    <div class="card">

                        <div class="card-header">
                            <h5>Previous Payroll</h5>
                        </div>

                        <div class="card-body">
                            <b-row>
                                <b-col xl="6">
                
                                    <b-input-group class="datatable-btn">
                                        <b-form-input v-model="filter" placeholder="Search..."></b-form-input>
                                        <b-input-group-append>
                                            <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                        </b-input-group-append>
                                    </b-input-group>
                
                                </b-col>
                
                                <b-col xl="6">
                                    <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                                        <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                                    </b-form-group>
                                </b-col>
                            </b-row>
            
                            <div class="table-responsive datatable-vue">
                                <b-table
                                    show-empty
                                    stacked="md"
                                    :items="items"
                                    :fields="tablefields"
                                    :filter="filter"
                                    :current-page="currentPage"
                                    :per-page="perPage"
                                    @filtered="onFiltered"
                                >
                                    <template #cell(index)="data">
                                        {{ data.index + 1 }}
                                    </template>

                                    <template #cell(created_on)="data">
                                        <p>{{ getDate(data.item.created_on) }}</p>
                                    </template>

                                    <template #cell(department)="data"> 
                                        <p v-if="data.item.designation">{{ data.item.designation.department.name }}</p>
                                    </template>

                                    <template #cell(designation)="data"> 
                                        <p v-if="data.item.designation">{{ data.item.designation.title }}</p>
                                    </template>

                                    <template #cell(active)="data"> 
                                        <span><b-badge pill variant="danger" @click="markasCurrent(data.item.id)">Mark as Current</b-badge></span> 
                                    </template>

                                    <template #cell(action)="data">
                                        <span style="display: flex; gap: 12px;">
                                            <div @click="payrollDetail(data.item)">
                                                <feather type="eye" size="22px" style="color: #568ae3; cursor: pointer;" v-b-tooltip.hover name="Edit" v-b-modal.modal-prevent-closing-xl></feather>
                                            </div> 
                                            <feather type="delete" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover name="Delete" v-b-modal.modal-center @click="deleteItems(data.item.id)"></feather>
                                        </span>
                                    </template>
                                </b-table>
                            </div>
            
                            <b-col md="6" class="my-1 p-0">
                                <b-pagination
                                    v-model="currentPage"
                                    :total-rows="totalRows"
                                    :per-page="perPage"
                                    class="my-0"
                                ></b-pagination>
                            </b-col>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Container-fluid Ends-->

        <!-- Payroll Details Modal -->
        <b-modal
            id="modal-prevent-closing-xl"
            size="xl"
            ref="modal"
            title="Payroll Details"
            hide-footer
        >
            <div class="form-inside-container">
                <div class="header-width">
                    <b-form-group
                        label="Department"
                        label-for="name-input"
                    >
                        <b-form-input v-model="payroll.department" disabled></b-form-input>
                    </b-form-group>
                </div>

                <div class="header-width">
                    <b-form-group
                        label="Designation"
                        label-for="name-input"
                    >
                        <b-form-input v-model="payroll.designation" disabled></b-form-input>
                    </b-form-group>
                </div>
            </div>

            <div class="form-inside-container">
                <div class="header-width item-padding">
                    <h6>Inclusions (+)</h6>
                    <div>
                        <div style="display: flex; flex-direction: column; gap: 10px;" v-if="payroll.inclusions !== null">
                            <div v-for="(inclusion, index) in payroll.inclusions" :key="index">
                                <div style="display: flex; flex-direction: row; gap: 10px;">
                                    <b-form-select v-model="inclusion.item" disabled>
                                        <b-form-select-option v-for="option in inclusion_items" :value="option.id">{{ option.name }}</b-form-select-option>
                                    </b-form-select>
                                    <b-form-input v-model="inclusion.amount" placeholder="Enter amount" disabled></b-form-input>
                                </div>
                            </div>
                        </div>
                        <div style="display: flex; flex-direction: column; gap: 10px;" v-else>
                            <h7>No Inclusions to show!</h7>
                        </div>
                    </div>
                </div>


                <div class="header-width item-padding">
                    <h6>Deductions (-)</h6>
                    <div>
                        <div style="display: flex; flex-direction: column; gap: 10px;" v-if="payroll.deductions !== null">
                            <div v-for="(deduction, index) in payroll.deductions" :key="index">
                                <div style="display: flex; flex-direction: row; gap: 10px;">
                                    <b-form-select v-model="deduction.item" disabled>
                                        <b-form-select-option v-for="option in deduction_items" :value="option.id">{{ option.name }}</b-form-select-option>
                                    </b-form-select>
                                    <b-form-input v-model="deduction.amount" placeholder="Enter amount" disabled></b-form-input>
                                </div>
                            </div>
                        </div>
                        <div style="display: flex; flex-direction: column; gap: 10px;" v-else>
                            <h7>No Deductions to show!</h7>
                        </div>
                    </div>
                </div>
            </div>

            <div style="display: flex; align-items: center; margin-top: 20px;">
                <h6 style="margin-top: 7px;">Gross Salary</h6>
                <b-form-input v-model="payroll.gross_salary" disabled style="width: 300px; margin-left: 16px;"></b-form-input>
            </div>
        </b-modal>

        <!-- Payroll Edit Modal -->
        <b-modal
            id="modal-prevent-closing-edit-xl"
            size="xl"
            ref="modal"
            title="Edit Payroll"
            @show="resetEditModal"
            @hidden="resetEditModal"
            @ok="handleEditOk"
        >
            <form ref="form" @submit.stop.prevent="handleEditSubmit">
                <div class="form-inside-container">
                    <div class="header-width">
                        <b-form-group
                            label="Department"
                            label-for="name-input"
                        >
                            <b-form-select v-model="edit_payroll.department" disabled>
                                <b-form-select-option v-for="option in departments" :value="option.id">{{ option.name }}</b-form-select-option>
                            </b-form-select>
                        </b-form-group>
                    </div>

                    <div class="header-width">
                        <b-form-group
                            label="Designation"
                            label-for="name-input"
                        >
                            <b-form-select v-model="edit_payroll.designation" disabled>
                                <b-form-select-option v-for="option in designations" :value="option.id">{{ option.title }}</b-form-select-option>
                            </b-form-select>
                        </b-form-group>
                    </div>

                    <div class="header-width">
                        <b-form-group
                            label="Employee"
                            label-for="name-input"
                        >
                            <b-form-select v-model="edit_payroll.employee" disabled>
                                <b-form-select-option v-for="option in employees" :value="option.id">{{ option.user_name }}</b-form-select-option>
                            </b-form-select>
                        </b-form-group>
                    </div>
                </div>

                <div class="form-inside-container">
                    <div class="header-width item-padding">
                        <h6>Inclusions (+)</h6>
                        <div>
                            <div style="display: flex; flex-direction: column; gap: 10px;">
                                <div v-for="(inc, index) in edit_inclusions" :key="index">
                                    <div style="display: flex; align-items: center; width: 100%;">
                                        <div style="display: flex; flex-direction: row; gap: 10px; width: 90%;">
                                            <b-form-select v-model="inc.item">
                                                <b-form-select-option v-for="option in inclusion_items" :value="option.id">{{ option.name }}</b-form-select-option>
                                            </b-form-select>
                                            <b-form-input v-model="inc.amount" type="number" placeholder="Enter amount"></b-form-input>
                                        </div>

                                        <div style="width: 50px; margin-left: 10px; display: flex; gap: 4px; align-items: center;">
                                            <!-- Add Svg Icon -->
                                            <svg @click="addField(inc.id, edit_inclusions)" width="24" height="24" class="cursor-pointer">
                                                <path fill="none" d="M0 0h24v24H0z" />
                                                <path fill="green" d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"/>
                                            </svg>

                                            <!-- Remove Svg Icon -->
                                            <svg v-if="edit_inclusions.length > 1 && !inc.id" @click="removeField(index, edit_inclusions)" width="24" height="24"       class="cursor-pointer">
                                                <path fill="none" d="M0 0h24v24H0z" />
                                                <path fill="#B13032" d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="header-width item-padding">
                       <h6> Deductions (-)</h6>
                       <div>
                            <div style="display: flex; flex-direction: column; gap: 10px;">
                                <div v-for="(deduction, index) in edit_deductions" :key="index">
                                    <div style="display: flex; align-items: center; width: 100%;">
                                        <div style="display: flex; flex-direction: row; gap: 10px; width: 90%;">
                                            <b-form-select v-model="deduction.item">
                                                <b-form-select-option v-for="option in deduction_items" :value="option.id">{{ option.name }}</b-form-select-option>
                                            </b-form-select>
                                            <b-form-input v-model="deduction.amount" type="number" placeholder="Enter amount"></b-form-input>
                                        </div>

                                        <div style="width: 50px; margin-left: 10px; display: flex; gap: 4px; align-items: center;">
                                            <!-- Add Svg Icon -->
                                            <svg @click="addDedField(deduction.id, edit_deductions)" width="24" height="24" class="cursor-pointer">
                                                <path fill="none" d="M0 0h24v24H0z" />
                                                <path fill="green" d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"/>
                                            </svg>

                                            <!-- Remove Svg Icon -->
                                            <svg v-if="edit_deductions.length > 1 && !deduction.id" @click="removeDedField(index, edit_deductions)" width="24" height="24"       class="cursor-pointer">
                                                <path fill="none" d="M0 0h24v24H0z" />
                                                <path fill="#B13032" d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div style="display: flex; align-items: center; margin-top: 20px;">
                    <h6 style="margin-top: 7px;">Gross Salary</h6>
                    <b-form-input v-model="edit_gross_salary" disabled style="width: 300px; margin-left: 16px;"></b-form-input>
                </div>
            </form>
        </b-modal>

        <!-- Payroll delete Modal -->
        <b-modal id="modal-center" centered hide-header @ok="handleDeleteOk">
            <div class="mt-4 mb-4" style="font-size: large;">Are you sure you want to delete this payroll?</div>
        </b-modal>
    </div>
</template>
  
<script>
import { mapState } from 'vuex';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss'; 
import { computed } from 'vue';
import moment from "moment";

export default { 
    data() {
        return {
            tablefields: [
                { key: 'index', label: '#' },
                { key: 'created_on', label: 'Date', sortable: true },
                { key: 'department', label: 'Department', sortable: true },
                { key: 'designation', label: 'Designation', sortable: true },
                { key: 'gross_salary', label: 'Gross Salary', sortable: true },
                { key: 'active', label: 'Status', sortable: true },
                { key: 'action', label: 'Action', sortable: false }
            ],

            payroll: {
                date: '',
                designation: '',
                department: '',
                gross_salary: '',
                inclusions: [],
                deductions: []
            },

            payroll_details: {},

            edit_payroll: {
                id: null,
                employee: null,
                designation: null,
                department: null
            },

            edit_inclusions: [
                {
                    item: '',
                    amount: 0
                }
            ],

            edit_deductions: [
                {
                    item: '',
                    amount: 0
                }
            ],

            edit_gross_salary: computed(() => {
                let total = 0;
                let inclusion_total = 0;
                let deduction_total = 0;

                if (this.edit_inclusions.length >= 1) {
                    this.edit_inclusions.map((item) => {
                        inclusion_total = parseInt(inclusion_total) + parseInt(item.amount);
                    });
                } else {
                    inclusion_total = 0
                }

                if (this.edit_deductions.length >= 1) {
                    this.edit_deductions.map((item) => {
                        deduction_total = parseInt(deduction_total) + parseInt(item.amount);
                    });
                } else {
                    deduction_total = 0
                }

                total = parseInt(inclusion_total) - parseInt(deduction_total)

                return total;
            }),

            delete_value: null,

            filter: null,
    
            totalRows: 1,
            currentPage: 1,
            perPage: 25,
            pageOptions: [25, 50, 100]
        };
    },

    computed: {
        ...mapState({
            details: state => state.payroll.payroll_details,
            items: state => state.payroll.payroll_history,
            departments: state => state.department.departments,
            designations: state => state.designation.designation,
            employees: state => state.employee.employee_list,
            inclusion_items: state => state.payroll.inclusion_items,
            deduction_items: state => state.payroll.deduction_items
        }),

        sortOptions() {
            // Create an options list from our fields
            return this.tablefields
                .filter(f => f.sortable)
                .map(f => {
                return {text: f.label, value: f.key};
            });
        }
    },

    mounted() { 
        this.$store.dispatch("payroll/fetchEmployeePayrollDetails", this.$route.params.id)
        this.$store.dispatch("payroll/fetchEmployeePayrollHistory", this.$route.params.id).then(() => {
            this.totalRows = this.items.length;
        });
        this.$store.dispatch("department/fetchDepartments")
        this.$store.dispatch("designation/fetchDesignations")
        this.$store.dispatch("employee/fetchEmployeeList");
        this.$store.dispatch("payroll/fetchInclusivePayrollItems")
        this.$store.dispatch("payroll/fetchDeductivePayrollItems")
    },

    watch: {
        details(oldValue, newValue) {
            this.payroll_details = this.details
        }
    },

    methods: {
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },

        getDate(date) {
            return moment(String(moment(date))).format("YYYY-MM-DD")
        },

        markasCurrent(id) {
            this.$store.dispatch("payroll/toggleEmployeePayroll", {id: id, e_id: this.$route.params.id}).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });
                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
        },

        addField(value, fieldType) {
            fieldType.push({ value: "", item: '', amount: 0 });
        },

        removeField(index, fieldType) {
            fieldType.splice(index, 1);
        },

        addDedField(value, fieldType) {
            fieldType.push({ value: "", item: '', amount: 0 });
        },

        removeDedField(index, fieldType) {
            fieldType.splice(index, 1);
        }, 

        payrollDetail(payroll) {
            this.payroll.date = payroll.created_on
            this.payroll.department = payroll.designation.department.name
            this.payroll.designation = payroll.designation.title
            this.payroll.inclusions = payroll.inclusion
            this.payroll.deductions = payroll.deduction
            this.payroll.gross_salary = payroll.gross_salary
        },

        editPayroll(payroll) {
            this.edit_payroll.id = payroll.id
            this.edit_payroll.employee = payroll.employee.id
            this.edit_payroll.department = payroll.designation.department.id
            this.edit_payroll.designation = payroll.designation.id
            if(payroll.inclusion !== null) {
                this.edit_inclusions = payroll.inclusion
            } else {
                this.edit_inclusions = [
                    {
                        item: '',
                        amount: 0
                    }
                ]
            }

            if(payroll.deduction !== null) {
                this.edit_deductions = payroll.deduction
            } else {
                this.edit_deductions = [
                    {
                        item: '',
                        amount: 0
                    }
                ]
            }
        },

        resetEditModal() {
            // this.edit_payroll.id = null
            // this.edit_payroll.employee = null
            // this.edit_payroll.department = null
            // this.edit_payroll.designation = null
            // this.edit_gross_salary = 0
            // this.edit_inclusions = []
            // this.edit_deductions = []
        },

        handleEditOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleEditSubmit()
        },
        
        handleEditSubmit() {
            this.$store.dispatch("payroll/editEmployeePayroll", {id: this.edit_payroll.id, gross_salary: this.edit_gross_salary, inclusions: this.edit_inclusions, deductions: this.edit_deductions}).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.edit_payroll.department = null
                    this.edit_payroll.designation = null
                    this.edit_payroll.employee = null
                    this.edit_gross_salary = 0
                    this.edit_inclusions = []
                    this.edit_deductions = []

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })

            // Hide the modal manually
            this.$nextTick(() => {
                this.$bvModal.hide('modal-prevent-closing-edit-xl')
            })
        },

        deleteItems(id) {
            this.delete_value = id
        },

        handleDeleteOk() {
            this.$store.dispatch("payroll/deletePayrollHistory", {id: this.delete_value, e_id: this.$route.params.id}).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.delete_value = null

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
        }
    }
};
</script>
  
<style scoped>
.form-inside-container {
    display: flex; 
    gap: 20px; 
    width: 100%;
}

.header-width {
    width: 50%;
}

.item-padding {
    padding-left: 12px;
    padding-right: 12px;
}

.button-style {
    padding: 2px; 
    width: 32px; 
    font-size: larger; 
    text-align: center; 
    color: white; 
    background-color: #222131; 
    border-radius: 4px;
    cursor: pointer;
}

@media screen and (max-width: 991px) {
    .form-inside-container {
        display: flex; 
        flex-direction: column;
        gap: 0px;
    }

    .header-width {
        width: 100%;
    }

    .item-padding {
        padding-left: 0px;
        padding-right: 0px;
    }
}
</style>