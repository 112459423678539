<template>
<div>
    <Breadcrumbs main="Finance & Accounts" title="Account Details" />
    <!-- Container-fluid starts-->
    <div>
        <div class="invoice-container">
            <div style="display: flex; gap: 10px;">
                <b-button variant="primary" @click="showPDF">Preview</b-button>
                <b-button variant="primary" @click="generatePDF">Download</b-button>
            </div>
            <!-- <div class="d-flex flex-row mt-4">
                <b-badge pill variant="primary" @click="toggleLetterHead" class="signature-btn" style="cursor: pointer;max-width: fit-content">
                    {{ showLetterHead ? 'Hide Letterhead' : 'Show Letterhead' }}
                </b-badge>
                <b-badge pill variant="primary" @click="toggleLogo" class="signature-btn" style="cursor: pointer;max-width: fit-content">
                    {{ showLogo ? 'Hide Logo' : 'Show Logo' }}
                </b-badge>
            </div> -->
            <div class="d-flex flex-row mt-4" style="gap: 10px;">

                <div class="d-flex flex-row">
                    <h6 class=" align-content-center mr-2">Letter Head</h6>
                    <div class="">
                        <div class="media-body text-right icon-state switch-outline">
                            <label class="switch">
                                <input type="checkbox" :checked="showLetterHead" @click="toggleLetterHead"><span class="switch-state bg-primary"></span>
                            </label>
                        </div>
                    </div>
                </div>

                <div class="d-flex flex-row">
                    <h6 class=" align-content-center mr-2">Logo</h6>
                    <div class="">
                        <div class="media-body text-right icon-state switch-outline">
                            <label class="switch">
                                <input type="checkbox" :checked="showLogo" @click="toggleLogo"><span class="switch-state bg-primary"></span>
                            </label>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div class="row">
            <div class="col-xl-8">

                <div v-if="!loading && !error">
                    <div :id="showPage ? '' : 'print'" ref="printDiv">
                        <div class="content-container">
                            <div id="details">
                                <div class="d-flex justify-content-center">
                                    <h5 class="mb-4">Banking Information</h5>
                                </div>
                                <div class="d-flex justify-content-center align-items-center">
                                    <div style="border: 1px solid gray; ">
                                        <div style="border: 1px solid gray; display: flex;">
                                            <span style="width: 240px; padding: 16px 12px;">Beneficiery Name</span>
                                            <span style="border-left: 1px solid gray; display: flex; align-items: center; padding-left: 12px; padding-right: 12px;">
                                                <h7 style="width: 240px;">{{ bank.beneficiery_name }}</h7>
                                            </span>
                                        </div>
                                        <div style="border: 1px solid gray; display: flex;">
                                            <span style="width: 240px; padding: 16px 12px;">Account Title</span>
                                            <span style="border-left: 1px solid gray; display: flex; align-items: center; padding-left: 12px; padding-right: 12px;">
                                                <h7 style="width: 240px;">{{ bank.account_title }}</h7>
                                            </span>
                                        </div>
                                        <div style="border: 1px solid gray; display: flex;">
                                            <span style="width: 240px; padding: 16px 12px;">Account Number</span>
                                            <span style="border-left: 1px solid gray; display: flex; align-items: center; padding-left: 12px; padding-right: 12px;">
                                                <h7 style="width: 240px;">{{ bank.account_number }}</h7>
                                            </span>
                                        </div>
                                        <div style="border: 1px solid gray; display: flex;">
                                            <span style="width: 240px; padding: 16px 12px;">Account Type</span>
                                            <span style="border-left: 1px solid gray; display: flex; align-items: center; padding-left: 12px; padding-right: 12px;">
                                                <h7 style="width: 240px;">{{ bank.account_type }}</h7>
                                            </span>
                                        </div>
                                        <div style="border: 1px solid gray; display: flex;">
                                            <span style="width: 240px; padding: 16px 12px;">Bank Name</span>
                                            <span style="border-left: 1px solid gray; display: flex; align-items: center; padding-left: 12px; padding-right: 12px;">
                                                <h7 style="width: 240px;">{{ bank.bank_name }}</h7>
                                            </span>
                                        </div>
                                        <div style="border: 1px solid gray; display: flex;">
                                            <span style="width: 240px; padding: 16px 12px;">Branch Name & Address</span>
                                            <span style="border-left: 1px solid gray; display: flex; align-items: center; padding-left: 12px; padding-right: 12px;">
                                                <h7 style="width: 240px;">{{ bank.branch_detail }}</h7>
                                            </span>
                                        </div>
                                        <div style="border: 1px solid gray; display: flex;">
                                            <span style="width: 240px; padding: 16px 12px;">Branch Routing Number</span>
                                            <span style="border-left: 1px solid gray; display: flex; align-items: center; padding-left: 12px; padding-right: 12px;">
                                                <h7 style="width: 240px;">{{ bank.branch_routing_number }}</h7>
                                            </span>
                                        </div>
                                        <div style="border: 1px solid gray; display: flex;">
                                            <span style="width: 240px; padding: 16px 12px;">Swift Code</span>
                                            <span style="border-left: 1px solid gray; display: flex; align-items: center; padding-left: 12px; padding-right: 12px;">
                                                <h7 style="width: 240px;">{{ bank.swift_number }}</h7>
                                            </span>
                                        </div>
                                        <div style="border: 1px solid gray; display: flex;">
                                            <span style="width: 240px; padding: 16px 12px;">Beneficiery E-mail Address</span>
                                            <span style="border-left: 1px solid gray; display: flex; align-items: center; padding-left: 12px; padding-right: 12px;">
                                                <h7 style="width: 240px;">{{ bank.beneficiery_email }}</h7>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <div class="loader-box">
                        <div class="loader-10"></div>
                    </div>
                </div>

            </div>

            <div class="col-xl-4">
                <div class="content-container-attahment w-100">
                    <div class="p-2" v-if="!loading && !error">
                        <div class="d-flex justify-content-between">
                            <h6 class="mb-2">Supporting Documents</h6>
                            <feather type="plus-square" size="22px" style="color: #ff595d; cursor: pointer;" v-b-modal.modal-scrollable-add-document v-b-tooltip.hover title="Add Documents"> </feather>
                        </div>

                        <b-table show-empty sticky-header :items="bank.info_attach" :fields="Attachment" head-variant="light">
                            <template #cell(index)="data">
                                {{ data.index + 1 }}
                            </template>

                            <template #cell(title)="data">
                                <a :href="host+data.item.info_attachment" target="_blank"> {{ data.item.attach_title}}</a>
                            </template>
                            <template #cell(action)="data">
                                <div style="display: flex; flex-direction: row; gap:10px">
                                    <feather type="delete" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover title="Delete" @click="deleteAttach(data.item.id)"></feather>
                                </div>
                            </template>
                        </b-table>
                    </div>

                    <div class="p-2" v-else>
                        <div class="loader-box">
                            <div class="loader-10"></div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <!--  Attachment Create -->
    <b-modal id="modal-scrollable-add-document" ref="modal" title="Add document" @ok="handleAttachOk">
        <form ref="form" @submit.stop.prevent="handleAttahmentSubmit">
            <div class="row">
                <div class="col">
                    <div class="form-group">
                        <label>Upload Documents</label>
                        <b-form-file placeholder="Choose files  or drop them here..." drop-placeholder="Drop file here..." @change="uploadAttachFile"></b-form-file>
                    </div>
                </div>
            </div>
        </form>
    </b-modal>
    <!-- Container-fluid Ends-->
</div>
</template>

<script>
import {
    mapState
} from 'vuex';
import {
    myHost
} from '../../../_helpers/host';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
export default {
    data() {
        return {
            showPage: true,
            showLogo: true,
            bank: {},
            host: myHost.host,
            showLetterHead: true,

            add_attachment: {
                info_attachment: null,
                bank_info: ''
            },

            Attachment: [{
                    key: 'index',
                    label: '#'
                },
                {
                    key: 'title',
                    label: 'Title',
                    sortable: true
                },
                {
                    key: 'action',
                    label: 'Action',
                    sortable: true
                }
            ],

        };
    },

    computed: {
        ...mapState({
            account_detail: state => state.bank.account_detail,
            loading: state => state.bank.submitLoading,
            error: state => state.bank.submitError,
            setting: state => state.organization.organization_setting,
        }),

    },

    watch: {
        account_detail(oldValue, newValue) {
            this.bank = this.account_detail
        }
    },

    mounted() {
        this.$store.dispatch("bank/fetchBankAccountDetails", this.$route.params.id),
            this.$store.dispatch("organization/fetchOrganizationSettings")
    },

    methods: {
        generatePDF() {
            this.showPage = false;
            const element = this.$refs.printDiv;
            const pdf = new jsPDF('p', 'pt', 'letter');

            // Background and logo image settings
            const backgroundImageUrl = this.showLetterHead ? this.host + this.setting.letterpad : null;
            const logoImageUrl = this.showLogo ? this.host + this.setting.logo : null;

            const backgroundImg = new Image();
            const logoImg = new Image();

            const loadImage = (img, src) => {
                return new Promise((resolve, reject) => {
                    if (!src) {
                        resolve();
                    } else {
                        img.crossOrigin = 'anonymous'; // Ensure CORS is properly set if needed
                        img.src = src;
                        img.onload = () => resolve(img);
                        img.onerror = reject;
                    }
                });
            };

            Promise.all([loadImage(backgroundImg, backgroundImageUrl), loadImage(logoImg, logoImageUrl)]).then((images) => {
                const pdfWidth = pdf.internal.pageSize.getWidth();
                const pdfHeight = pdf.internal.pageSize.getHeight();

                // Content padding and margin
                const contentPaddingTop = 0; // Adjust the top padding as needed
                const contentPaddingBottom = 0; // Adjust the bottom padding as needed
                const contentMargin = 0; // Margin between content and background image

                let positionY = contentPaddingTop; // Start position at the top padding
                let contentHeight = element.offsetHeight;

                const addContent = () => {
                    pdf.html(element, {
                        x: contentMargin,
                        y: positionY + contentMargin,
                        html2canvas: {
                            scale: 0.6,
                            useCORS: true // Use CORS for capturing images
                        },
                        callback: () => {
                            const filename = 'Account_Details.pdf';
                            pdf.save(filename);
                            this.showPage = true;
                        },
                        margin: [180, 0, 80, 0],
                    });
                };

                const addImages = () => {
                    if (this.showLetterHead) {
                        pdf.addImage(backgroundImg, 'JPEG', 0, 0, pdfWidth, pdfHeight);
                    }
                    if (this.showLogo) {
                        const logoWidth = logoImg.width; // Use the natural width of the logo image
                        const logoHeight = 30; // Use the natural height of the logo image
                        const logoMargin = 50; // Right margin for the logo
                        const logoX = pdfWidth - logoWidth - logoMargin;
                        const logoY = 18; // Margin from top
                        pdf.addImage(logoImg, 'JPEG', logoX, logoY, logoWidth, logoHeight);
                    }
                };

                addContent();
                addImages();

                let pageCount = Math.ceil(contentHeight / 794);
                for (let i = 1; i < pageCount; i++) {
                    pdf.addPage();
                    addImages();
                }
            }).catch((error) => {
                console.error('Error loading images:', error);
            });
        },

        // generatePDF() {
        //     this.showPage = false;
        //     const element = this.$refs.printDiv;
        //     const pdf = new jsPDF('p', 'pt', 'letter');

        //     // Background and logo image settings
        //     const backgroundImageUrl = this.showLetterHead ? this.host + this.setting.letterpad : null;
        //     const logoImageUrl = this.showLogo ? this.host + this.setting.logo : null;

        //     const backgroundImg = new Image();
        //     const logoImg = new Image();

        //     const loadImage = (img, src) => {
        //         return new Promise((resolve, reject) => {
        //             if (!src) {
        //                 resolve();
        //             } else {
        //                 img.crossOrigin = 'anonymous'; // Ensure CORS is properly set if needed
        //                 img.src = src;
        //                 img.onload = () => resolve(img);
        //                 img.onerror = reject;
        //             }
        //         });
        //     };

        //     Promise.all([loadImage(backgroundImg, backgroundImageUrl), loadImage(logoImg, logoImageUrl)]).then(() => {
        //         const pdfWidth = pdf.internal.pageSize.getWidth();
        //         const pdfHeight = pdf.internal.pageSize.getHeight();

        //         // Render the text content first
        //         pdf.html(element, {
        //             html2canvas: {
        //                 scale: 0.6,
        //                 useCORS: true // Use CORS for capturing images
        //             },
        //             callback: (doc) => {
        //                 const filename = 'Account_Details.pdf';
        //                 const pageCount = doc.internal.getNumberOfPages();

        //                 // Loop through each page and add the watermark with reduced opacity
        //                 for (let i = 1; i <= pageCount; i++) {
        //                     doc.setPage(i);

        //                     if (this.showLetterHead) {
        //                         // Set opacity to 0.4 for the watermark
        //                         doc.setGState(new doc.GState({
        //                             opacity: 0.9
        //                         }));
        //                         doc.addImage(backgroundImg, 'JPEG', 0, 0, pdfWidth, pdfHeight);
        //                         // Reset opacity to 1 for the rest of the content
        //                         doc.setGState(new doc.GState({
        //                             opacity: 1
        //                         }));
        //                     }
        //                     if (this.showLogo) {
        //                         const logoWidth = logoImg.width; // Use the natural width of the logo image
        //                         const logoHeight = 30; // Use the natural height of the logo image
        //                         const logoMargin = 50; // Right margin for the logo
        //                         const logoX = pdfWidth - logoWidth - logoMargin;
        //                         const logoY = 18; // Margin from top
        //                         doc.addImage(logoImg, 'JPEG', logoX, logoY, logoWidth, logoHeight);
        //                     }
        //                 }

        //                 doc.save(filename);
        //                 this.showPage = true;
        //             },
        //             margin: [80, 0, 80, 0],
        //         });
        //     }).catch((error) => {
        //         console.error('Error loading images:', error);
        //     });
        // },
        showPDF() {
            this.showPage = false;
            const element = this.$refs.printDiv;
            const pdf = new jsPDF('p', 'pt', 'letter');

            // Background and logo image settings
            const backgroundImageUrl = this.showLetterHead ? this.host + this.setting.letterpad : null;
            const logoImageUrl = this.showLogo ? this.host + this.setting.logo : null;

            const backgroundImg = new Image();
            const logoImg = new Image();

            const loadImage = (img, src) => {
                return new Promise((resolve, reject) => {
                    if (!src) {
                        resolve();
                    } else {
                        img.crossOrigin = 'anonymous'; // Ensure CORS is properly set if needed
                        img.src = src;
                        img.onload = () => resolve(img);
                        img.onerror = reject;
                    }
                });
            };

            Promise.all([loadImage(backgroundImg, backgroundImageUrl), loadImage(logoImg, logoImageUrl)]).then((images) => {
                const pdfWidth = pdf.internal.pageSize.getWidth();
                const pdfHeight = pdf.internal.pageSize.getHeight();

                // Content padding and margin
                const contentPaddingTop = 0; // Adjust the top padding as needed
                const contentPaddingBottom = 0; // Adjust the bottom padding as needed
                const contentMargin = 0; // Margin between content and background image

                let positionY = contentPaddingTop; // Start position at the top padding
                let contentHeight = element.offsetHeight;

                const addContent = () => {
                    pdf.html(element, {
                        x: contentMargin,
                        y: positionY + contentMargin,
                        html2canvas: {
                            scale: 0.6,
                            useCORS: true // Use CORS for capturing images
                        },
                        callback: () => {
                            // const filename = `${this.invoiceData.invoice_id}.pdf`;

                            // Convert the PDF to a Blob and create an object URL
                            const pdfBlob = pdf.output('blob');
                            const pdfUrl = URL.createObjectURL(pdfBlob);

                            // Open the PDF in a new tab
                            window.open(pdfUrl);

                            this.showPage = true;
                        },
                        margin: [180, 0, 80, 0],
                    });
                };

                const addImages = () => {
                    if (this.showLetterHead) {
                        pdf.addImage(backgroundImg, 'JPEG', 0, 0, pdfWidth, pdfHeight);
                    }
                    if (this.showLogo) {
                        const logoWidth = logoImg.width; // Use the natural width of the logo image
                        const logoHeight = 30; // Use the natural height of the logo image
                        const logoMargin = 50; // Right margin for the logo
                        const logoX = pdfWidth - logoWidth - logoMargin;
                        const logoY = 18; // Margin from top
                        pdf.addImage(logoImg, 'JPEG', logoX, logoY, logoWidth, logoHeight);
                    }
                };

                addContent();
                addImages();

                let pageCount = Math.ceil(contentHeight / 794);
                for (let i = 1; i < pageCount; i++) {
                    pdf.addPage();
                    addImages();
                }
            }).catch((error) => {
                console.error('Error loading images:', error);
            });
        },

        toggleLetterHead() {
            this.showLetterHead = !this.showLetterHead;
        },
        toggleLogo() {
            this.showLogo = !this.showLogo;
        },
        printPdf(section) {
            this.downloadPdf = true;

            const printContents = document.getElementById(section).innerHTML;
            const originalContents = document.body.innerHTML;
            const wrappedPrintContents = `<div class="page">${printContents}</div>`;

            document.body.innerHTML = wrappedPrintContents;

            window.print();

            document.body.innerHTML = originalContents;
            location.reload();
        },
        uploadAttachFile(e) {
            this.add_attachment.info_attachment = e.target.files[0]
        },

        handleAttachOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleAttahmentSubmit()
        },

        handleAttahmentSubmit() {
            this.add_attachment.bank_info = this.$route.params.id
            this.$store.dispatch("bank/addBankAttachment", {
                attachment: this.add_attachment,
                bank_info: this.$route.params.id
            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.add_attachment.invoice_attachment = null

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })

            this.$nextTick(() => {
                this.$bvModal.hide('modal-scrollable-add-document')
            })
        },

        deleteAttach(id) {
            this.$store.dispatch("bank/deleteAttachmentFromBank", {
                id: id,
                bank_info: this.$route.params.id,

            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
        },
    }
};
</script>

<style scoped>
.content-container {
    background-color: white;
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 20px 60px;
}

.imgSection {
    position: absolute;
    top: 32px;
    right: 58px;
}

.content-container-attahment {
    background-color: white;
    padding: 10px;
}

.invoice-header,
.client-section {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: start;
    margin-bottom: 48px;
}

.company-info,
.client-info {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: start;
}

.payment-info {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-items: flex-end;
    align-items: flex-end;
}

.total-item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: start;
}

.invoice-info {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: end;
}

.logo-img {
    object-fit: cover;
    object-position: center;
}

.table-container {
    width: 100%;
    font-size: small;
    color: black;
}

.total-container {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    align-self: flex-end;
    margin-top: 20px;
    margin-bottom: 32px;
}

.other-info-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

/* @media print {
        html,
        body {
            width: 210mm;
            height: 297mm;
        }

        .content-container {
            margin: 0;
            width: initial;
            min-height: initial;
        }
    } */

.invoice-container {
    width: 100%;
    max-width: 1280px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 32px;
}

@media print {
    .invoice-container {
        display: none;
        visibility: hidden;
    }

    .signature-btn {
        display: none;
        visibility: hidden;
    }
}

@media screen and (max-width: 639px) {
    .content-container {
        background-color: white;
        display: flex;
        flex-direction: column;
        position: relative;
        padding: 20px 20px;
    }

    .invoice-header {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-bottom: 20px;
    }

    .invoice-info {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-items: center;
        align-items: start;
    }

    .payment-info {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-items: flex-start;
        align-items: flex-start;
    }

    .client-section {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: start;
        gap: 20px;
        margin-bottom: 20px;
    }

    .total-container {
        width: 80%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        align-self: flex-end;
        margin-top: 20px;
        margin-bottom: 32px;
    }
}

#print {
    width: 1020px;
    padding: 0in 0in;
    box-sizing: border-box;

}
</style>
