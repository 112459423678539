<template>
<div>
    <Breadcrumbs main="Finance & Accounts" submain="Finance" title="Invoices" />
    <!-- Container-fluid starts-->
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header" style="max-width: fit-content">
                        <router-link to="/finance-and-accounts/finance/invoice/create/new">
                            <button class="btn btn-primary" type="submit" style="display: flex; align-items: center;">

                                <div style="display: flex; gap: 12px; align-items: center; color: white;">
                                    <i class="fa fa-light fa-plus"></i>
                                    <h7>Add New Invoice</h7>
                                </div>

                            </button>
                        </router-link>
                    </div>

                    <div class="card-body">
                        <b-row>
                            <b-col xl="6">

                                <b-input-group class="datatable-btn">
                                    <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
                                    <b-input-group-append>
                                        <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                    </b-input-group-append>
                                </b-input-group>

                            </b-col>

                            <b-col xl="6">
                                <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                                    <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <div v-if="!loading && !error">
                            <div class="table-responsive datatable-vue">
                                <b-table show-empty stacked="md" :items="items" :fields="tablefields" :filter="filter" :current-page="currentPage" :per-page="perPage" @filtered="onFiltered">
                                    <template #cell(index)="data">
                                        {{ (currentPage - 1) * perPage + data.index + 1 }}
                                    </template>

                                    <template #cell(invoice_id)="data">
                                        <span>
                                            <router-link :to="`/finance-and-accounts/finance/invoice/${data.item.invoice_id}`" class="link-style">
                                                {{ data.item.invoice_id }}
                                            </router-link>
                                        </span>
                                    </template>

                                    <template #cell(created_by)="data">
                                        <span>
                                            {{data.item.created_by.full_name }}
                                        </span>
                                    </template>

                                    <template #cell(subtotal)="data">

                                        <div class="d-flex flex-column" style="width: 200px;">
                                            <span class="font-weight-bold">Total: {{ data.item.subtotal }} {{ data.item.currency }}</span>
                                            <span> <span class="text-success font-weight-bold">Paid:</span> {{ data.item.paid_amount }} {{ data.item.currency }}</span>
                                            <span> <span class="text-danger font-weight-bold">Due:</span> {{ data.item.due_amount }} {{ data.item.currency }}</span>
                                        </div>
                                    </template>

                                    <template #cell(record)="data">

                                        <div @click="recordPayment(data)">
                                            <b-badge variant="primary" v-if="data.item.due_amount > 0" v-b-modal.modal-record>Record Payment</b-badge>
                                        </div>
                                    </template>

                                    <template #cell(status)="row">
                                        <span @click="getStatus(row.value, row.item.id)" v-if="row.value == 'APPROVED'">
                                            <b-badge pill variant="success" v-b-modal.modal-prevent-closing>{{ row.value }}</b-badge>
                                        </span>
                                        <span @click="getStatus(row.value, row.item.id)" v-if="row.value == 'PENDING'">
                                            <b-badge pill variant="primary" v-b-modal.modal-prevent-closing>{{ row.value }}</b-badge>
                                        </span>
                                        <span @click="getStatus(row.value, row.item.id)" v-if="row.value == 'DUE'">
                                            <b-badge pill variant="danger" v-b-modal.modal-prevent-closing>{{ row.value }}</b-badge>
                                        </span>
                                    </template>

                                    <template #cell(action)="data">
                                        <span style="display: flex; gap: 12px;">
                                            <feather type="delete" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover v-b-modal.modal-center @click="deleteInvoice(data.item.id)" title="Delete"></feather>
                                            <router-link :to="`/finance-and-accounts/finance/invoice/edit/${data.item.invoice_id}`">
                                                <feather type="edit" size="20px" style="color: #568ae3; cursor: pointer;" v-b-tooltip.hover title="Edit"></feather>
                                            </router-link>
                                            <!-- <feather type="sidebar" size="20px" style="color: #51bb25; cursor: pointer;" v-b-tooltip.hover title="Invoice Payments"></feather> -->
                                        </span>
                                    </template>
                                </b-table>
                            </div>
                        </div>

                        <div v-else>
                            <div class="loader-box">
                                <div class="loader-10"></div>
                            </div>
                        </div>
                        <b-col md="6" class="my-1 p-0">
                            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" class="my-0"></b-pagination>
                        </b-col>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Container-fluid Ends-->

    <!-- Delete Modal -->
    <b-modal id="modal-center" centered hide-header @ok="handleDeleteOk">
        <div class="mt-4 mb-4" style="font-size: large;">Are you sure you want to delete this invoice?</div>
    </b-modal>

    <!-- Record Payment -->
    <b-modal id="modal-record" ref="modal" title="Record Payment" @show="resetModal" @hidden="resetModal" @ok="handleRecordOk">
        <form ref="form" @submit.stop.prevent="handleRecordSubmit">
            <b-form-group label="Amount" label-for="name-input" invalid-feedback="Item is required">
                <b-form-input id="name-input" v-model="record.paid_amount" placeholder="Enter Amount"></b-form-input>
            </b-form-group>
        </form>
    </b-modal>

    <b-modal id="modal-prevent-closing" ref="modal" title="Update Invoice Status" @show="resetModal" @hidden="resetModal" @ok="handleOk">
        <form ref="form" @submit.stop.prevent="handleSubmit">
            <b-form-group label="Status" label-for="name-input">
                <b-form-select v-model="selectedStatus" :options="statuses"></b-form-select>
            </b-form-group>
        </form>
    </b-modal>

</div>
</template>

<script>
import {
    mapState
} from 'vuex';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';

export default {
    data() {
        return {
            tablefields: [{
                    key: 'index',
                    label: '#'
                },

                {
                    key: 'invoice_id',
                    label: 'Invoice ID',
                    sortable: true
                },
                {
                    key: 'created_by',
                    label: 'Raised By',
                    sortable: true
                },
                {
                    key: 'email',
                    label: 'Email',
                    sortable: true
                },
                {
                    key: 'subtotal',
                    label: 'Amount',
                    sortable: true
                },
                {
                    key: 'invoice_date',
                    label: 'Invoice Date',
                    sortable: true
                },
                {
                    key: 'due_date',
                    label: 'Due Date',
                    sortable: true
                },
                {
                    key: 'status',
                    label: 'Status',
                    sortable: true
                },

                {
                    key: 'record',
                    label: 'Record',
                    sortable: true,
                    class: 'text-center'
                },

                {
                    key: 'action',
                    label: 'Action',
                    sortable: false
                }
            ],

            record: {
                paid_amount: '',
                invoice: '',
            },
            invoice_due: '',

            filter: null,
            delete_value: null,

            selectedStatus: null,
            update_id: null,
            statuses: ['DUE', 'PENDING', 'APPROVED'],

            totalRows: 1,
            currentPage: 1,
            perPage: 25,
            pageOptions: [25, 50, 100],

            temp_invoice: {}
        };
    },

    computed: {
        ...mapState({
            items: state => state.invoice.invoice,
            loading: state => state.invoice.submitLoading,
            error: state => state.invoice.submitError
        }),

        sortOptions() {
            return this.tablefields
                .filter(f => f.sortable)
                .map(f => {
                    return {
                        text: f.label,
                        value: f.key
                    };
                });
        }
    },

    mounted() {
        this.$store.dispatch("invoice/fetchInvoice")
        this.totalRows = this.items.length;
    },

    methods: {

        getStatus(status, id) {
            this.update_id = id
            this.selectedStatus = status

        },

        recordPayment(data) {
            this.record.invoice = data.item.id
            this.invoice_due = data.item.due_amount
        },
        resetModal() {
            this.record.paid_amount = ''
        },

        handleRecordOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleRecordSubmit()
        },

        handleRecordSubmit() {
            if (this.record.paid_amount > this.invoice_due) {
                Swal.fire({
                    title: "Error!",
                    text: 'Request amount is more than due amount!',
                    icon: "error"
                });
            } else {
                this.$store.dispatch("invoice/recordPayment", this.record).then(response => {
                    if (response.code == 200) {
                        Swal.fire({
                            title: "Success!",
                            text: response.response,
                            icon: "success"
                        });

                        this.record.paid_amount = '',
                            this.record.invoice = ''

                    } else {
                        Swal.fire({
                            title: "Error!",
                            text: response.response,
                            icon: "error"
                        });
                    }
                })

                // Hide the modal manually
                this.$nextTick(() => {
                    this.$bvModal.hide('modal-record')
                })
            }

        },

        onFiltered(filteredItems) {

            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },

        deleteInvoice(id) {
            this.delete_value = id
        },

        handleDeleteOk() {
            this.$store.dispatch("invoice/deleteInvoice", this.delete_value).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.delete_value = null

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
        },


        handleOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleSubmit()
        },
        handleSubmit() {

            this.$store.dispatch("invoice/updateInvoiceStatus", {
                id: this.update_id,
                status: this.selectedStatus,
              

            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.delete_value = null

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
            // Hide the modal manually
            this.$nextTick(() => {
                this.$bvModal.hide('modal-prevent-closing')
            })
        },
    }
};
</script>
