<template>
<div>
    <Breadcrumbs main="Finance & Accounts" submain="Accounts" title="Transactions" />

    <!-- Container-fluid starts-->
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12">
                <div class="card">
                    <div class="card-header">
                        <h5>Transaction Analysis</h5>
                    </div>

                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label>Amount</label>
                                    <b-form-input id="name-input" type="number" v-model="transaction.amount" placeholder="Amount" readonly></b-form-input>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-sm-12">
                                <h6>Debit Account</h6>

                                <div class="row" v-for="(debit, index) in transaction.debit_instances" :key="index">
                                    <div class="col-sm-3">
                                        <div class="form-group">
                                            <label>Category</label>
                                            <b-form-select v-model="debit.category" @change="updateGroup(debit.category, true)">
                                                <b-form-select-option v-for="option in accountCategories" :value="option.id">{{ option.title }}</b-form-select-option>
                                            </b-form-select>
                                        </div>
                                    </div>

                                    <div class="col-sm-3">
                                        <div class="form-group">
                                            <label>Group</label>
                                            <input class='form-control' type="text" list="debit_groups" v-model="debit.group" @change="updateLedgers(debit.group, true)" />
                                            <!--                                              <input class='form-contro' type="text" list="debit_ledgers" v-model="debit.ledger_name"/>-->
                                            <datalist id="debit_groups">
                                                <option v-for="option in debit_groups" :value="option.title">{{ option.title }}</option>
                                            </datalist>
                                            <!--                                              <b-form-select v-model="debit.group" @change="updateLedgers(debit.group, true)">-->
                                            <!--                                                <b-form-select-option v-for="option in debit_groups" :value="option.id">{{ option.title }}</b-form-select-option>-->
                                            <!--                                              </b-form-select>-->
                                        </div>
                                    </div>

                                    <div class="col-sm-3">
                                        <div class="form-group">
                                            <label>Ledger</label>
                                            <input class='form-control' type="text" list="debit_ledgers" v-model="debit.ledger" />
                                            <!--                                              <input class='form-contro' type="text" list="debit_ledgers" v-model="debit.ledger_name"/>-->
                                            <datalist id="debit_ledgers">
                                                <option v-for="option in debit_ledgers" :value="option.title">{{ option.title }}</option>
                                            </datalist>
                                            <!--                                                <b-form-select v-model="debit.ledger_id" >-->
                                            <!--                                                  <b-form-select-option v-for="option in debit_ledgers" name="hello" :value="option.id">{{ option.title }}</b-form-select-option>-->
                                            <!--                                                </b-form-select>-->

                                        </div>
                                    </div>

                                    <div class="col-sm-2">
                                        <div class="form-group">
                                            <label>Amount</label>
                                            <b-form-input id="name-input" type="number" v-model="debit.amount" placeholder="Enter debit amount"></b-form-input>
                                        </div>
                                    </div>

                                    <div class="col-sm-1" style="margin-top: 35px;">
                                        <!-- Add Svg Icon -->
                                        <svg @click="addField(debit.id, transaction.debit_instances, true)" width="24" height="24" style="cursor: pointer;">
                                            <path fill="none" d="M0 0h24v24H0z" />
                                            <path fill="green" d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z" />
                                        </svg>

                                        <!-- Remove Svg Icon -->
                                        <svg v-if="transaction.debit_instances.length > 1 && !debit.id" @click="removeField(index, transaction.debit_instances)" width="24" height="24" style="cursor: pointer;">
                                            <path fill="none" d="M0 0h24v24H0z" />
                                            <path fill="#B13032" d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z" />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-sm-12">
                                <h6>Credit Account</h6>

                                <div class="row" v-for="(credit, index) in transaction.credit_instances" :key="index">
                                    <div class="col-sm-3">
                                        <div class="form-group">
                                            <label>Category</label>
                                            <b-form-select v-model="credit.category" @change="updateGroup(credit.category)">
                                                <b-form-select-option v-for="option in accountCategories" :value="option.id">{{ option.title }}</b-form-select-option>
                                            </b-form-select>

                                        </div>
                                    </div>

                                    <div class="col-sm-3">
                                        <div class="form-group">
                                            <label>Group</label>
                                            <input class='form-control' type="text" list="credit_groups" v-model="credit.group" @change="updateLedgers(credit.group, false)" />
                                            <!--                                              <input class='form-contro' type="text" list="debit_ledgers" v-model="debit.ledger_name"/>-->
                                            <datalist id="credit_groups">
                                                <option v-for="option in credit_groups" :value="option.title">{{ option.title }}</option>
                                            </datalist>
                                            <!--                                              <b-form-select v-model="credit.group" @change="updateLedgers(credit.group, false)" >-->
                                            <!--                                                <b-form-select-option v-for="option in credit_groups" :value="option.id">{{ option.title }}</b-form-select-option>-->
                                            <!--                                              </b-form-select>-->

                                        </div>
                                    </div>

                                    <div class="col-sm-3">
                                        <div class="form-group">
                                            <label>Ledger</label>

                                            <input class='form-control' type="text" list="credit_ledgers" v-model="credit.ledger" />
                                            <datalist id="credit_ledgers">
                                                <option v-for="option in credit_ledgers" :value="option.title">{{ option.title }}</option>
                                            </datalist>
                                            <!--                                              <b-form-select v-model="credit.ledger_id" >-->
                                            <!--                                                <b-form-select-option v-for="option in credit_ledgers" :value="option.id">{{ option.title }}</b-form-select-option>-->
                                            <!--                                              </b-form-select>-->

                                        </div>
                                    </div>

                                    <div class="col-sm-2">
                                        <div class="form-group">
                                            <label>Amount</label>
                                            <b-form-input id="name-input" type="number" v-model="credit.amount" placeholder="Enter credit amount"></b-form-input>
                                        </div>
                                    </div>

                                    <div class="col-sm-1" style="margin-top: 35px;">
                                        <!-- Add Svg Icon -->
                                        <svg @click="addField(credit.id, transaction.credit_instances, false)" width="24" height="24" style="cursor: pointer;">
                                            <path fill="none" d="M0 0h24v24H0z" />
                                            <path fill="green" d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z" />
                                        </svg>

                                        <!-- Remove Svg Icon -->
                                        <svg v-if="transaction.credit_instances.length > 1 && !credit.id" @click="removeField(index, transaction.credit_instances)" width="24" height="24" style="cursor: pointer;">
                                            <path fill="none" d="M0 0h24v24H0z" />
                                            <path fill="#B13032" d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z" />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col">
                                <div class="form-group">
                                    <label>Note</label>
                                    <b-form-textarea id="textarea-auto-height" v-model="transaction.note" rows="3" max-rows="8" placeholder="Enter note..."></b-form-textarea>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col">
                                <div style="width: 100%; text-align: center;">
                                    <b-button variant="primary" @click="handleSubmit" :disabled="isButtonDisabled">Submit</b-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card">
                    <div class="card-header">
                        <h5>Transaction History</h5>
                    </div>

                    <div class="card-body">
                        <b-row>
                            <b-col xl="6">

                                <b-input-group class="datatable-btn">
                                    <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
                                    <b-input-group-append>
                                        <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                    </b-input-group-append>
                                </b-input-group>

                            </b-col>

                            <b-col xl="6">
                                <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                                    <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <div v-if="!accountCategoryLoading && !accountCategoryError">
                            <div class="table-responsive datatable-vue">
                                <b-table show-empty stacked="md" sticky-header :items="items" :fields="tablefields" :filter="filter" :current-page="currentPage" :per-page="perPage" @filtered="onFiltered">
                                    <template #cell(index)="data">
                                        {{ data.item.journal_id}}
                                    </template>
                                    <template #cell(date)="data">
                                        {{ data.item.date.split('T')[0]}}<br>
                                        <small>{{ data.item.date.split('T')[1].split('.')[0] }}</small>
                                    </template>

                                    <template #cell(ledger_break_down)="data">
                                        <span style="display: flex; flex-direction: column; ">

                                            <span v-for="ledger in data.item.ledger_break_down.debit" style="display: flex; flex-direction: column">{{ledger.ledger_id.title}}
                                                <span style="border-style: solid; border-width: 0.01rem; border-color:lightgrey"></span>
                                            </span>

                                        </span>

                                        <span style="display: flex; flex-direction: column;">

                                            <span v-for="(ledger,index) in data.item.ledger_break_down.credit" style="display: flex; flex-direction: column">
                                                <span style="margin-left: 16px;">{{ledger.ledger_id.title}}</span>
                                                <span v-if="data.item.ledger_break_down.credit.length-1!==index" style="border-style: solid; border-width: 0.01rem; border-color:lightgrey"></span>

                                            </span>

                                        </span>

                                    </template>

                                    <template #cell(reference)="data">
                                        <span style="display: flex; flex-direction: column;">
                                            <span v-for="ledger in data.item.ledger_break_down.debit" style="display: flex; flex-direction: column">
                                                <router-link :to="{ path: '/finance-and-accounts/account/ledger/' + ledger.ledger_id.lid }">
                                                    {{ ledger.ledger_id.lid }}
                                                </router-link>
                                                <span style="border-style: solid; border-width: 0.01rem; border-color:lightgrey"></span>

                                            </span>
                                            <span v-for="(ledger,index) in data.item.ledger_break_down.credit" style="display: flex; flex-direction: column">
                                                <router-link :to="{ path: '/finance-and-accounts/account/ledger/' + ledger.ledger_id.lid }">
                                                    {{ ledger.ledger_id.lid}}
                                                </router-link>
                                                <span v-if=" data.item.ledger_break_down.credit.length-1!==index" style="border-style: solid; border-width: 0.01rem; border-color:lightgrey"></span>
                                            </span>
                                        </span>
                                    </template>

                                    <template #cell(debit)="data">
                                        <span style="display: flex; flex-direction: column;">
                                            <span v-for="ledger in data.item.ledger_break_down.debit" style="display: flex; flex-direction: column;">

                                                {{ ledger.amount }}

                                                <span style="border-style: solid; border-width: 0.01rem; border-color:lightgrey"></span>

                                            </span>
                                            <span v-for="(ledger,index) in data.item.ledger_break_down.credit" style="display:flex;flex-direction: column">
                                                <br>
                                                <span v-if=" data.item.ledger_break_down.credit.length-1!==index" style="border-style: solid; border-width: 0.01rem; border-color:lightgrey"></span>

                                            </span>
                                        </span>
                                    </template>

                                    <template #cell(credit)="data">
                                        <span style="display: flex; flex-direction: column;">
                                            <span v-for="ledger in data.item.ledger_break_down.debit" style="display: flex; flex-direction: column;">

                                                <br>
                                                <span style="border-style: solid; border-width: 0.01rem; border-color:lightgrey"></span>

                                            </span>
                                            <span v-for="(ledger,index) in data.item.ledger_break_down.credit" style="display: flex; flex-direction: column;">
                                                {{ ledger.amount }}
                                                <span v-if=" data.item.ledger_break_down.credit.length-1!==index" style="border-style: solid; border-width: 0.01rem; border-color:lightgrey"></span>
                                            </span>
                                        </span>
                                    </template>
                                    <template #cell(amount)="data">
                                        <span style="display: flex; flex-direction: column;">

                                            {{ data.item.amount }}

                                        </span>
                                    </template>
                                    <template #cell(note)="data">
                                        <span>
                                            {{data.item.note}}
                                        </span>
                                    </template>

                                    <template #cell(action)="data">
                                        <span style="display: flex; gap: 12px;">
                                            <div @click="editNote(data.item)">
                                                <feather type="edit" size="20px" style="color: #568ae3; cursor: pointer;" v-b-tooltip.hover title="Edit note" v-b-modal.modal-prevent-closing-edit></feather>
                                            </div>
                                        </span>
                                    </template>
                                </b-table>
                            </div>
                        </div>

                        <div v-else>
                            <div class="loader-box">
                                <div class="loader-10"></div>
                            </div>
                        </div>

                        <b-col md="6" class="my-1 p-0">
                            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" class="my-0"></b-pagination>
                        </b-col>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Container-fluid Ends-->

    <!-- Note Edit Modal -->
    <b-modal id="modal-prevent-closing-edit" ref="modal" title="Edit note" @show="resetEditModal" @hidden="resetEditModal" @ok="handleEditOk">
        <form ref="form" @submit.stop.prevent="handleEditSubmit">
            <b-form-group label="Note" label-for="name-input">
                <b-form-textarea id="textarea-auto-height" v-model="edit_note.note" rows="3" max-rows="8" placeholder="Enter note..."></b-form-textarea>
            </b-form-group>

        </form>
    </b-modal>
</div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import {
    mapState
} from "vuex";
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
export default {
    components: {
        Datepicker
    },

    data() {
        return {
            isButtonDisabled: false,
            tablefields: [{
                    key: 'index',
                    label: '#',
                    sortable: true
                },
                {
                    key: 'date',
                    label: 'Date',
                    sortable: true
                },
                {
                    key: 'ledger_break_down',
                    label: 'Account Title',
                    sortable: true
                },
                {
                    key: 'reference',
                    label: 'Reference',
                },
                {
                    key: 'debit',
                    label: 'Debit',
                },
                {
                    key: 'credit',
                    label: 'Credit',
                },
                {
                    key: 'amount',
                    label: 'amount',
                },
                {
                    key: 'note',
                    label: 'Note',
                },
                {
                    key: 'action',
                    label: 'Action',
                }
            ],

            items: [

            ],
            edit_note:{
                id:null,
                note:'',
            },

            credit_groups: [],
            credit_ledgers: [],
            debit_groups: [],
            debit_ledgers: [],

            transaction: {
                date: '',
                debit_instances: [{
                    category: null,
                    group: null,
                    ledger: null,
                    amount: null,
                    is_debit: true
                }],
                credit_instances: [{
                    category: null,
                    group: null,
                    ledger: null,
                    amount: null,
                    is_debit: false
                }],
                amount: 0,
                note: ''
            },
            debitamount: 0,
            creditamount: 0,
            // options: ["Digital Marketing", "Front-end", "Back-end"],
            //
            filter: "",

            totalRows: 1,
            currentPage: 2,
            perPage: 25,
            pageOptions: [25, 50, 100]
        };
    },

    computed: {
        ...mapState('account', ["accountTransactions", "accountTransactionsLoading", "accountTransactionsError", "accountCategories", "accountGroups", "accountLedgers", "accountLedgerLoading", "accountLedgerError", "accountGroupLoading", "accountGroupError", "accountCategoryLoading", "accountCategoryError", "submitError", "submitSuccess", "submitLoading"]),
        sortOptions() {
            // Create an options list from our fields
            return this.tablefields
                .filter(f => f.sortable)
                .map(f => {
                    return {
                        text: f.label,
                        value: f.key
                    };
                });
        },
        totalDebitAmount() {
            return this.transaction.debit_instances.reduce((total, debit) => {
                return total + (parseFloat(debit.amount) || 0);
            }, 0);
        },
        totalCreditAmount() {
            return this.transaction.credit_instances.reduce((total, credit) => {
                return total + (parseFloat(credit.amount) || 0);
            }, 0);
        }
    },
    created() {
        this.$store.dispatch('account/fetchAccountTransactions').then(result => {
            this.items = this.accountTransactions
            this.totalRows = this.items.length
        })
        this.$store.dispatch('account/fetchAccountCategory')
        this.$store.dispatch('account/fetchAccountGroup').then(result => {
            this.debit_groups = this.accountGroups
            this.credit_groups = this.accountGroups
        })
        this.$store.dispatch('account/fetchAccountLedger').then(result => {
            this.debit_ledgers = this.accountLedgers
            this.credit_ledgers = this.accountLedgers
        })

    },
    watch: {

        totalCreditAmount(newValue) {
            this.creditamount = newValue;
            this.updateTransactionAmount();
        },
        totalDebitAmount(newValue) {
            this.debitamount = newValue;
            this.updateTransactionAmount();
        },

        accountCategories(oldValue, newValue) {

            // this.options = this.accountCategories

        },
        accountTransactions(oldValue, newValue) {

            this.items = this.accountTransactions
            this.totalRows = this.items.length

        },
        accountLedgers(oldValue, newValue) {

            // this.options = this.accountCategories

        },

        accountGroups(oldValue, newValue) {

            // this.items = this.accountGroups

        },

    },

    mounted() {
        // Set the initial number of items
        this.totalRows = this.items.length;

    },

    methods: {
        updateTransactionAmount() {
            if (this.debitamount === this.creditamount) {
                this.transaction.amount = this.debitamount;
            } else {
                this.transaction.amount = 0;
            }
        },
        updateGroup(id, is_debit) {
            console.log("category_id", id)

            this.$store.dispatch('account/fetchAccountGroupByCategory', id).then(result => {
                if (is_debit) {
                    this.debit_groups = this.accountGroups
                } else {
                    this.credit_groups = this.accountGroups
                }
            })

        },
        updateLedgers(id, is_debit) {

            this.$store.dispatch('account/fetchAccountLedgerByGroup', id).then(result => {
                if (is_debit) {
                    this.debit_ledgers = this.accountLedgers
                } else {
                    this.credit_ledgers = this.accountLedgers
                }
            })

        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 2;
        },

        addField(value, fieldType, is_debit) {

            fieldType.push({
                is_debit: is_debit
            });
        },

        removeField(index, fieldType) {
            fieldType.splice(index, 1);
        },

        handleSubmit() {
            if (this.debitamount != 0 && this.creditamount != 0) {
                if (this.debitamount === this.creditamount) {
                    if (!this.isButtonDisabled) {
                        this.isButtonDisabled = true;
                        this.$store.dispatch("account/recordTransaction", this.transaction).then(response => {
                            if (response.code == 200) {
                                this.isButtonDisabled = false;
                                Swal.fire({
                                    title: "Success!",
                                    text: response.response,
                                    icon: "success"
                                });

                                this.transaction = {
                                    date: '',
                                    debit_instances: [{
                                        category: null,
                                        group: null,
                                        ledger_id: null,
                                        amount: null,
                                        is_debit: true
                                    }],
                                    credit_instances: [{
                                        category: null,
                                        group: null,
                                        ledger_id: null,
                                        amount: null,
                                        is_debit: false
                                    }],
                                    amount: '',
                                    note: ''
                                }
                            } else {
                                this.isButtonDisabled = false;
                                Swal.fire({
                                    title: "Error!",
                                    text: response.response,
                                    icon: "error"
                                });

                            }

                        })

                    }
                } else {
                    Swal.fire({
                        title: "Error!",
                        text: 'Debit amount must be equal to credit amount.',
                        icon: "error"
                    });
                }
            } else {
                Swal.fire({
                    title: "Error!",
                    text: 'Please enter valid amount',
                    icon: "error"
                });
            }

        },
        editNote(type) {
            this.edit_note.id = type.journal_id
            this.edit_note.note = type.note
          
        },

        resetEditModal() {
            this.edit_note.id = null
            this.edit_note.note = null
        },

        handleEditOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleEditSubmit()
        },

        handleEditSubmit() {
            this.$store.dispatch("account/updateAccountTransactions", {

                id: this.edit_note.id,
                note: this.edit_note.note,
               

            }).then(response => {

                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.edit_item.phone = '',
                        this.edit_item.company = '',
                        this.edit_item.address = ''

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
            this.$nextTick(() => {
                this.$bvModal.hide('modal-prevent-closing-edit')
            })
        },
    }
};
</script>
