<template>
<div>
    <Breadcrumbs main="Finance & Accounts" submain="Accounts" title="Income Statement" />

    <!-- Container-fluid starts-->
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12">
                <div class="card">
                    <div class="card-body">
                        <div class="col-sm-12 text-center">
                            <h5>Income Statement</h5>
                            <br><br>
                            <div class="row" v-if="!incomeStatementLoading && !incomeStatementError">
                                <div class="col-2"></div>
                                <div class="col-8 ">
                                    <div class="row">
                                        <div class="col-6 text-left">
                                            {{ getElement('net_sales').title }}
                                        </div>
                                        <div class="col-6 text-right">
                                            {{ getElement('net_sales').amount }}
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-6 text-left">
                                            {{ getElement('cost_of_sales').title }}
                                        </div>
                                        <div class="col-6 text-right">
                                            {{ getElement('cost_of_sales').amount }}
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-10 text-left">

                                        </div>

                                        <div class="col-2">
                                            <hr style="border-style: solid; border-width: 0.01rem; border-color: black;">
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-2"></div>
                                        <div class="col-4 text-left" style="font-weight: bolder">
                                            GROSS PROFIT
                                        </div>
                                        <div class="col-6 text-right" style="font-weight: bolder">
                                            {{ incomeStatementCalculationDetails.gross_profit }}
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-10 text-left">

                                        </div>

                                        <div class="col-2">
                                            <hr style="border-style: solid; border-width: 0.01rem; border-color: black;">
                                        </div>
                                    </div>

                                    <br>

                                    <div class="row">
                                        <div class="col-6 text-left">
                                            {{ getElement('selling_and_operating_expenses').title }}
                                        </div>
                                        <div class="col-6 text-right">
                                            {{ getElement('selling_and_operating_expenses').amount }}
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-6 text-left">
                                            {{ getElement('general_and_administrative_expenses').title }}
                                        </div>
                                        <div class="col-6 text-right">
                                            {{ getElement('general_and_administrative_expenses').amount }}
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-10 text-left">

                                        </div>

                                        <div class="col-2">
                                            <hr style="border-style: solid; border-width: 0.01rem; border-color: black;">
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-2"></div>
                                        <div class="col-4 text-left" style="font-weight: bolder">
                                            TOTAL OPERATING EXPENSES
                                        </div>
                                        <div class="col-6 text-right" style="font-weight: bolder">
                                            {{incomeStatementCalculationDetails.total_operating_expenses}}
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-10 text-left">

                                        </div>

                                        <div class="col-2">
                                            <hr style="border-style: solid; border-width: 0.01rem; border-color: black;">
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-2"></div>
                                        <div class="col-4 text-left" style="font-weight: bolder">
                                            OPERATING INCOME
                                        </div>
                                        <div class="col-6 text-right" style="font-weight: bolder">
                                            {{incomeStatementCalculationDetails.operating_income}}

                                        </div>
                                    </div>

                                    <br>
                                    <div class="row">
                                        <div class="col-6 text-left">
                                            {{ getElement('other_income').title }}
                                        </div>
                                        <div class="col-6 text-right">
                                            {{ getElement('other_income').amount }}
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-6 text-left">
                                            {{ getElement('gain_loss_on_financial_instruments').title }}
                                        </div>
                                        <div class="col-6 text-right">
                                            {{ getElement('gain_loss_on_financial_instruments').amount }}
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-6 text-left">
                                            {{ getElement('loss_gain_on_foreign_currency').title }}
                                        </div>
                                        <div class="col-6 text-right">
                                            {{ getElement('loss_gain_on_foreign_currency').amount }}
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-6 text-left">
                                            {{ getElement('interest_expense').title }}
                                        </div>
                                        <div class="col-6 text-right">
                                            {{ getElement('interest_expense').amount }}
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-10 text-left">

                                        </div>

                                        <div class="col-2">
                                            <hr style="border-style: solid; border-width: 0.01rem; border-color: black;">
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-2"></div>
                                        <div class="col-4 text-left" style="font-weight: bolder">
                                            INCOME BEFORE TAXES
                                        </div>
                                        <div class="col-6 text-right" style="font-weight: bolder">
                                            {{incomeStatementCalculationDetails.income_before_taxes}}
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-6 text-left">
                                            {{ getElement('income_tax_expense').title }}
                                        </div>
                                        <div class="col-6 text-right">
                                            {{ getElement('income_tax_expense').amount }}
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-10 text-left">

                                        </div>

                                        <div class="col-2">
                                            <hr style="border-style: solid; border-width: 0.01rem; border-color: black;">
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-6 text-left" style="font-weight: bolder">
                                            NET INCOME
                                        </div>
                                        <div class="col-6 text-right" style="font-weight: bolder">
                                            {{incomeStatementCalculationDetails.net_income}}

                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-10 text-left">

                                        </div>

                                        <div class="col-2">
                                            <hr style="border-style: solid; border-width: 0.01rem; border-color: black;">
                                            <hr style="border-style: solid; border-width: 0.01rem; border-color: black;">
                                        </div>
                                    </div>

                                </div>

                                <div class="col-2"></div>
                            </div>

                            <div v-else>
                                <div class="row">
                                    <div class="col-sm-12">
                                        <div class="loader-box">
                                            <div class="loader-10"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>

                </div>

            </div>
        </div>

    </div>

    <b-modal id="modal-center" centered hide-header @ok="handleDeleteOk">
        <div class="mt-4 mb-4" style="font-size: large;">Are you sure you want to delete this item?</div>
    </b-modal>
    <!-- Container-fluid Ends-->
</div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import {
    mapState
} from "vuex";
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';

export default {
    components: {
        Datepicker
    },

    data() {
        return {
            tablefields: [{
                    key: 'index',
                    label: '#'
                },
                {
                    key: 'lid',
                    label: 'Ledger ID',
                    sortable: true
                },
                {
                    key: 'title',
                    label: 'Title',
                    sortable: true
                },
                {
                    key: 'group',
                    label: 'Group',
                    sortable: true
                },
                {
                    key: 'group.pre_defined.title',
                    label: 'Pre-Defined Categories',
                    sortable: true
                },
                {
                    key: 'action',
                    label: 'Action',
                    sortable: false
                }
            ],

            items: [],
            structure_item: {
                category: null,
                group: null,
                ledger: null,
                amount: null,
                is_debit: true
            },
            groups: [],
            gross_profit: 0.0,
            total_operating_expenses: 0.0,
            operating_income: 0.0,
            income_before_taxes: 0.0,
            net_income: 0.0,

            ledgers: [],
            delete_value: "",
            delete_slug: "",
            // options: ["Digital Marketing", "Front-end", "Back-end"],
            //
            filter: "",

            totalRows: 1,
            currentPage: 2,
            perPage: 25,
            pageOptions: [25, 50, 100]
        };
    },

    computed: {
        ...mapState('account', ["incomeStatement", "incomeStatementCalculationDetails", "incomeStatementLoading", "incomeStatementError"]),
        sortOptions() {
            // Create an options list from our fields
            return this.tablefields
                .filter(f => f.sortable)
                .map(f => {
                    return {
                        text: f.label,
                        value: f.key
                    };
                });
        }
    },
    created() {
        this.$store.dispatch('account/fetchIncomeStatement').then(result => {
            this.items = this.incomeStatement
            this.totalRows = this.items.length
        })

    },
    watch: {

        incomeStatement(oldValue, newValue) {

            this.items = this.incomeStatement
            this.totalRows = this.items.length

        },
        incomeStatementCalculationDetails(oldValue, newValue) {

        },

    },

    mounted() {
        // Set the initial number of items
        this.totalRows = this.items.length;

    },

    methods: {
        getElement(slug) {
            let index = this.incomeStatement.map(object => object.slug).indexOf(slug)
            return this.incomeStatement[index]
        },
        // calculateGrossProfit(value1, value2) {
        //   this.gross_profit=value1-value2
        //   return this.gross_profit
        // },
        // calculateTotalOperatingExpense(value1, value2) {
        //   this.total_operating_expenses=value1+value2
        //   return this.total_operating_expenses
        // },
        // calculateOperatingIncome() {
        //   this.operating_income=this.gross_profit-this.total_operating_expenses
        //   return this.operating_income
        // },
        // calculateIncomeBeforeTaxes(value1,value2,value3,value4) {
        //   this.income_before_taxes=this.operating_income+value1+value2+value3+value4
        //   console.log(this.income_before_taxes)
        //   return this.income_before_taxes
        // },
        // calculateNetIncome(value) {
        //   this.net_income=this.income_before_taxes-value
        //   return this.net_income
        // },
    }
};
</script>
