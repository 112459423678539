import axios from 'axios';
import {V1API} from "../../../_helpers/APIPath";
import {encode, decode} from "../../dataMagic";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";


const keyList = {
    smsUpdate: 'PAYMENT_UPDATE',
    smsDelete: 'PAYMENT_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,
    state: {
        requisition_payments: [],
        bill_payments: [],
        invoice_payments: [],
        


        submitLoading: false,
        submitError: false,
        submitSuccess: false,

        ...store
    },

    mutations: {
        ...mutations,

        SET_REQUISITION_PAYMENT_LIST(state, data) {
            state.requisition_payments = data;
        },

        SET_BILL_PAYMENT_LIST(state, data) {
            state.bill_payments = data;
        },

        SET_INVOICE_PAYMENT_LIST(state, data) {
            state.invoice_payments = data;
        },



        SUBMIT_LOADING (state, loading) {
            state.submitLoading = loading;
        },

        SUBMIT_ERROR (state, hasError) {
            state.submitError = hasError;
        },

        SUBMIT_SUCCESS (state, success) {
            state.submitSuccess = success;
        }
    },

    actions: {
        // REQUISITION PAYMENT
        async fetchRequisitionPayments ({ commit }) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };
            try{
                let result = await axios.get(V1API.requisition_payments, config);
                const data = result.data.data
                commit('SET_REQUISITION_PAYMENT_LIST', data);
            } catch(error) {
                commit('SUBMIT_ERROR', true);
            }
            finally {
                commit('SUBMIT_LOADING', false);
            }
        },

        async updateRequisitionPaymentStatus({commit, dispatch}, payload) {
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try {
                let response = await axios.get(V1API.requisition_payemnt_list_status_change+ payload.id + "/" + payload.status, config).then(result => {
                    let data = result.data
                    return data

                })
                dispatch("fetchRequisitionPayments")
                if(payload.detail_id){
                    dispatch("requisition/fetchRequisitionPaymentByRequisition", payload.detail_id, { root: true })
                }
               
                return response
            } catch (e) {
                commit('SUBMIT_ERROR', true);
            } finally {
                commit('SUBMIT_LOADING', false);
            }
        },

        async deleteRequisitionPayments ({dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.delete(V1API.delete_requisition_payments + payload, config).then(result=>{
                let data = result.data
                return data
            })

            dispatch('fetchRequisitionPayments');

            return response
        },


        // BILL PAYMENT
        async fetchBillPayments ({ commit }) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.bill_payments, config);
                const data = result.data.data

                commit('SET_BILL_PAYMENT_LIST', data);

            } catch(error) {
                commit('SUBMIT_ERROR', true);
            }
            finally {
                commit('SUBMIT_LOADING', false);
            }
        },

        async updateBillPaymentStatus({commit, dispatch}, payload) {
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try {
                let response = await axios.get(V1API.bill_payment_list_status_change+ payload.id + "/" + payload.status, config).then(result => {
                    let data = result.data
                    return data
                })
                dispatch("fetchBillPayments")

                if(payload.detail_id){
                    dispatch("bill/fetchBillPaymentByBill", payload.detail_id, { root: true })
                }
              
                return response
            } catch (e) {
                commit('SUBMIT_ERROR', true);
            } finally {
                commit('SUBMIT_LOADING', false);
            }
        },

        async deleteBillPayments ({dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.delete(V1API.delete_bill_payments + payload, config).then(result=>{
                let data = result.data
                return data
            })

            dispatch('fetchBillPayments');

            return response
        },

        // INVOICE PAYMENT
        async fetchInvoicePayments ({ commit }) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.invocie_payments, config); 
                const data = result.data.data

                commit('SET_INVOICE_PAYMENT_LIST', data);

            } catch(error) {
                commit('SUBMIT_ERROR', true);
            }
            finally {
                commit('SUBMIT_LOADING', false);
            }
        },

        async updateInvoicePaymentStatus({commit, dispatch}, payload) {
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try {
                let response = await axios.get(V1API.invoice_payment_list_status_change+ payload.id + "/" + payload.status, config).then(result => {
                    let data = result.data
                    return data
                })
                dispatch("fetchInvoicePayments")
                if(payload.detail_id){
                    dispatch("invoice/fetchInvoicePaymentByInvoice", payload.detail_id, { root: true })
                }

               

                return response
            } catch (e) {
                commit('SUBMIT_ERROR', true);
            } finally {
                commit('SUBMIT_LOADING', false);
            }
        },

        async deleteInvoicePayments ({dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.delete(V1API.delete_invoice_payments + payload, config).then(result=>{
                let data = result.data
                return data
            })

            dispatch('fetchInvoicePayments');

            return response
        },
    },

    getters: {}
}
