<template>
<div>
    <Breadcrumbs main="HR" submain="Training Management" title="Trainer" />
    <!-- Container-fluid starts-->
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card">

                    <div class="card-header">
                        <button class="btn btn-primary" type="submit" v-b-modal.modal-prevent-closing style="display: flex; gap: 12px; align-items: center;">
                            <i class="fa fa-light fa-plus"></i>
                            <h7>Add New Trainer</h7>
                        </button>
                    </div>

                    <div class="card-body">
                        <b-row>
                            <b-col xl="6">

                                <b-input-group class="datatable-btn">
                                    <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
                                    <b-input-group-append>
                                        <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                    </b-input-group-append>
                                </b-input-group>

                            </b-col>

                            <b-col xl="6">
                                <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                                    <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <div v-if="!loading && !error">
                            <div class="table-responsive datatable-vue">
                                <b-table show-empty stacked="md" :items="trainer_list" :fields="tablefields" :filter="filter" :per-page="perPage" @filtered="onFiltered">

                                    <template #cell(index)="data">
                                        {{ data.index + 1 }}
                                    </template>

                                    <template #cell(status)="data">
                                        <span v-if="data.item.status == true" @click="updateTrainer(data.item.id)" style="cursor: pointer;">
                                            <b-badge pill variant="success">Active</b-badge>
                                        </span>
                                        <span v-if="data.item.status == false" @click="updateTrainer(data.item.id)" style="cursor: pointer;">
                                            <b-badge pill variant="danger">Inactive</b-badge>
                                        </span>
                                    </template>

                                    <template #cell(action)="data">
                                        <span style="display: flex; gap: 12px;">
                                            <feather type="delete" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover v-b-modal.modal-center @click="deleteTrainer(data.item.id)" title="Delete"></feather>
                                            <div @click="editType(data.item)">
                                                <feather type="edit" size="20px" style="color: #568ae3; cursor: pointer;" v-b-tooltip.hover title="Edit" v-b-modal.modal-prevent-closing-edit></feather>
                                            </div>
                                        </span>
                                    </template>
                                </b-table>
                            </div>
                        </div>
                        <div v-else>
                            <div class="loader-box">
                                <div class="loader-10"></div>
                            </div>
                        </div>

                        <b-col md="6" class="my-1 p-0">
                            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" class="my-0"></b-pagination>
                        </b-col>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Container-fluid Ends-->

    <!-- Training Type Create Modal -->
    <b-modal id="modal-prevent-closing" ref="modal" title="Add Trainer" @show="resetModal" @hidden="resetModal" @ok="handleOk">
        <form ref="form" @submit.stop.prevent="handleSubmit">
            <b-form-group label="Name" label-for="name-input">
                <b-form-input v-model="trainer.name" placeholder="Enter trainer name"></b-form-input>
            </b-form-group>
            <b-form-group label="Contact No" label-for="name-input">
                <b-form-input v-model="trainer.contact_number" placeholder="Enter trainer contact no"></b-form-input>
            </b-form-group>
            <b-form-group label="Email" label-for="name-input">
                <b-form-input v-model="trainer.email" placeholder="Enter trainer email"></b-form-input>
            </b-form-group>

            <b-form-group label="Description" label-for="name-input">
                <b-form-textarea id="textarea-auto-height" rows="3" max-rows="8" v-model="trainer.description" placeholder="Enter trainer description"></b-form-textarea>
            </b-form-group>

            <b-form-group label="Status" label-for="name-input">
                <b-form-select v-model="trainer.status" :options="statusOptions"></b-form-select>
            </b-form-group>
        </form>
    </b-modal>

    <!-- Training Type Edit Modal -->
    <b-modal id="modal-prevent-closing-edit" ref="modal" title="Edit Trainer" @show="resetEditModal" @hidden="resetEditModal" @ok="handleEditOk">
        <form ref="form" @submit.stop.prevent="handleEditSubmit">
            <b-form-group label="Name" label-for="name-input">
                <b-form-input v-model="edit_trainer.name" placeholder="Enter trainer name"></b-form-input>
            </b-form-group>
            <b-form-group label="Contact No" label-for="name-input">
                <b-form-input v-model="edit_trainer.contact_number" placeholder="Enter trainer contact no"></b-form-input>
            </b-form-group>
            <b-form-group label="Email" label-for="name-input">
                <b-form-input v-model="edit_trainer.email" placeholder="Enter trainer email"></b-form-input>
            </b-form-group>

            <b-form-group label="Description" label-for="name-input">
                <b-form-textarea id="textarea-auto-height" rows="3" max-rows="8" v-model="edit_trainer.description" placeholder="Enter trainer description"></b-form-textarea>
            </b-form-group>

            <b-form-group label="Status" label-for="name-input">
                <b-form-select v-model="edit_trainer.status" :options="statusOptions"></b-form-select>
            </b-form-group>
        </form>
    </b-modal>

    <!-- Delete Modal -->
    <b-modal id="modal-center" centered hide-header @ok="handleDeleteOk">
        <div class="mt-4 mb-4" style="font-size: large;">Are you sure you want to delete this trainer?</div>
    </b-modal>
</div>
</template>

<script>
import {
    mapState
} from 'vuex';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';

export default {
    data() {
        return {
            tablefields: [{
                    key: 'index',
                    label: '#'
                },
                {
                    key: 'name',
                    label: 'Name',
                    sortable: true
                },
                {
                    key: 'contact_number',
                    label: 'Contact Number',
                    sortable: true
                },
                {
                    key: 'email',
                    label: 'Email',
                    sortable: true
                },
                {
                    key: 'status',
                    label: 'Status',
                    sortable: true
                },
                {
                    key: 'action',
                    label: 'Action',
                    sortable: false
                }
            ],

            statusOptions: [{
                    text: 'Active',
                    value: true
                },
                {
                    text: 'Inactive',
                    value: false
                }
            ],

            trainer: {
                name: '',
                contact_number: '',
                email: '',
                description: '',
                status: '',

            },

            edit_trainer: {
                id: '',
                name: '',
                contact_number: '',
                email: '',
                description: '',
                status: '',
            },

            delete_value: null,
            udate_value: null,

            filter: null,

            totalRows: 1,
            currentPage: 1,
            perPage: 25,
            pageOptions: [25, 50, 100],

            temp_quotation: {}
        };
    },

    computed: {
        ...mapState({
            trainer_list: state => state.training.trainer,
            count: state => state.training.trainer_count,
            loading: state => state.training.trainingLoading,
            error: state => state.training.trainingError,

        }),
        sortOptions() {
            return this.tablefields
                .filter(f => f.sortable)
                .map(f => {
                    return {
                        text: f.label,
                        value: f.key
                    };
                });
        }
    },

    watch: {
        currentPage(newCurrentPage) {
            this.$router.replace({
                path: '/hr/training-management/trainer/list',
                query: {
                    page: newCurrentPage,
                    perPage: this.perPage
                },
            });
            this.currentPage = newCurrentPage
            this.$store.dispatch("training/fetchTrainer", {
                perPage: this.perPage,
                currentPage: newCurrentPage
            }).then(() => {
                this.totalRows = this.count;
            });

        },
        perPage(newperPage) {
            this.$router.replace({
                path: '/hr/training-management/trainer/list',
                query: {
                    page: this.currentPage,
                    perPage: newperPage
                },
            });
            this.perPage = newperPage
            this.$store.dispatch("training/fetchTrainer", {
                perPage: newperPage,
                currentPage: this.currentPage
            }).then(() => {
                this.totalRows = this.count;
            });

        },
    },

    mounted() {

        if (!this.$route.query.page || !this.$route.query.perPage) {
            this.$router.replace({
                path: this.$route.path,
                query: {
                    page: this.currentPage,
                    perPage: this.perPage,
                },
            });

            this.$store.dispatch("training/fetchTrainer", {
                perPage: this.perPage,
                currentPage: this.currentPage
            }).then(() => {
                this.totalRows = this.count;
            });

        } else {
            this.$router.replace({
                path: this.$route.path,
                query: {
                    page: this.$route.query.page,
                    perPage: this.$route.query.perPage,
                },
            });
            this.currentPage = this.$route.query.page
            this.perPage = this.$route.query.perPage

            this.$store.dispatch("training/fetchTrainer", {
                currentPage: this.$route.query.page,
                perPage: this.$route.query.perPage,

            }).then(() => {
                this.totalRows = this.count;
            });

        }

    },

    methods: {
        onFiltered(filteredItems) {

            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },

        resetModal() {
            this.trainer.name = '',
                this.trainer.contact_number = '',
                this.trainer.email = '',
                this.trainer.description = '',
                this.trainer.status = ''
        },

        handleOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleSubmit()
        },

        handleSubmit() {
            this.$store.dispatch("training/addTrainer", this.trainer).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.trainer.name = '',
                        this.trainer.contact_number = '',
                        this.trainer.email = '',
                        this.trainer.description = '',
                        this.trainer.status = ''

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })

            this.$nextTick(() => {
                this.$bvModal.hide('modal-prevent-closing')
            })
        },

        editType(type) {
            this.edit_trainer.id = type.id
            this.edit_trainer.name = type.name
            this.edit_trainer.contact_number = type.contact_number
            this.edit_trainer.email = type.email
            this.edit_trainer.description = type.description
            this.edit_trainer.status = type.status

        },

        resetEditModal() {
            this.edit_trainer.name = ''
            this.edit_trainer.contact_number = ''
            this.edit_trainer.email = ''
            this.edit_trainer.description = ''
            this.edit_trainer.status = ''
        },

        handleEditOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleEditSubmit()
        },

        handleEditSubmit() {
            this.$store.dispatch("training/updateTrainer", {

                id: this.edit_trainer.id,
                name: this.edit_trainer.name,
                contact_number: this.edit_trainer.contact_number,
                email: this.edit_trainer.email,
                description: this.edit_trainer.description,
                status: this.edit_trainer.status

            }).then(response => {

                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.edit_trainer.name = ''
                    this.edit_trainer.contact_number = ''
                    this.edit_trainer.email = ''
                    this.edit_trainer.description = ''
                    this.edit_trainer.status = ''

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
            this.$nextTick(() => {
                this.$bvModal.hide('modal-prevent-closing-edit')
            })
        },
        deleteTrainer(id) {
            this.delete_value = id
        },
        handleDeleteOk() {
            this.$store.dispatch("training/deleteTrainer", this.delete_value).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.delete_value = null

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
        },

        updateTrainer(id) {
            this.udate_value = id
            this.$store.dispatch("training/updateTrainerStatus", this.udate_value).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.udate_value = null

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
        },
    }
};
</script>
