<template>
<div>
    <Breadcrumbs main="My Workspace" title="My Contact" />
    <!-- Container-fluid starts-->
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card">

                    <div class="card-body">

                        <b-row>
                            <b-col xl="6">

                                <b-input-group class="datatable-btn">
                                    <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
                                    <b-input-group-append>
                                        <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                    </b-input-group-append>
                                </b-input-group>

                            </b-col>

                            <b-col xl="6">
                                <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                                    <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <div v-if="!loading && !error">
                            <div class="table-responsive datatable-vue">
                                <b-table show-empty stacked="md" :items="contact_list" :fields="tablefields" :filter="filter" :per-page="perPage" @filtered="onFiltered">
                                    <template #cell(index)="data">
                                        {{ (currentPage - 1) * perPage + data.index + 1 }}
                                    </template>

                                    <template #cell(typeOfLeave)="data">
                                        <div>
                                            <p v-b-tooltip.hover title="Details" v-b-modal.modal-prevent-closing-edit>{{ data.item.leave_type.name}}</p>
                                        </div>

                                    </template>

                                    <template #cell(photo)="data">
                                        <img :src="host+ data.item.photo" alt="" class="img-60 rounded-circle" style="object-fit: cover;">
                                    </template>

                                    <template #cell(user_name)="data">
                                        <span style="display: flex; flex-direction: column; gap: 4px;">
                                            <h7>{{data.item.user_name}}</h7>
                                            <small><span style="font-size: medium;">
                                                    <b-badge pill variant="primary">
                                                        <a :href="`mailto:${data.item.email}`" class="text-white">
                                                            {{data.item.email}}
                                                        </a>
                                                    </b-badge>
                                                </span></small>
                                        </span>
                                    </template>

                                    <template #cell(phone_number)="data">
                                        <h7 v-if="data.item.phone_number">
                                            <a :href="`tel:${data.item.phone_number}`">
                                                {{ data.item.phone_number }}
                                            </a>
                                        </h7>
                                        <h7 v-else> N/A </h7>
                                    </template>

                                    <template #cell(address)="data">
                                        <h7 v-if="data.item.address">{{ data.item.address }}</h7>
                                        <h7 v-else> N/A </h7>
                                    </template>

                                </b-table>
                            </div>
                        </div>
                        <div v-else>
                            <div class="loader-box">
                                <div class="loader-10"></div>
                            </div>
                        </div>

                        <b-col md="6" class="my-1 p-0">
                            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" class="my-0"></b-pagination>
                        </b-col>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Container-fluid Ends-->

</div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import moment from "moment";
import {
    mapState
} from 'vuex';
import Multiselect from 'vue-multiselect';
import {
    myHost
} from '../../../_helpers/host';

export default {
    components: {
        Datepicker,
        Multiselect
    },

    data() {
        return {
            host: myHost.host,
            tablefields: [{
                    key: 'index',
                    label: '#'
                },
                {
                    key: 'photo',
                    label: 'Picture',
                    sortable: true
                },
                {
                    key: 'user_name',
                    label: 'Name',
                    sortable: true
                },
                {
                    key: 'phone_number',
                    label: 'Phone Number',
                    sortable: true
                },

                {
                    key: 'address',
                    label: 'Address',
                    sortable: true
                },

            ],

            filter: null,
            leave_details: {
                id: '',
                leave_type: '',
                reason: '',
                start_date: '',
                end_date: '',
                refer_to: [],
                status: '',
            },
            assignedMembers: [],
            my_reply: {
                id: '',
                reply: '',
                refer_to: '',
            },
            temp_id: '',

            totalRows: 1,
            currentPage: 1,
            perPage: 25,
            pageOptions: [25, 50, 100],

            temp_quotation: {}
        };
    },

    watch: {
        assignedMembers: {
            handler: function (newVal, oldVal) {
                this.storeAssignedMembers(newVal);
            },
            deep: true
        },
        currentPage(newCurrentPage) {
            this.$router.replace({
                path: '/my-workspace/my-contact',
                query: {
                    page: newCurrentPage,
                    perPage: this.perPage
                },
            });
            this.currentPage = newCurrentPage
            this.$store.dispatch("myUpdates/fetchMyContact", {
                perPage: this.perPage,
                currentPage: newCurrentPage
            }).then(() => {
                this.totalRows = this.count;
            });

        },
        perPage(newperPage) {
            this.$router.replace({
                path: '/my-workspace/my-contact',
                query: {
                    page: this.currentPage,
                    perPage: newperPage
                },
            });
            this.perPage = newperPage
            this.$store.dispatch("myUpdates/fetchMyContact", {
                perPage: newperPage,
                currentPage: this.currentPage
            }).then(() => {
                this.totalRows = this.count;
            });

        },

    },

    computed: {
        ...mapState({
            contact_list: (state) => state.myUpdates.my_contact,
            count: (state) => state.myUpdates.count,
            loading: (state) => state.myUpdates.submitLoading,
            error: (state) => state.myUpdates.submitError,

        }),
        sortOptions() {
            // Create an options list from our fields
            return this.tablefields
                .filter(f => f.sortable)
                .map(f => {
                    return {
                        text: f.label,
                        value: f.key
                    };
                });
        }
    },

    mounted() {
        if (!this.$route.query.page || !this.$route.query.perPage) {
            this.$router.replace({
                path: this.$route.path,
                query: {
                    page: this.currentPage,
                    perPage: this.perPage,
                },
            });

            this.$store.dispatch("myUpdates/fetchMyContact", {
                perPage: this.perPage,
                currentPage: this.currentPage
            }).then(() => {
                this.totalRows = this.count;
            });

        } else {
            this.$router.replace({
                path: this.$route.path,
                query: {
                    page: this.$route.query.page,
                    perPage: this.$route.query.perPage,
                },
            });
            this.currentPage = this.$route.query.page
            this.perPage = this.$route.query.perPage

            this.$store.dispatch("myUpdates/fetchMyContact", {
                currentPage: this.$route.query.page,
                perPage: this.$route.query.perPage,

            }).then(() => {
                this.totalRows = this.count;
            });

        }

    },

    methods: {

        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        }
    }
};
</script>

<style scoped>
.badge-style {
    display: flex;
    justify-content: space-between;
    align-items: center;
    justify-items: center;
    background-color: #2c323f;
    padding: 0.375rem 1.75rem;
    color: white;

}

.badge-style:hover {
    background-color: #ff595d;
}

.chatbox {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 0px 10px;
}

.chat {
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
    word-break: break-all;
}

.avatar img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    border: solid 2px white;
}

.message {
    flex-grow: 1;
}

.img-60 {
    width: 60px !important;
    height: 60px !important;
}

.msg {
    background: #fff0f0;
    width: 100%;
    /* Ensures message takes full width */
    padding: 10px;
    border-radius: 10px;
    position: relative;
    font-size: 13px;
}

.task-board-comments {
    height: 450px;
    overflow-y: auto;
}

/* height */
::-webkit-scrollbar {
    height: 6px;
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}
</style>
