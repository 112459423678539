<template>
<div>
    <Breadcrumbs main="HR" title="Employee Create" />
    <!-- Container-fluid starts-->
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12">
                <px-card>
                    <div slot="with-padding">
                        <div class="form theme-form">

                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Employee First Name</label>
                                        <input class="form-control" type="text" placeholder="Enter employee first name" v-model="employee.first_name">
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <div class="form-group">
                                            <label>Employee Last Name</label>
                                            <input class="form-control" type="text" placeholder="Enter employee last name" v-model="employee.last_name">
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Employee Email</label>
                                        <input class="form-control" type="text" placeholder="Enter employee first name" v-model="employee.email">
                                    </div>
                                </div>

                            </div>
                            <h6 class="my-4">Additional Information</h6>

                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Employee Phone No.</label>
                                        <input class="form-control" type="text" placeholder="Enter phone no" v-model="employee.additional_info.phone_number">
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Joining Date</label>
                                        <datepicker placeholder="Select a date" input-class="datepicker-here form-control digits" v-model="employee.additional_info.joining_date"></datepicker>
                                    </div>
                                </div>
                            </div>

                            <div class="row">

                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Department</label>
                                        <select class="form-control digits" v-model="employee.additional_info.department">
                                            <option v-for="(type, index) in department_list" :key="index" :value="type.id">{{ type.name }}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Designation</label>
                                        <select class="form-control digits" v-model="employee.additional_info.designation">
                                            <option v-for="(type, index) in designation_list_by_department" :key="index" :value="type.id">{{ type.title }}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Personal ID No.</label>
                                        <input class="form-control" type="text" placeholder="Enter personal ID" v-model="employee.additional_info.personal_id_no">
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Address</label>
                                        <input class="form-control" type="text" placeholder="Enter address" v-model="employee.additional_info.address">
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Blood Group</label>
                                        <input class="form-control" type="text" placeholder="Enter blood group" v-model="employee.additional_info.blood_group">
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Date of Birth</label>
                                        <datepicker placeholder="Select a date" input-class="datepicker-here form-control digits" v-model="employee.additional_info.date_of_birth"></datepicker>
                                    </div>
                                </div>
                            </div>

                            <div class="row">

                                <div class="col">
                                    <label>Upload Photo (Ratio: 1:1, Max-size: 3MB)</label>
                                    <div class="flex items-center">
                                        <img v-if="employee.additional_info.photo" style="height: 100px; width: 100px;" :src="employee.additional_info.photo">
                                        <input :class="employee.additional_info.photo ? 'ml-4' : 'ml-0'" type="file" accept="image/*" @change="uploadFile">
                                    </div>
                                </div>
                            </div>

                            <div class="row my-5">
                                <div class="col">
                                    <div class="form-group mb-0">
                                        <button @click="createEmployee()" class="btn btn-success mr-3">Submit</button>
                                        <router-link to="/hr/organization/employee">
                                            <button class="btn btn-danger" href="#">Go Back</button>
                                        </router-link>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </px-card>
            </div>
        </div>
    </div>

    <!-- Container-fluid Ends-->
</div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';

import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import moment from "moment";
import Multiselect from 'vue-multiselect';
import {
    myHost
} from "../../../../_helpers/host";
import {
    mapState
} from 'vuex';

export default {

    components: {
        vueDropzone: vue2Dropzone,
        Datepicker,
        Multiselect
    },

    data() {
        return {
            host: myHost.host,
            employee: {
                first_name: '',
                last_name: '',
                email: '',

                additional_info: {
                    phone_number:null,
                    blood_group: null,
                    joining_date: null,
                    department: null,
                    designation: null,
                    address: null,
                    personal_id_no: null,
                    date_of_birth: null,
                    photo: null,

                }

            },

        };
    },
    watch: {
        'employee.first_name'(newVal) {
            if (!newVal.trim()) {
                this.employee.first_name = null;
            }
        },
        'employee.last_name'(newVal) {
            if (!newVal.trim()) {
                this.employee.last_name = null;
            }
        },
        'employee.additional_info': {
            handler(newVal) {
                const isEmpty = Object.values(newVal).every(value => value === '');
                if (isEmpty) {
                    for (const key in newVal) {
                        this.employee.additional_info[key] = null;
                    }
                }
            },
            deep: true
        },
        'employee.additional_info.department': function (newDepartmentId, oldDepartmentId) {

            if (newDepartmentId) {
                this.$store.dispatch("designation/fetchDesignationsbyDepartment", newDepartmentId);
            }
        }
    },

    methods: {
        uploadFile(e) {
            const image = e.target.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(image);
            reader.onload = e => {
                this.employee.additional_info.photo = e.target.result;
            };
        },
        createEmployee() {
            if (this.employee.additional_info.joining_date !== null) {
                this.employee.additional_info.joining_date = moment(String(this.employee.additional_info.joining_date)).format("YYYY-MM-DD");
            }
            if (this.employee.additional_info.date_of_birth !== null) {
                this.employee.additional_info.date_of_birth = moment(String(this.employee.additional_info.date_of_birth)).format("YYYY-MM-DD");
            }

            this.$store.dispatch("employee/addEmployee", this.employee).then(response => {
                if (response.code == 200) {

                    // alert(JSON.stringify(response.user_info.id))
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.employee.first_name = '',
                        this.employee.last_name = '',
                        this.employee.email = null,
                        this.employee.additional_info.blood_group = '',
                        this.employee.additional_info.joining_date = null,
                        this.employee.additional_info.department = '',
                        this.employee.additional_info.designation = '',
                        this.employee.additional_info.phone_number = '',
                        this.employee.additional_info.address = '',
                        this.employee.additional_info.personal_id_no = '',
                        this.employee.additional_info.date_of_birth = null,
                        this.employee.additional_info.photo = null,

                        // this.$router.push('/hr/organization/employee') 

                        setTimeout(() => {
                            this.$router.push(`/hr/organization/employee/details/${response.user_info.id}`)
                        }, 1000)

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
        }
    },
    computed: {
        ...mapState({
            department_list: state => state.department.departments,
            designation_list: state => state.designation.designation,
            designation_list_by_department: state => state.designation.designation_by_department,
        }),

    },
    mounted() {
        this.$store.dispatch("department/fetchDepartments")
        this.$store.dispatch("designation/fetchDesignations")

    },

};
</script>

<style scoped>
.task-board {
    width: 100%;
    overflow-x: auto;
    white-space: nowrap;
}

::-webkit-scrollbar {
    height: 5px;
    width: 8px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
    background: #555;
}
</style>
