<template>
    <div>
        <Breadcrumbs main="Vendor"  title="Purchase Orders"/>
        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">

            
                        <div class="card-body">
                            <b-row>
                                <b-col xl="6">
                
                                    <b-input-group class="datatable-btn">
                                        <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
                                        <b-input-group-append>
                                            <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                        </b-input-group-append>
                                    </b-input-group>
                
                                </b-col>
                
                                <b-col xl="6">
                                    <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                                        <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                                    </b-form-group>
                                </b-col>
                            </b-row>
            
                            <div class="table-responsive datatable-vue">
                                <b-table
                                    show-empty
                                    stacked="md"
                                    :items="items"
                                    :fields="tablefields"
                                    :filter="filter"
                                    :current-page="currentPage"
                                    :per-page="perPage"
                                    @filtered="onFiltered"
                                >
                                    <template #cell(index)="data">
                                        {{ data.index + 1 }}
                                    </template>

                                    <template #cell(purchase_order_id)="data">
                                        <span>
                                            <router-link :to="`/finance-and-accounts/finance/purchase-order/${data.item.id}`" class="link-style">
                                                {{ data.item.purchase_order_id }}
                                            </router-link>
                                        </span>
                                    </template>

                                    <template #cell(subtotal)="data">
                                        <span>
                                            {{ data.item.subtotal }} {{ data.item.currency }}
                                        </span>
                                    </template>

                               
                                </b-table>
                            </div>
            
                            <b-col md="6" class="my-1 p-0">
                                <b-pagination
                                    v-model="currentPage"
                                    :total-rows="totalRows"
                                    :per-page="perPage"
                                    class="my-0"
                                ></b-pagination>
                            </b-col>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Container-fluid Ends-->

        <!-- Delete Modal -->
        <b-modal 
            id="modal-center" 
            centered 
            hide-header
            @ok="handleDeleteOk"
        >
            <div class="mt-4 mb-4" style="font-size: large;">Are you sure you want to delete this purchase order?</div>
        </b-modal>
    </div>
</template>
  
<script>
import { mapState } from 'vuex';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';

export default {
    data() {
        return {
            tablefields: [
                { key: 'index', label: '#' },
                { key: 'purchase_order_id', label: 'Purchase Order ID', sortable: true },
                { key: 'email', label: 'Email', sortable: true },
                { key: 'purchase_order_date', label: 'Purchase Order Date', sortable: true },
                { key: 'due_date', label: 'Due Date', sortable: true },
                { key: 'subtotal', label: 'Grand Total', sortable: true },
              
            ],
    
            filter: null,
            delete_value: null,
    
            totalRows: 1,
            currentPage: 1,
            perPage: 25,
            pageOptions: [25, 50, 100]
        };
    },

    computed: {
        ...mapState({
            items: state => state.vendor.purchase_order_list
        }),

        sortOptions() {
            return this.tablefields
                .filter(f => f.sortable)
                .map(f => {
                return {text: f.label, value: f.key};
            });
        }
    },

    mounted() {
        this.$store.dispatch("vendor/fetchVendorPurchaseOrder")
        this.totalRows = this.items.length;
    },

    methods: {
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },


    }
};
</script>
  