<template>
<div>
    <Breadcrumbs main="Project" submain="Vendor" title="Project Details" />

    <!-- Container-fluid starts-->
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-header title-button-container">
                        <div style="display: flex; flex-direction: column; gap: 10px;">
                            <div style="display: flex; flex-direction: row; gap: 10px;">
                                <h5>{{ details.title }}</h5>

                            </div>

                        </div>

                    </div>

                    <div class="card-body">
                        <div v-html="details.details"></div>

                        <div>
                            <div class="row">
                                <div class="mt-4 col col-lg-8">

                                    <div style="display: flex; flex-direction: row; align-items: center; margin-top: 4px;">
                                        <div style="width: 140px;">
                                            <span>Priority</span>
                                        </div>
                                        <span v-if="details.priority == 'MEDIUM'" class="badge" :class="'badge-primary'">{{ details.priority }}</span>
                                        <span v-if="details.priority == 'LOW'" class="badge" :class="'badge-danger'">{{ details.priority }}</span>
                                        <span v-if="details.priority == 'HIGH'" class="badge" :class="'badge-success'">{{ details.priority }}</span>
                                    </div>

                                    <div style="display: flex; flex-direction: row; align-items: center; margin-top: 4px;">
                                        <div style="width: 140px;">
                                            <span>Start Date</span>
                                        </div>
                                        <div style="font-size: medium;">{{ details.start_date }}</div>
                                    </div>

                                    <div style="display: flex; flex-direction: row; align-items: center; margin-top: 4px;">
                                        <div style="width: 140px;">
                                            <span>Deadline</span>
                                        </div>
                                        <div style="font-size: medium;">{{ details.deadline }}</div>
                                    </div>

                                    <!-- <div style="display: flex; flex-direction: row; align-items: center; margin-top: 4px; margin-bottom: 4px;">
                                        <div style="width: 140px;">
                                            <span>Project Lead</span>

                                        </div>

                                    </div> -->

                                </div>
                                <div class="mt-4 col col-lg-4">
                                    <div>
                                        <div class="d-flex justify-content-between ">
                                            <h6 class="mb-3">Supporting Documents</h6>
                                        </div>
                                        <div class="table-responsive datatable-vue">

                                            <b-table show-empty sticky-header :items="details.project_attach" :fields="projectAttachment" head-variant="light">
                                                <template #cell(index)="data">
                                                    {{ data.index + 1 }}
                                                </template>

                                                <template #cell(title)="data">
                                                    <a :href="host+data.item.project_attachment">{{ truncateText(data.item.attach_title, 20) }}</a>
                                                </template>

                                            </b-table>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-body">
                        <div style="display: flex; flex-direction: row; gap: 10px; cursor: pointer;">

                            <h7 @click="toggleItem('Po')" :class="{ active: activeItem === 'Po' }">Purchase Order</h7>
                            <h7 @click="toggleItem('Bill')" :class="{ active: activeItem === 'Bill' }">Bill</h7>

                        </div>
                    </div>

                    <div class="card" v-if="activeItem === 'Po'">

                        <div class="card-body">
                            <b-row>
                                <b-col xl="6">

                                    <b-input-group class="datatable-btn">
                                        <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
                                        <b-input-group-append>
                                            <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                        </b-input-group-append>
                                    </b-input-group>

                                </b-col>

                                <b-col xl="6">
                                    <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                                        <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                                    </b-form-group>
                                </b-col>
                            </b-row>

                            <div class="table-responsive datatable-vue">
                                <b-table show-empty stacked="md" :items="po_list" :fields="tablefieldsPurchaseOrder" :filter="filter" :current-page="currentPage" :per-page="perPage" @filtered="onFiltered">
                                    <template #cell(index)="data">
                                        {{ data.index + 1 }}
                                    </template>

                                    <template #cell(purchase_order_id)="data">
                                        <router-link :to="`/finance-and-accounts/finance/purchase-order/${data.item.id}`" class="link-style">{{data.item.purchase_order_id}}</router-link>
                                    </template>

                                </b-table>
                            </div>

                            <b-col md="6" class="my-1 p-0">
                                <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" class="my-0"></b-pagination>
                            </b-col>
                        </div>
                    </div>
                    <div class="card" v-if="activeItem === 'Bill'">
                        <div class="card-body">
                            <b-row>
                                <b-col xl="6">

                                    <b-input-group class="datatable-btn">
                                        <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
                                        <b-input-group-append>
                                            <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                        </b-input-group-append>
                                    </b-input-group>

                                </b-col>

                                <b-col xl="6">
                                    <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                                        <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                                    </b-form-group>
                                </b-col>
                            </b-row>

                            <div class="table-responsive datatable-vue">
                                <b-table show-empty stacked="md" :items="bill_list" :fields="tablefieldsBill" :filter="filter" :current-page="currentPage" :per-page="perPage" @filtered="onFiltered">

                                    <template #cell(index)="data">
                                        {{ data.index + 1 }}
                                    </template>

                                    <template #cell(bill_id)="data">
                                        <router-link :to="`/finance-and-accounts/finance/bill/${data.item.id}`" class="link-style">{{data.item.bill_id}}</router-link>
                                    </template>

                                </b-table>
                            </div>

                            <b-col md="6" class="my-1 p-0">
                                <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" class="my-0"></b-pagination>
                            </b-col>
                        </div>
                    </div>

                </div>
            </div>
        </div>

    </div>
</div>
</template>

<script>
import {
    mapState
} from 'vuex';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';

export default {
    data() {
        return {
            activeItem: 'Po',

            tablefieldsPurchaseOrder: [{
                    key: 'index',
                    label: '#'
                },
                {
                    key: 'purchase_order_id',
                    label: 'Purchase Order ID',
                    sortable: true,
                },
                {
                    key: 'purchase_order_date',
                    label: 'Purchase Order Date',
                    sortable: true,
                },

                {
                    key: 'subtotal',
                    label: 'Subtotal',
                    sortable: true
                },
                {
                    key: 'total',
                    label: 'Total',
                    sortable: true
                },

            ],
            tablefieldsBill: [{
                    key: 'index',
                    label: '#'
                },
                {
                    key: 'bill_id',
                    label: 'Bill ID',
                    sortable: true
                },
                {
                    key: 'email',
                    label: 'Email',
                    sortable: true
                },
                {
                    key: 'bill_date',
                    label: 'Bill Date',
                    sortable: true
                },
                {
                    key: 'due_date',
                    label: 'Due Date',
                    sortable: true
                },
                {
                    key: 'subtotal',
                    label: 'Grand Total',
                    sortable: true
                },

            ],
            projectAttachment: [{
                    key: 'index',
                    label: '#'
                },
                {
                    key: 'title',
                    label: 'Title',
                    sortable: false
                },

            ],

            filter: null,

            totalRows: 1,
            currentPage: 1,
            perPage: 25,
            pageOptions: [25, 50, 100],

        };
    },
    computed: {
        ...mapState({
            details: state => state.vendor.vendor_project_detail,
            po_list: state => state.vendor.vendor_po_by_project,
            bill_list: state => state.vendor.vendor_bill_by_project

        }),

        sortOptions() {
            return this.tablefields
            s.filter(f => f.sortable)
                .map(f => {
                    return {
                        text: f.label,
                        value: f.key
                    };
                });
        }
    },
    mounted() {
        this.$store.dispatch("vendor/fetchVendorProjectDetails", this.$route.params.id).then(() => {
            this.$store.dispatch("vendor/fetchVendorPurchaseOrderbyProject", this.details.id)
            this.$store.dispatch("vendor/fetchVendorBillbyProject", this.details.id)
        });

    },
    methods: {
        truncateText(text, maxLength) {
            if (text.length > maxLength) {
                return text.substring(0, maxLength) + '...';
            } else {
                return text;
            }
        },
        toggleItem(item) {
            this.activeItem = item;
        },
    }
}
</script>

<style scoped>
.active {
    color: #ff595d;
}
</style>
