<template>
<div>
    <Breadcrumbs main="HR" title="Designations" /> 
    <!-- Container-fluid starts-->
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card">

                    <div class="card-header">
                        <button class="btn btn-primary" type="submit" v-b-modal.modal-prevent-closing style="display: flex; gap: 12px; align-items: center;">
                            <i class="fa fa-light fa-plus"></i>
                            <h7>Add New Designation</h7>
                        </button>
                    </div>

                    <div class="card-body">
                        <b-row>
                            <b-col xl="6">

                                <b-input-group class="datatable-btn">
                                    <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
                                    <b-input-group-append>
                                        <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                    </b-input-group-append>
                                </b-input-group>

                            </b-col>

                            <b-col xl="6">
                                <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                                    <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <div v-if="!loading && !error">
                            <div class="table-responsive datatable-vue">
                                <b-table show-empty stacked="md" :items="designation_list" :fields="tablefields" :filter="filter" :current-page="currentPage" :per-page="perPage" @filtered="onFiltered">
                                    <template #cell(index)="data">
                                        {{ data.index + 1 }}
                                    </template>
                                    <template #cell(permissions)="data">
                                        <router-link :to="`designation/details/${data.item.id}`"> <span v-b-tooltip.hover title="Permission Details"><small><span style="font-size: medium;">
                                                        <b-badge pill variant="dark">Details</b-badge>
                                                    </span></small></span></router-link>
                                    </template>
                                    <template #cell(action)="data">
                                        <span style="display: flex; gap: 12px;">

                                            <div>
                                                <feather type="delete" size="18px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover v-b-modal.modal-center @click="deleteDesignation(data.item.id)" title="Delete"></feather>
                                            </div>

                                            <div @click="editDesigantion(data.item)">
                                                <feather type="edit" size="20px" style="color: #568ae3; cursor: pointer;" v-b-tooltip.hover title="Edit" v-b-modal.modal-prevent-closing-edit></feather>
                                            </div>
                                        </span>
                                    </template>
                                </b-table>
                            </div>
                        </div>

                        <div v-else>
                            <div class="loader-box">
                                <div class="loader-10"></div>
                            </div>
                        </div>

                        <b-col md="6" class="my-1 p-0">
                            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" class="my-0"></b-pagination>
                        </b-col>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Container-fluid Ends-->

    <!-- Designation Create Modal -->
    <b-modal id="modal-prevent-closing" ref="modal" title="Add Designation" @show="resetModal" @hidden="resetModal" @ok="handleOk">
        <form ref="form" @submit.stop.prevent="handleSubmit()">
            <b-form-group label="Designation Name" label-for="name-input">
                <b-form-input v-model="designation.title" placeholder="Enter designation name"></b-form-input>
            </b-form-group>

            <div class="form-group">
                <label>Department</label>
                <select class="form-control digits" v-model="designation.department">
                    <option v-for="(department, index) in department_list" :key="index" :value="department.id">{{ department.name }}</option>
                </select>
            </div>
        </form>
    </b-modal>

    <!-- Designation Edit Modal -->
    <b-modal id="modal-prevent-closing-edit" ref="modal" title="Edit Designation" @show="resetEditModal" @hidden="resetEditModal" @ok="handleEditOk">
        <form ref="form" @submit.stop.prevent="handleEditSubmit">
            <b-form-group label="Designation Name" label-for="name-input">
                <b-form-input v-model="edit_designation.title" placeholder="Enter designation name"></b-form-input>
            </b-form-group>
            <div class="form-group">
                <label>Department</label>
                <select class="form-control digits" v-model="edit_designation.department">
                    <option v-for="(department, index) in department_list" :key="index" :value="department.id">{{ department.name }}</option>
                </select>
            </div>

        </form>
    </b-modal>
    <!-- Department Delete Modal -->
    <b-modal id="modal-center" centered hide-header @ok="handleDeleteOk">
        <div class="mt-4 mb-4" style="font-size: large;">Are you sure you want to delete this designation?</div>
    </b-modal>
</div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import moment from "moment";
import {
    mapState
} from 'vuex';
export default {
    components: {
        Datepicker
    },

    data() {
        return {
            tablefields: [{
                    key: 'index',
                    label: '#'
                },
                {
                    key: 'title',
                    label: 'Designation',
                    sortable: true
                },
                {
                    key: 'department.name',
                    label: 'Department',
                    sortable: true
                },
                {
                    key: 'permissions',
                    label: 'Permissions',
                    sortable: true
                },
                {
                    key: 'action',
                    label: 'Action',
                    sortable: false
                }
            ],

            designation: {
                title: '',
                department: '',

            },

            edit_designation: {
                id: '',
                title: '',
                department: '',

            },

            delete_value: null,

            filter: null,
            totalRows: 1,
            currentPage: 1,
            perPage: 25,
            pageOptions: [25, 50, 100],

            temp_quotation: {}
        };
    },

    computed: {
        ...mapState({
            department_list: state => state.department.departments,
            designation_list: state => state.designation.designation,

            loading: state => state.designation.designationLoading,
            error: state => state.designation.designationError,

        }),
        sortOptions() {
            // Create an options list from our fields
            return this.tablefields
                .filter(f => f.sortable)
                .map(f => {
                    return {
                        text: f.label,
                        value: f.key
                    };
                });
        },

    },

    mounted() {
        this.$store.dispatch("department/fetchDepartments")
        this.$store.dispatch("designation/fetchDesignations").then(() => {
            this.items = this.designation_list
            this.totalRows = this.items.length;
        });
    },

    methods: {
        onFiltered(filteredItems) {

            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },

        resetModal() {
            this.designation.title = ''
            this.designation.department = ''
        },

        handleOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleSubmit()

        },

        handleSubmit() {

            this.$store.dispatch("designation/addDesignation", this.designation).then(response => {

                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.designation.title = ''
                    this.designation.department = ''

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })

            this.$nextTick(() => {
                this.$bvModal.hide('modal-prevent-closing')
            })
        },

        editDesigantion(type) {
            this.edit_designation.id = type.id
            this.edit_designation.title = type.title
            this.edit_designation.department = type.department.id

        },

        resetEditModal() {
            this.edit_designation.title = ''
            this.edit_designation.department = ''

        },

        handleEditOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleEditSubmit()
        },

        handleEditSubmit() {

            this.$store.dispatch("designation/updateDesignation", {
                id: this.edit_designation.id,
                title: this.edit_designation.title,
                department: this.edit_designation.department

            }).then(response => {
                console.log(this.edit_holiday)
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.edit_designation.title = ''
                    this.edit_designation.department = ''

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })

            this.$nextTick(() => {
                this.$bvModal.hide('modal-prevent-closing-edit')
            })
        },
        deleteDesignation(id) {
            this.delete_value = id
        },
        handleDeleteOk() {
            this.$store.dispatch("designation/deleteDesignation", this.delete_value).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.delete_value = null

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
        }
    }
};
</script>
