<template>
    <div>
        <Breadcrumbs main="Finance & Accounts" submain="Accounts" title="Ledger Detail"/>

        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-12">
                    <div class="card">
                        <div class="card-header">
                          <div class="row">
                            <div class="col-8">
                              <h5>Ledger: {{ledgerDetails.lid }}</h5><br>
                              <h5>{{ ledgerDetails.title }}</h5><br>
                              <p style="font-weight: bold">{{ledgerDetails.group.pre_defined.title }} >> {{ ledgerDetails.group.title}} </p> <br>

                            </div>
                            <div class="col-4">


                              <p style="font-weight: bold">Debit Balance  : {{ balanceDetails.debit}} </p>
                              <p style="font-weight: bold">Credit Balance : {{ balanceDetails.credit}} </p>
                              <span style="border-style: solid; border-width: 0.01rem; border-color:lightgrey" ></span>
                              <div style="font-weight: bold; display: flex; flex-direction: row">Current Balance : <p v-if="balanceDetails.balance<0" >   ({{balanceDetails.balance*-1}}) </p><p v-else> {{balanceDetails.balance}} </p> </div>

                            </div>
                          </div>

                        </div>

                        <div class="card-body">
                            <b-row>
                                <b-col xl="6">

                                    <b-input-group class="datatable-btn">
                                        <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
                                        <b-input-group-append>
                                            <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                        </b-input-group-append>
                                    </b-input-group>

                                </b-col>

                                <b-col xl="6">
                                    <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                                        <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                                    </b-form-group>
                                </b-col>
                            </b-row>

                            <div class="table-responsive datatable-vue">
                                <b-table
                                    show-empty
                                    stacked="md"
                                    :items="items"
                                    :fields="tablefields"
                                    :filter="filter"
                                    :current-page="currentPage"
                                    :per-page="perPage"
                                    @filtered="onFiltered"
                                >
                                    <template #cell(index)="data">
                                        {{ data.index + 1 }}
                                    </template>
                                  <template #cell(transaction_id)="data">
                                        <a v-b-modal.modal-prevent-closing-detail-xl style="display: flex; gap: 12px; align-items: center;" @click="journalDetails(data.item.transaction_id)">{{ data.item.transaction_id }}</a>
                                    </template>
                                  <template #cell(date)="data">
                                        {{ data.item.date.split('T')[0]}}<br>
                                    <small>{{ data.item.date.split('T')[1].split('.')[0] }}</small>
                                    </template>
                                </b-table>
                            </div>

                            <b-col md="6" class="my-1 p-0">
                                <b-pagination
                                    v-model="currentPage"
                                    :total-rows="totalRows"
                                    :per-page="perPage"
                                    class="my-0"
                                ></b-pagination>
                            </b-col>

                        </div>
                    </div>
                </div>
            </div>
        </div>
      <b-modal
          id="modal-prevent-closing-detail-xl"
          size="xl"
          ref="modal"
          title="Journal Details"
          @ok="handleOk"
      >


        <div class="card">
          <div class="card-header">
            <h5>Transaction History of Journal {{jid}}</h5>
          </div>

          <div class="card-body">


            <div class="table-responsive datatable-vue">
              <b-table
                  show-empty
                  stacked="md"
                  sticky-header
                  :items="jItems"
                  :fields="jtablefields"

                  @filtered="onFiltered"
              >
                <template #cell(index)="data">
                  {{ data.item.journal_id}}
                </template>
                <template #cell(date)="data">
                  {{ data.item.date.split('T')[0]}}<br>
                  <small>{{ data.item.date.split('T')[1].split('.')[0] }}</small>
                </template>

                <template #cell(ledger_break_down)="data">
                                        <span style="display: flex; flex-direction: column; ">


                                              <span v-for="ledger in data.item.ledger_break_down.debit" style="display: flex; flex-direction: column">{{ledger.ledger_id.title}}
                                                  <span style="border-style: solid; border-width: 0.01rem; border-color:lightgrey" ></span>
                                              </span>


                                        </span>

                  <span  style="display: flex; flex-direction: column;">

                                                 <span v-for="(ledger,index) in data.item.ledger_break_down.credit" style="display: flex; flex-direction: column">
                                                   <span style="margin-left: 16px;">{{ledger.ledger_id.title}}</span>
                                                        <span v-if =" data.item.ledger_break_down.credit.length-1!==index"style="border-style: solid; border-width: 0.01rem; border-color:lightgrey" ></span>

                                              </span>


                                            </span>

                </template>

                <template #cell(reference)="data">
                                        <span style="display: flex; flex-direction: column;">
                                            <span  v-for="ledger in data.item.ledger_break_down.debit" style="display: flex; flex-direction: column" @click="handleOk">
                                                <router-link :to="{ path: '/finance-and-accounts/account/ledger/' + ledger.ledger_id.lid }">
                                                    {{ ledger.ledger_id.lid }}
                                                </router-link>
                                              <span style="border-style: solid; border-width: 0.01rem; border-color:lightgrey" ></span>

                                            </span>
                                             <span  v-for="(ledger,index) in data.item.ledger_break_down.credit"  style="display: flex; flex-direction: column"  @click="handleOk">
                                                <router-link :to="{ path: '/finance-and-accounts/account/ledger/' + ledger.ledger_id.lid }">
                                                    {{ ledger.ledger_id.lid}}
                                                </router-link>
                                               <span  v-if =" data.item.ledger_break_down.credit.length-1!==index" style="border-style: solid; border-width: 0.01rem; border-color:lightgrey" ></span>
                                            </span>
                                        </span>
                </template>



                <template #cell(debit)="data">
                                        <span style="display: flex; flex-direction: column;">
                                            <span  v-for="ledger in data.item.ledger_break_down.debit" style="display: flex; flex-direction: column;">

                                                    {{ ledger.amount }}

                                                <span style="border-style: solid; border-width: 0.01rem; border-color:lightgrey" ></span>

                                            </span>
                                             <span  v-for="(ledger,index) in data.item.ledger_break_down.credit" style="display:flex;flex-direction: column">
                                               <br>
                                                 <span  v-if =" data.item.ledger_break_down.credit.length-1!==index" style="border-style: solid; border-width: 0.01rem; border-color:lightgrey" ></span>

                                            </span>
                                        </span>
                </template>

                <template #cell(credit)="data">
                                        <span style="display: flex; flex-direction: column;">
                                             <span  v-for="ledger in data.item.ledger_break_down.debit" style="display: flex; flex-direction: column;">

                                                   <br>
                                                <span style="border-style: solid; border-width: 0.01rem; border-color:lightgrey" ></span>


                                            </span>
                                             <span  v-for="(ledger,index) in data.item.ledger_break_down.credit" style="display: flex; flex-direction: column;">
                                                {{ ledger.amount }}
                                               <span v-if =" data.item.ledger_break_down.credit.length-1!==index" style="border-style: solid; border-width: 0.01rem; border-color:lightgrey" ></span>
                                            </span>
                                        </span>
                </template>
                <template #cell(amount)="data">
                                        <span style="display: flex; flex-direction: column;">

                                                {{ data.item.amount }}

                                        </span>
                </template>
                <template #cell(note)="data">
                                        <span>
                                            {{data.item.note}}
                                        </span>
                </template>
              </b-table>
            </div>



          </div>
        </div>
      </b-modal>
        <!-- Container-fluid Ends-->
    </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import {mapState} from "vuex";
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import balanceScale from "vue-rate-it/glyphs/balance-scale";

export default {
    components: {
        Datepicker
    },

    data() {
        return {
            tablefields: [
                { key: 'index', label: '#' },
                { key: 'date', label: 'Date', sortable: true },
                { key: 'transaction_id', label: 'Journal', sortable: true },
                { key: 'debit_amount', label: 'Debit', sortable: true },
                { key: 'credit_amount', label: 'Credit', sortable: true }
            ],
          jtablefields: [
            { key: 'index', label: '#', sortable:true },
            { key: 'date', label: 'Date', sortable: true },
            { key: 'ledger_break_down', label: 'Account Title', sortable: true },
            { key: 'reference', label: 'Reference',  },
            { key: 'debit', label: 'Debit',  },
            { key: 'credit', label: 'Credit', },
            { key: 'amount', label: 'amount', },
            { key: 'note', label: 'Note',  }
          ],

            items: [
                { date: '2024-12-04', journal: '1', debit: '', credit: '33500' },
                { date: '2023-05-08', journal: '6', debit: '', credit: '21650' },
                { date: '2022-01-06', journal: '3', debit: '4980', credit: '' }
            ],
          jItems:[],

            filter: null,
            jid:'hello',

            totalRows: 1,
            currentPage: 1,
            perPage: 25,
            pageOptions: [25, 50, 100]
        };
    },

  computed: {
    ...mapState('account', ["accountTransactions",'ledgerDetails','balanceDetails',"journalTransactions","accountTransactionsLoading","accountTransactionsError","accountCategories", "accountGroups","accountLedgers","accountLedgerLoading","accountLedgerError","accountGroupLoading", "accountGroupError", "accountCategoryLoading", "accountCategoryError", "submitError", "submitSuccess", "submitLoading"]),
    sortOptions() {
      // Create an options list from our fields
      return this.tablefields
          .filter(f => f.sortable)
          .map(f => {
            return {text: f.label, value: f.key};
          });
    }
  },
  created() {
      console.log(this.$route.params.id)
    this.$store.dispatch('account/fetchAccountTransactionsByLedger', this.$route.params.id).then(result => {
      this.items = this.accountTransactions
      this.totalRows = this.items.length;
    })
    this.$store.dispatch('account/fetchAccountLedgerDetails', this.$route.params.id).then(result => {

    })
  },

    mounted() {
        // Set the initial number of items
        this.totalRows = this.items.length;
    },

    methods: {
      balanceScale() {
        return balanceScale
      },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
      handleOk(bvModalEvent) {

          this.$bvModal.hide("modal-prevent-closing-detail-xl")
          this.$router.go(0)
      },
      journalDetails(jid) {
          this.jid=jid
          console.log(jid)
        this.$store.dispatch('account/fetchAccountTransactionsByJid', jid).then(result => {
          let a=[]
          a.push(this.journalTransactions)
          this.jItems=a
        })
            // Trigger pagination to update the number of buttons/pages due to filtering

        }
    }
};
</script>
