<template>
    <div>
        <Breadcrumbs main="Job" title="Question Set"/>
        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header">
                            <button class="btn btn-primary" type="submit">
                                <router-link to="/job/question-set/create" style="display: flex; gap: 12px; align-items: center; color: white;">
                                    <i class="fa fa-light fa-plus"></i> 
                                    <h7>Add New Question Set</h7>
                                </router-link>
                            </button>
                        </div>
            
                        <div class="card-body">
                            <b-row>
                                <b-col xl="6">
                
                                    <b-input-group class="datatable-btn">
                                        <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
                                        <b-input-group-append>
                                            <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                        </b-input-group-append>
                                    </b-input-group>
                
                                </b-col>
                
                                <b-col xl="6">
                                    <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                                        <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                                    </b-form-group>
                                </b-col>
                            </b-row>
            
                            <div class="table-responsive datatable-vue">
                                <b-table
                                    show-empty
                                    stacked="md"
                                    :items="items"
                                    :fields="tablefields"
                                    :filter="filter"
                                    :current-page="currentPage"
                                    :per-page="perPage"
                                    @filtered="onFiltered"
                                >
                                    <template #cell(index)="data">
                                        {{ data.index + 1 }}
                                    </template>

                                    <template #cell(action)="data">
                                        <span style="display: flex; gap: 12px;">
                                            <feather type="delete" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover title="Delete"></feather>
                                            <!-- <router-link :to="`/finance/bill/edit/${data.item.bill_id}`"> -->
                                                <feather type="edit" size="20px" style="color: #568ae3; cursor: pointer;" v-b-tooltip.hover title="Edit"></feather>
                                            <!-- </router-link> -->
                                            <feather type="sidebar" size="20px" style="color: #51bb25; cursor: pointer;" v-b-tooltip.hover title="View"></feather>
                                        </span>
                                    </template>
                                </b-table>
                            </div>
            
                            <b-col md="6" class="my-1 p-0">
                                <b-pagination
                                    v-model="currentPage"
                                    :total-rows="totalRows"
                                    :per-page="perPage"
                                    class="my-0"
                                ></b-pagination>
                            </b-col>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Container-fluid Ends-->
    </div>
</template>
  
<script>
  
export default {
    data() {
        return {
            tablefields: [
                { key: 'index', label: '#' },
                {key: 'group_name', label: 'Group Name', sortable: true,},
                {key: 'question_type', label: 'Question Type', sortable: true,},
                {key: 'total_question', label: 'Total Question', sortable: true,},
                {key: 'updated_on', label: 'Updated On', sortable: true,},
                {key: 'updated_by', label: 'Updated By', sortable: true,},
                { key: 'action', label: 'Action', sortable: true, },
            ],
    
            items: [
                { group_name: 'Content Writer Screening', question_type: 'Screening', total_question: '11', updated_on: '01-02-2024', updated_by: 'Azizul Bari' },
                { group_name: 'ME Quiz for Remote', question_type: 'Quiz', total_question: '15', updated_on: '12-11-2023', updated_by: 'Arman Malik' },
            ],
    
            filter: null,
    
            totalRows: 1,
            currentPage: 1,
            perPage: 25,
            pageOptions: [25, 50, 100],
        };
    },

    computed: {
      sortOptions() {
        // Create an options list from our fields
        return this.tablefields
            .filter(f => f.sortable)
            .map(f => {
              return {text: f.label, value: f.key};
            });
      }
    },
    mounted() {
      // Set the initial number of items
      this.totalRows = this.items.length;
    },
    methods: {
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      }
    }
  };
  </script>
  