<template>
    <div>
        <Breadcrumbs main="Procurement" title="Vendor Details" />
    
        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-body">
                            <div style="display: flex; flex-direction: row; gap: 40px;  align-items: center; background-color: rgb(243 244 246); border-radius: 0.75rem; filter: drop-shadow(0 4px 3px rgb(0 0 0 / 0.07)) drop-shadow(0 2px 2px rgb(0 0 0 / 0.06)); padding: 20px;">
                                <img style="height: 100px; width: 100px; border-radius: 100%;" :src="clients.image">
                                <div style="margin-top: 15px; display: flex; flex-direction: column;">
                                    <h5>{{ details.user.first_name }}</h5>
                                    <h7>Email: {{ details.user.email }}</h7>
                                    <h7>Phone: {{ details.phone }}</h7>
                                    <h7>Company: {{ details.company }}</h7>
                                    <h7>Address: {{ details.address }}</h7>
    
                                </div>
                            </div>
                        </div>
    
                    </div>
                </div>
            </div>
    
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-body">
                            <div style="display: flex; flex-direction: row; gap: 10px; cursor: pointer;">
                                <h7 @click="toggleItem('Projects')" :class="{ active: activeItem === 'Projects' }">Projects</h7>
                                <h7 @click="toggleItem('Po')" :class="{ active: activeItem === 'Po' }">Purchase Order</h7>
                                <h7 @click="toggleItem('Bill')" :class="{ active: activeItem === 'Bill' }">Bill</h7>
                                
                            </div>
                        </div>
    
                        <div class="card" v-if="activeItem === 'Po'">
                            <div class="card-body">
                                <b-row>
                                    <b-col xl="6">
                    
                                        <b-input-group class="datatable-btn">
                                            <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
                                            <b-input-group-append>
                                                <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                            </b-input-group-append>
                                        </b-input-group>
                    
                                    </b-col>
                    
                                    <b-col xl="6">
                                        <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                                            <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                
                                <div class="table-responsive datatable-vue">
                                    <b-table
                                        show-empty
                                        stacked="md"
                                        :items="details.po"
                                        :fields="tablefieldsPurchaseOrder"
                                        :filter="filter"
                                        :current-page="currentPage"
                                        :per-page="perPage"
                                        @filtered="onFiltered"
                                    >
                                        <template #cell(index)="data">
                                            {{ data.index + 1 }}
                                        </template>

                                    </b-table>
                                </div>
                
                                <b-col md="6" class="my-1 p-0">
                                    <b-pagination
                                        v-model="currentPage"
                                        :total-rows="totalRows"
                                        :per-page="perPage"
                                        class="my-0"
                                    ></b-pagination>
                                </b-col>
                            </div>
                        </div>
                        <div class="card" v-if="activeItem === 'Bill'">
                            <div class="card-body">
                                <b-row>
                                    <b-col xl="6">
                    
                                        <b-input-group class="datatable-btn">
                                            <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
                                            <b-input-group-append>
                                                <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                            </b-input-group-append>
                                        </b-input-group>
                    
                                    </b-col>
                    
                                    <b-col xl="6">
                                        <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                                            <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                
                                <div class="table-responsive datatable-vue">
                                    <b-table
                                        show-empty
                                        stacked="md"
                                        :items="details.bill"
                                        :fields="tablefieldsBill"
                                        :filter="filter"
                                        :current-page="currentPage"
                                        :per-page="perPage"
                                        @filtered="onFiltered"
                                    >
    
                                        <template #cell(index)="data">
                                            {{ data.index + 1 }}
                                        </template>
                                    </b-table>
                                </div>
                
                                <b-col md="6" class="my-1 p-0">
                                    <b-pagination
                                        v-model="currentPage"
                                        :total-rows="totalRows"
                                        :per-page="perPage"
                                        class="my-0"
                                    ></b-pagination>
                                </b-col>
                            </div>
                        </div>
                        <div class="card" v-if="activeItem === 'Projects'">
                            <div class="card-body">
                                <b-row>
                                    <b-col xl="6">
                    
                                        <b-input-group class="datatable-btn">
                                            <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
                                            <b-input-group-append>
                                                <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                            </b-input-group-append>
                                        </b-input-group>
                    
                                    </b-col>
                    
                                    <b-col xl="6">
                                        <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                                            <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                
                                <div class="table-responsive datatable-vue">
                                    <b-table
                                        show-empty
                                        stacked="md"
                                        :items="details.projects"
                                        :fields="tablefieldsProjects"
                                        :filter="filter"
                                        :current-page="currentPage"
                                        :per-page="perPage"
                                        @filtered="onFiltered"
                                    >
    
                                        <template #cell(index)="data">
                                            {{ data.index + 1 }}
                                        </template>
                                    </b-table>
                                </div>
                
                                <b-col md="6" class="my-1 p-0">
                                    <b-pagination
                                        v-model="currentPage"
                                        :total-rows="totalRows"
                                        :per-page="perPage"
                                        class="my-0"
                                    ></b-pagination>
                                </b-col>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    
        </div>
    </div>
    </template>
    
    <script>
    import { mapState } from 'vuex';
    import Swal from 'sweetalert2/dist/sweetalert2.js';
    import 'sweetalert2/src/sweetalert2.scss';
    
    import img1 from "../../../assets/images/user/3.jpg";
    
    export default {
        data() {
            return {
    
                activeItem:'Projects',
                img1: img1,
                clients: {
                    id: 1,
                    name: 'Rasel Mir',
                    phone_no: '+8801785457487',
                    email: 'rasel@test.com',
                    image: img1
                },
    
                tablefieldsPurchaseOrder: [
                    { key: 'index', label: '#' },
                    {key: 'purchase_order_id', label: 'Purchase Order ID', sortable: true,},
                    {key: 'purchase_order_date', label: 'Purchase Order Date', sortable: true,},
                    {key: 'subtotal', label: 'Purchase Order Date', sortable: true,},
                    {key: 'purchase_order_date', label: 'Purchase Order Date', sortable: true,},
                    {key: 'subtotal', label: 'Subtotal', sortable: true },
                    {key: 'total', label: 'Total', sortable: true },
                  
                ],
                tablefieldsBill: [
                    { key: 'index', label: '#' },
                    { key: 'bill_id', label: 'Bill ID', sortable: true },
                    { key: 'email', label: 'Email', sortable: true },
                    { key: 'bill_date', label: 'Bill Date', sortable: true },
                    { key: 'due_date', label: 'Due Date', sortable: true },
                    { key: 'subtotal', label: 'Grand Total', sortable: true },
                   
                ],
                tablefieldsProjects: [
                    { key: 'index', label: '#' },
                    { key: 'project_id.title', label: 'Name', sortable: true },
                    { key: 'project_id.created_by', label: 'Project Lead', sortable: true },
                    { key: 'project_id.start_date', label: 'Start Date', sortable: true },
                    { key: 'project_id.end_date', label: 'End Date', sortable: true },
                    { key: 'project_id.status', label: 'Status', sortable: true },
                    
                  
                ],
    
                invoices:[
                    {
                        invoice_id:'',
                    }
                ],
    
            
                filter: null,
        
                totalRows: 1,
                currentPage: 1,
                perPage: 25,
                pageOptions: [25, 50, 100],
    
    
                
            };
        },
        computed: {
            ...mapState({
                details: state => state.vendor.vendorDetails
            }),
    
            sortOptions() {
                return this.tablefields
                .filter(f => f.sortable)
                .map(f => {
                    return {text: f.label, value: f.key};
                });
            }
        },
        mounted() {
            this.$store.dispatch("vendor/fetchVendorDetails", this.$route.params.id)
         
        },
        methods: {
            toggleItem(item) {
                this.activeItem = item;
            },
        }
    }
    </script>
    
    <style scoped>
        .active{
            color: #ff595d;
        }
    </style>
    